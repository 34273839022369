import React, { useEffect, useState } from "react";
import police_ic from "../../../assets/images/Emergency/police_ic.svg";
import fire_station from "../../../assets/images/Emergency/fire_station.svg";
import hospital from "../../../assets/images/Emergency/hospital.png";
import Header from "../../../sharedComponents/Header";
import Footer from "../../../sharedComponents/Footer";
import { Modal } from "react-bootstrap";
import police_icon_modal from "../../../assets/images/model/police_icon_modal.svg";
import { getWithAuthCall } from "../../../api/ApiServices";
import ApiConfig from "../../../api/ApiConfig";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { recentSearch } from "../../../Redux/Action";

const Emergency = () => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [areaList, setAreaList] = useState([]);

  const [CityList, setCityList] = useState([]);
  const [value, setvalue] = useState(null);
  const dispatch = useDispatch();
  const [contactData, setcontactData] = useState([]);

  const [selectedAreaList, setSelectedAreaList] = useState([]);

  const [selectedCity, setSelectedCity] = useState([]);
  const [selectedArea, setSelectedArea] = useState([]);

  const eData = useLocation().state;

  const setGetCity = async () => {
    getWithAuthCall(ApiConfig.GET_CITYLIST)
      .then((res) => {
        // console.log(res);
        parseCityData(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const parseCityData = (data) => {
    setCityList(
      data.city_area_listing.map((item, index) => {
        return { label: item.city, value: item.city };
      })
    );
  };

  const parseAreaData = (data) => {
    setAreaList(
      data.city_data.map((item, index) => {
        return { label: item.area, value: item.area };
      })
    );
  };

  useEffect(() => {
    setGetCity();
    if (selectedCity.length > 0 || selectedArea.length > 0) {
      getEmergrncyCont();
    }
    if (selectedCity.length > 0) {
      getSelectedCityAreaList();
    }
    return () => {};
  }, [selectedCity, selectedArea]);

  const getEmergrncyCont = (area) => {
    setLoading(true);
    getWithAuthCall(
      ApiConfig.EMERGENCY_CONTACT + selectedArea + "&city=" + selectedCity
    )
      .then((res) => {
        // console.log("res", res);
        setcontactData(res.search);
        dispatch(recentSearch({ city: value, area: area }));
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getSelectedCityAreaList = (area) => {
    setLoading(true);
    getWithAuthCall(ApiConfig.CITY_LIST + selectedCity)
      .then((res) => {
        // console.log("res", res);
        setSelectedAreaList(res.city_data);
        parseAreaData(res);

        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function handleClose() {
    setShow(false);
  }

  const handleClick = () => {
    setShow(!show);
  };

  let pp = CityList.filter(
    (ele, ind) =>
      ind ===
      CityList.findIndex(
        (elem) => elem.label === ele.label && elem.id === ele.id
      )
  );

  return (
    <>
      <Header />

      <Modal
        responsive-md
        className="modal-md"
        id="police"
        role="dialog"
        show={show}
        onHide={handleClose}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        {contactData &&
          contactData.length &&
          contactData.map((contact, index) => {
            return (
              <>
                {/* {contact.type === "Police Station" && ( */}
                <div className="text-center">
                  <div className="img-rating">
                    {/* {contact.type === "Police Station" && ( */}
                    <img src={contact.image} alt="" />
                    {/* )} */}
                    {/* {contact.type === "Fire Station" && (
                        <img src={contact.image} alt="" />
                      )}
                      {contact.type === "Hospital" && (
                        <img src={contact.image} alt="" />
                      )} */}
                  </div>
                  <div>
                    <p className="user-name mt-2 mb-3">
                      {/* {contact.type} */}
                      police
                      <br />{" "}
                    </p>
                  </div>
                  <div
                    className=" justify-content-between align-items-start mb-3 "
                    id="days"
                  >
                    <a href="" className="text-decoration-none">
                      <div className=" p-2 d-flex align-items-middle justify-content-between  rsponsive-card-data">
                        <p className="model_staion">{contact.type}-1</p>
                        <span className="model_staion">
                          {contact.phone_number1}
                        </span>
                      </div>
                    </a>
                  </div>
                  <div
                    className=" justify-content-between align-items-start mb-3 "
                    id="days"
                  >
                    <a href="" className="text-decoration-none">
                      <div className=" p-2 d-flex align-items-middle justify-content-between  rsponsive-card-data">
                        <p className="model_staion">{contact.type}-2</p>
                        <span className="model_staion">
                          {contact.phone_number2}
                        </span>
                      </div>
                    </a>
                  </div>
                </div>
              </>
            );
          })}
      </Modal>

      {/* --------------------fire Model-------- */}

      <main className="home-getStarted">
        <div className="">
          <div className="">
            <div className="emergency_banner"></div>
          </div>
        </div>
        <div className="container mt-2" style={{ marginBottom: "200px" }}>
          <div className="row">
            <div className="TranHistory justify-content-between">
              <div className="col-lg-7 ">
                <h5 htmlFor="" className="emr_fileld">
                  Emergency Contact Numbers
                </h5>
              </div>
            </div>
          </div>
          <div className="d-lg-flex form-group ">
            <div>
              <Select
                className="form-control border-0"
                options={pp}
                placeholder="Select City"
                style={{ maxwidth: "300px" }}
                onChange={(e) => {
                  setSelectedCity(e.value);
                }}
              />
            </div>
            <div>
              <Select
                className="form-control border-0"
                options={areaList}
                placeholder="Select Area"
                onChange={(e) => {
                  setSelectedArea(e.value);
                }}
              />
            </div>
          </div>

          {/* <!--card's --> */}

          <div className="row ">
            {contactData && contactData.length > 0 ? (
              contactData.map((contact, index) => {
                return (
                  <div className="col-lg-6 mt-4" key={"contact" + index}>
                    <div className="card" id="emergency_card">
                      <div className="emergencycard-body">
                        {/* <div className="row"> */}
                        <div className=" d-flex justify-content-between">
                          <div className="col-lg-8 col-md-8  d-flex fd-img-icon">
                            {contact.type === "Police Station" && (
                              <img
                                src={police_ic}
                                alt=""
                                className="img-fluid emergency-icon-1"
                              />
                            )}
                            {contact.type === "Fire Station" && (
                              <img
                                src={fire_station}
                                alt=""
                                className="img-fluid emergency-icon-1"
                              />
                            )}
                            {contact.type === "Hospital" && (
                              <img
                                src={hospital}
                                alt=""
                                className="img-fluid emergency-icon-1"
                              />
                            )}
                            <p className="ERcard-title  pt-4">{contact.type}</p>
                          </div>
                          <div className="emry-Btn">
                            <button
                              className="emry-btn"
                              data-toggle="police"
                              data-target="#police"
                              id="police"
                              onClick={() => handleClick()}
                              state={contact.id}
                            >
                              Contact
                            </button>
                          </div>
                        </div>
                        {/* </div> */}
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <>
                {loading ? (
                  <div
                    className="spinner-border mx-auto mt-2"
                    style={{
                      color: "#3D313A",
                      width: "70px",
                      height: "70px",
                    }}
                  />
                ) : (
                  <h3 className="mt-4 text-center text-danger">
                    Select City And Area
                  </h3>
                )}
              </>
            )}
          </div>
        </div>
        <Footer />
      </main>
    </>
  );
};

export default Emergency;
