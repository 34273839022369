import React, { useEffect, useState } from "react";
import Header from "../sharedComponents/Header";
import Footer from "../sharedComponents/Footer";
import { contactpostMultipartWithAuthCallWithErrorResponse } from "../api/ApiServices";
import ApiConfig from "../api/ApiConfig";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AppContext } from "../context/user/AppContext";

const ContactUs = () => {
  const [aboutUs, setAboutUs] = useState("");
  const [loading, setLoading] = useState(false);
  const { logedIn, role } = useContext(AppContext);
  const navigate = useNavigate();
  const [state, setState] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [errMsg, setErrMsg] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const contactUs = () => {
    setLoading(true);

    if (state.name === "") {
      setErrMsg({ ...errMsg, name: "Name is required" });
      // window.scrollTo(0, 0);
      return;
    }
    if (state.email === "") {
      setErrMsg({ ...errMsg, email: "File Name is required" });
      // window.scrollTo(0, 0);
      return;
    }
    if (state.subject === "") {
      setErrMsg({ ...errMsg, subject: "File Name is required" });
      // window.scrollTo(0, 0);
      return;
    }
    if (state.message === "") {
      setErrMsg({ ...errMsg, message: "File Name is required" });
      // window.scrollTo(0, 0);
      return;
    } else {
      const formData = new FormData();
      formData.append("name", state.name);
      formData.append("email", state.email);
      formData.append("subject", state.subject);
      formData.append("message", state.message);
      contactpostMultipartWithAuthCallWithErrorResponse(
        ApiConfig.CONTACT_US,
        formData
      )
        .then((res) => {
          // console.log(res);
          swal("Thank you for Contacting Us!");
          if (logedIn && role === "Traveler") {
            navigate("/SelectMarshall");
          } else if (logedIn && role === "TravelMarshall") {
            navigate("/home");
          } else {
            navigate("/");
          }

          setLoading(false);
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <>
      <Header />

      <main className="home-getStarted">
        <div className="">
          <div className="about_banner"></div>
          {/* <img src={emergency} alt="" /> */}
        </div>
        <div
          className="container mt-2"
          style={{ marginBottom: "200px" }}
          id="contact-main"
        >
          <h5 htmlFor="" className="Home_req ">
            Contact Us
          </h5>
          <div className="contact-us-main ">
            <div>
              <div className="form-group mb-3">
                {/* <label for="exampleInputEmail1">Name</label> */}
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Name *"
                  value={state.name}
                  onChange={(e) => {
                    setErrMsg({ ...errMsg, name: "" });
                    setState({ ...state, name: e.target.value });
                  }}
                />
                {errMsg.name.length > 0 && (
                  <div className="text-center text-danger">
                    <span> {errMsg.name}</span>
                  </div>
                )}
              </div>
              <div className="form-group mb-3">
                <input
                  type="email"
                  className="form-control"
                  id="exampleInputPassword1"
                  placeholder="Email *"
                  value={state.email}
                  onChange={(e) => {
                    setErrMsg({ ...errMsg, email: "" });
                    setState({ ...state, email: e.target.value });
                  }}
                />
                {errMsg.email.length > 0 && (
                  <div className="text-center text-danger">
                    <span> {errMsg.email}</span>
                  </div>
                )}
              </div>
              <div className="form-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Subject"
                  value={state.subject}
                  onChange={(e) => {
                    setErrMsg({ ...errMsg, subject: "" });
                    setState({ ...state, subject: e.target.value });
                  }}
                />
                {errMsg.subject.length > 0 && (
                  <div className="text-center text-danger">
                    <span> {errMsg.subject}</span>
                  </div>
                )}
              </div>
              <div className="form-group mb-3">
                <textarea
                  style={{ height: "100px" }}
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Message..."
                  rows="10"
                  value={state.message}
                  onChange={(e) => {
                    setErrMsg({ ...errMsg, message: "" });
                    setState({ ...state, message: e.target.value });
                  }}
                />
                {errMsg.message.length > 0 && (
                  <div className="text-center text-danger">
                    <span> {errMsg.message}</span>
                  </div>
                )}
              </div>
              <button onClick={contactUs} className="btn btn-primary">
                Send
              </button>
            </div>
          </div>
        </div>
        <Footer />
      </main>
    </>
  );
};

export default ContactUs;
