import React, { useState } from "react";
import login_banner from "../assets/images/login_banner.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import eye from "../assets/images/eye.svg";
import { simplePostCall } from "../api/ApiServices";
import ApiConfig from "../api/ApiConfig";
import swal from "sweetalert";
import view from "../assets/images/eye_off_icon.svg";

const Verification = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const previousPageData = useLocation().state;
  const [errMsg, setErrMsg] = useState({ password: "", Confirm_password: "" });
  const [values, setValues] = useState({
    password: "",
    Confirm_password: "",
    showPassword: false,
    showConfirmPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handlePasswordChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleConfirmClickShowPassword = () => {
    setValues({ ...values, showConfirmPassword: !values.showConfirmPassword });
  };

  const handleConfirmMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleConfirmPasswordChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const [state, setState] = useState({
    ...previousPageData,
    otpDigit1: "",
    otpDigit2: "",
    otpDigit3: "",
    otpDigit4: "",
    otpDigit5: "",
    password: "",
    Confirm_password: "",
  });
  const [data, setData] = useState({});

  const changePassword = (e) => {
    e.preventDefault();
    setLoading(true);

    var otp =
      state.otpDigit1 +
      state.otpDigit2 +
      state.otpDigit3 +
      state.otpDigit4 +
      state.otpDigit5;
    var password = state.password;

    // if (state.otp === "") {
    //   setErrMsg({ ...errMsg, otp: "Enter Email ID " });
    //   return;
    // }

    if (state.password === "") {
      setErrMsg({ ...errMsg, password: "Enter Password " });
      return;
    }
    if (state.Confirm_password === "") {
      setErrMsg({
        ...errMsg,
        Confirm_password: '"Please enter confirm password!"',
      });
      return;
    }
    if (state.Confirm_password !== state.password) {
      setErrMsg({
        ...errMsg,
        Confirm_password: '"Password and Confirm Password do not match"',
      });
      return;
    } else {
      simplePostCall(
        ApiConfig.SET_PASSWORD,
        JSON.stringify({ ...previousPageData, otp, password })
      )
        .then((res) => {
          // console.log("res", res);
          setData(res);
          if (res.detail === "Password Saved") {
            navigate("/login");
            swal(res.detail);
          } else {
            swal(res.detail);
          }
        })
        .catch((err) => {
          // console.log(err)
          setLoading(false);
        });
    }
  };

  const handleEnter = (event) => {
    var validkeys = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"];
    if (validkeys.includes(event.key)) {
      event.target.value = event.key;
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1] && form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  return (
    <main className="login-main">
      <section className="login-section">
        <div className="row">
          <div className="col-md-5 col-lg-5">
            <div className="left-section-login">
              <img src={login_banner} />
            </div>
          </div>

          <div className="col-md-7 col-lg-7 parent-login">
            <div className="login-form ">
              <form action="">
                <div className=" text-left">
                  <h4 className="forgot-heading">Verification Code</h4>
                  <label htmlFor="" className="mt-3 text_head">
                    Please enter the code we sent to <span> Your Mail Id</span>
                  </label>
                </div>
                <form>
                  <div className="container height-100 d-flex justify-content-center align-items-center border-bottom mt-3 mb-4">
                    <div className="position-relative">
                      <div className=" text-center mb-2">
                        <div
                          id="otp"
                          className="inputs d-flex flex-row justify-content-center mt-1 mb-2 pinotp"
                        >
                          <input
                            className=" text-center form-control rounded"
                            type="text"
                            id="first"
                            maxLength="1"
                            value={state.otpDigit1}
                            onKeyDown={(e) => {
                              if (String(e.key).match("^[0-9]+$")) {
                                setState({ ...state, otpDigit1: e.key });
                                handleEnter(e);
                              }
                            }}
                            onChange={(e) => {
                              setState({
                                ...state,

                                otpDigit1: e.target.value,
                              });
                            }}
                          />
                          <input
                            className=" text-center form-control rounded"
                            type="text"
                            id="first"
                            maxLength="1"
                            value={state.otpDigit2}
                            onKeyDown={(e) => {
                              if (String(e.key).match("^[0-9]+$")) {
                                setState({ ...state, otpDigit2: e.key });
                                handleEnter(e);
                              }
                            }}
                            onChange={(e) => {
                              setState({
                                ...state,

                                otpDigit2: e.target.value,
                              });
                            }}
                          />
                          <input
                            className=" text-center form-control rounded"
                            type="text"
                            id="first"
                            maxLength="1"
                            value={state.otpDigit3}
                            onKeyDown={(e) => {
                              if (String(e.key).match("^[0-9]+$")) {
                                setState({ ...state, otpDigit3: e.key });
                                handleEnter(e);
                              }
                            }}
                            onChange={(e) => {
                              setState({
                                ...state,

                                otpDigit3: e.target.value,
                              });
                            }}
                          />
                          <input
                            className=" text-center form-control rounded"
                            type="text"
                            id="first"
                            maxLength="1"
                            value={state.otpDigit4}
                            onKeyDown={(e) => {
                              if (String(e.key).match("^[0-9]+$")) {
                                setState({ ...state, otpDigit4: e.key });
                                handleEnter(e);
                              }
                            }}
                            onChange={(e) => {
                              setState({
                                ...state,

                                otpDigit4: e.target.value,
                              });
                            }}
                          />
                          <input
                            className=" text-center form-control rounded"
                            type="text"
                            id="first"
                            maxLength="1"
                            value={state.otpDigit5}
                            onKeyDown={(e) => {
                              if (String(e.key).match("^[0-9]+$")) {
                                setState({ ...state, otpDigit5: e.key });
                                handleEnter(e);
                              }
                            }}
                            onChange={(e) => {
                              setState({
                                ...state,

                                otpDigit5: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <hr/> */}
                </form>
                <div>
                  <h4 className="forgot-heading">Create new password</h4>
                  <label htmlFor="" className="text_head">
                    Your new password must be different from <br /> previous
                    used passwords.
                  </label>
                </div>
                <div className="form-group mt-2 email_fileld">
                  <label for="exampleFormControlInput1" className="text_head">
                    Password
                  </label>
                  <input
                    type={values.showPassword ? "text" : "password"}
                    placeholder="password"
                    row="1"
                    maxLength="10"
                    className="form-control emailInput mt-2"
                    onChange={(e) => {
                      // setErrMsg({ ...errMsg, password: "" });
                      setState({ ...state, password: e.target.value });
                      handlePasswordChange("password");
                    }}
                  />
                  <img
                    src={values.showPassword ? eye : view}
                    alt=""
                    className="eye_iconVerify"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  />

                  <p className="must_8cha mt-2">
                    Must be at least 8 characters.
                  </p>
                </div>
                {errMsg.password.length > 0 && (
                  <span className="text-danger">{errMsg.password}</span>
                )}
                <div className="form-group mt-4  email_fileld">
                  <label for="" className="text_head">
                    Confirm Password
                  </label>
                  <input
                    type={values.showConfirmPassword ? "text" : "password"}
                    row="1"
                    maxLength="10"
                    placeholder="Confirm Password"
                    className="form-control emailInput mt-2"
                    onChange={(e) => {
                      setErrMsg({ ...errMsg, password: "" });
                      setState({ ...state, Confirm_password: e.target.value });
                      handleConfirmPasswordChange("password");
                    }}
                  />
                  <img
                    src={values.showConfirmPassword ? eye : view}
                    alt=""
                    className="eye_iconVerify"
                    onClick={handleConfirmClickShowPassword}
                    onMouseDown={handleConfirmMouseDownPassword}
                  />

                  <p for="exampleFormControlInput1" className="must_8cha mt-2">
                    Both passwords must match.
                  </p>
                </div>
                {errMsg.Confirm_password.length > 0 && (
                  <span className="text-danger">{errMsg.Confirm_password}</span>
                )}
                <div className="text-center">
                  <button
                    className="send-varification-btn mt-4"
                    onClick={changePassword}
                  >
                    Reset Password
                    {/* {loading ? (
                      <div
                        className="spinner-border"
                        style={{ textAlign: "right", color: "#FFFBF3" }}
                      />
                    ) : (
                      "Reset Password"
                    )} */}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Verification;
