import React, { useEffect, useState } from "react";
import ApiConfig from "../../api/ApiConfig";
import { getWithAuthCallWithtext } from "../../api/ApiServices";
import Footer from "../../sharedComponents/Footer";
import Header from "../../sharedComponents/Header";

const TermandCondition = () => {
  const [terms, setTerms] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getTerms();
    return () => {};
  }, []);

  const getTerms = () => {
    setLoading(true);

    getWithAuthCallWithtext(ApiConfig.TERMS_CONDITION)
      .then((data) => {
        setTerms(data.text);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
      <Header />

      <main className="home-getStarted">
        <div className="">
          <div className="about_banner"></div>
          {/* <img src={emergency} alt="" /> */}
        </div>
        <div className="container mt-2" style={{ marginBottom: "200px" }}>
          <h5 htmlFor="" className="Home_req ">
            Terms and Conditions
          </h5>

          <div className="row ">
            {loading ? (
              <div
                className="spinner-border mx-auto mt-2"
                style={{
                  color: "#3D313A",
                  width: "70px",
                  height: "70px",
                }}
              />
            ) : (
              <p dangerouslySetInnerHTML={{ __html: terms }}></p>
            )}
          </div>
        </div>
        <Footer />
      </main>
    </>
  );
};

export default TermandCondition;
