import React, { useEffect, useState } from "react";
import ApiConfig from "../../../api/ApiConfig";
import { getWithAuthCallWithtext } from "../../../api/ApiServices";

function Privacy(props) {
  const [loading, setLoading] = useState(false);
  const [privacy, setPrivacy] = useState("");
  useEffect(() => {
    getPrivacy();
    return () => {};
  }, []);

  const getPrivacy = () => {
    setLoading(true);

    getWithAuthCallWithtext(ApiConfig.PRIVACY_POLICY)
      .then((data) => {
        setPrivacy(data.text);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
      {loading ? (
        <div
          className="spinner-border mx-auto mt-2"
          style={{
            color: "#3D313A",
            width: "70px",
            height: "70px",
          }}
        />
      ) : (
        <div dangerouslySetInnerHTML={{ __html: privacy }}></div>
      )}
    </>
  );
}

export default Privacy;
