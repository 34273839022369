import React, { useEffect, useState } from "react";
import Header from "../../../sharedComponents/Header";
import Footer from "../../../sharedComponents/Footer";
import coins from "../../../assets/images/Home/coins.png";
import date from "../../../assets/images/PayMethods/date.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { simpleGetCallWithErrorResponse } from "../../../api/ApiServices";
import ApiConfig from "../../../api/ApiConfig";
import { AppContext } from "../../../context/user/AppContext";
import { useContext } from "react";
import dateFormat, { masks } from "dateformat";

const TranscationHistory = () => {
  const [chatCount, setChatCount] = useState("");
  const [transaction, setTransaction] = useState([{}]);
  const [subscriptions, setSubscription] = useState([{}]);

  const { customerData, role } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getSubscriptionHistory();
    return () => {};
  }, []);

  const getSubscriptionHistory = () => {
    setLoading(true);
    simpleGetCallWithErrorResponse(
      role === "Traveler"
        ? ApiConfig.SUBSCRIPTION_HISTORY
        : ApiConfig.AUTO_TRANSACTION
    ).then((res) => {
      // console.log(res);
      setLoading(false);
      setSubscription(res.json.subscriptions);
      setChatCount(res.json.remaining_chat_count);
      setTransaction(res.json.list);
      setChatCount(res.json.remaining_chat_count);
    });
  };

  return (
    <>
      <Header />

      <main className="home-getStarted">
        <div className="">
          <div className="about_banner"></div>
          {/* <img src={emergency} alt="" /> */}
        </div>
        <div className="container mt-2" style={{ marginBottom: "39px" }}>
          <div className="TranHistory">
            <div className="">
              <h5 htmlFor="" className="Home_req ">
                {role === "Traveler"
                  ? "Subscription History"
                  : "Transaction History"}
              </h5>
            </div>

            {/* <div className=" date-calender position-relative">
              <input
                type="date"
                id="inputDate"
                className="Calender border border-light"
              />
            </div> */}
          </div>
          {role === "Traveler" && (
            <div className="col-12 p-3">
              <div className="card Tran_Card">
                <p className="date text-warning text-center">
                  You have {chatCount} remaining counts to connect
                </p>
              </div>
            </div>
          )}

          <div />

          <div className="row ">
            {/* <label htmlFor="" className="Today mb-2">
              TODAY
            </label> */}
            {role === "Traveler" &&
            subscriptions &&
            subscriptions.length > 0 ? (
              subscriptions.map((item, index) => {
                return (
                  <>
                    <div className="col-12 p-3" key={"item" + index}>
                      <div className="card Tran_Card">
                        <div className="row">
                          <div className="col-lg-6 d-flex">
                            <img src={coins} className="coins" alt="" />
                            <p className="sbi ms-3">
                              {item.subscription_plan &&
                                item.subscription_plan.plan_name}
                              <br />
                              <span>Expire On : {item.expired_date}</span>
                            </p>
                          </div>

                          <div className="col-lg-6 text-end">
                            <p className="date">
                              {item.subscription_plan &&
                                item.subscription_plan.pricing}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })
            ) : (
              <h4 className="text-center text-danger">
                {role === "Traveler" && "No Transaction Found"}
              </h4>
            )}
            {role === "TravelMarshall" &&
            transaction &&
            transaction.length > 0 ? (
              transaction.map((item, index) => {
                console.log("item", item.created_at);
                return (
                  <>
                    <div className="col-12 p-3" key={"item" + index}>
                      <div className="card Tran_Card">
                        <div className="row">
                          <div className="col-lg-6 d-flex">
                            <img src={coins} className="coins" alt="" />
                            <p className="sbi ms-3">
                              Credited By {item.WT}
                              <br />
                              {/* <Moment format=""> */}
                              <span>
                                {dateFormat(
                                  item.created_at,
                                  "dd/mm/yyyy, h:MM:ss TT"
                                )}
                              </span>
                              {/* </Moment> */}
                            </p>
                          </div>

                          <div className="col-lg-6 text-end">
                            <p className="date">{item.amount}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })
            ) : (
              <h4 className="text-center text-danger">
                {role === "TravelMarshal" && "No Transaction Found"}
              </h4>
            )}
          </div>
        </div>
        <Footer />
      </main>
    </>
  );
};

export default TranscationHistory;
