import React, { useState } from "react";
import Header from "../../../sharedComponents/Header";
import Footer from "../../../sharedComponents/Footer";
import back from "../../../assets/images/PayMethods/back.svg";
import { Link, useNavigate } from "react-router-dom";
import { postMultipartWithAuthCallWithErrorResponse } from "../../../api/ApiServices";
import swal from "sweetalert";
import ApiConfig from "../../../api/ApiConfig";
const UPIPayments = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [bankDetails, setBankDetails] = useState({
    transfer_option: "UPI_ID",
    upi_no: "",
  });

  const [errMsg, setErrMsg] = useState({
    upi_no: "",
  });
  const addBankDetails = async () => {
    if (bankDetails.upi_no === "") {
      if (bankDetails.upi_no === "") {
        setErrMsg({ ...errMsg, upi_no: "Account number is requied" });
        // window.scrollTo(0, 0);

        return;
      }
    } else {
      let formData = new FormData();
      formData.append("transfer_option", bankDetails.transfer_option);

      formData.append("upi_no", bankDetails.upi_no);
      // let details = JSON.stringify({
      //   transfer_option: bankDetails.transfer_option,
      //   account_number: bankDetails.account_number,
      //   IFSC_code: bankDetails.IFSC_code,
      //   bank_name: bankDetails.bank_name,
      //   account_name: bankDetails.account_name,
      // });
      // formData.append("details", details);
      setLoading(true);
      postMultipartWithAuthCallWithErrorResponse(
        ApiConfig.ADD_BANK_DETAILS,
        formData
      )
        .then((res) => {
          // console.log(res);
          setLoading(false);
          swal("Account Added Successfully");
          if (res) navigate("/transferMoney");
        })
        .catch((err) => console.log(err));
    }
  };
  return (
    <>
      <Header />

      <main className="home-getStarted">
        <div className="">
          <div className="about_banner"></div>
          {/* <img src={emergency} alt="" /> */}
        </div>
        <div className="container mt-2" style={{ marginBottom: "200px" }}>
          <form className="row ">
            <div className="d-flex justify-content-between">
              <h5 htmlFor="" className="Home_req">
                Add UPI Details
              </h5>
            </div>
          </form>

          <div className="row ">
            <div className="col-lg-4"></div>
            <div className="col-lg-5 ">
              <div className="justify-content-center ">
                <div className="card-doc-bank  p-2" id="cards">
                  <div className=" pb-2">
                    <div className="d-flex justify-content-between ">
                      <Link to="/TransferMoney">
                        <img src={back} alt="" className="pt-4 ps-3" />
                      </Link>
                      <p
                        htmlFor=""
                        className="payment_heading text-center pt-4 me-4"
                      >
                        Add UPI Payment
                      </p>
                      <div></div>

                      {/* <img src={back} alt="" className="back_img " /> */}
                    </div>
                  </div>
                  <hr />
                  <div className="card-body p-3">
                    <label className="upcard-title   ms-2 mb-3">
                      Enter your UPI ID
                    </label>
                    <div className="col-lg-12 pe-2">
                      <input
                        type="text"
                        className="form-control ms-2 upi-input"
                        id="emergency_location "
                        aria-label="location"
                        onChange={(e) => {
                          setErrMsg({ ...errMsg, upi_no: "" });
                          setBankDetails({
                            ...bankDetails,
                            upi_no: e.target.value,
                          });
                        }}
                      />
                      {errMsg.upi_no.length > 0 && (
                        <span className="text-danger">{errMsg.upi_no}</span>
                      )}
                    </div>{" "}
                    <div className="p-2 upi_body ms-2 mt-4">
                      <span htmlFor="" className="upi_verify">
                        UPI ID verified
                      </span>
                      <p className="verify_text">
                        You can now make deposits, withdrawals, and use P2P.
                      </p>
                    </div>
                    <div className="d-flex ms-2 mt-3">
                      {/* <img src={check} alt="" /> */}
                      <div className="bank-round-checkbox">
                        <input type="checkbox" id="checkbox" />
                        <label htmlFor="checkbox"></label>
                      </div>
                      <label className="text-left upi_text p-2">
                        Securely save this UPI ID for a faster checkout next
                        time.
                      </label>
                    </div>
                    <div className="row justify-content-end pb-2 mt-3">
                      <div className="col-lg-6 col-md-5">
                        <button
                          className="add-btn text-center"
                          onClick={addBankDetails}
                        >
                          <span>Add</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </main>
    </>
  );
};

export default UPIPayments;
