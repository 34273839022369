import React, { useEffect, useState, useContext } from "react";
import Footer from "../../../sharedComponents/Footer";
import chat from "../../../assets/images/Home/chat.svg";
import Header from "../../../sharedComponents/Header";
import { Link, useLocation } from "react-router-dom";

import {
  postWithAuthCall,
  simpleGetCallWithErrorResponse,
} from "../../../api/ApiServices";
import ApiConfig from "../../../api/ApiConfig";
import { AppContext } from "../../../context/user/AppContext";
import StarRatingComponent from "react-star-rating-component";
import swal from "sweetalert";

const Home = () => {
  // let chatDetails = useLocation().state;
  const [show, setShow] = useState(true);
  const [connect, setConnect] = useState(false);
  const chatData = useLocation().state;

  const [requestList, setRequestList] = useState([]);
  const { customerData, setCurrentUser, role } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const handleClose = () => setShow(false);
  useEffect(() => {
    getReqList();
    return () => {};
  }, []);

  const getReqList = () => {
    setLoading(true);
    simpleGetCallWithErrorResponse(ApiConfig.REQ_LIST).then((res) => {
      // console.log(res);
      setRequestList(res.json.WT_connections);
      setLoading(false);
    });
  };

  return (
    <>
      <Header />

      <main className="home-getStarted">
        <div className="">
          <div className="home_Banner"></div>
          {/* <img src={home_Banner} alt="" /> */}
        </div>
        <div className="container mt-2" style={{ marginBottom: "200px" }}>
          <form className="row  ">
            <div className="TranHistory justify-content-between">
              <div className="col-lg-7 ">
                <h5 htmlFor="" className="connection mt-4">
                  My Connections
                </h5>
              </div>
            </div>
          </form>
          {/* <!--card's --> */}

          <div>
            {loading ? (
              <div
                className="d-flex spinner-border mx-auto mt-2"
                style={{
                  color: "#3D313A",
                  width: "70px",
                  height: "70px",
                }}
              />
            ) : (
              <div className="row ">
                {requestList &&
                  requestList.length &&
                  requestList.map((list, index) => {
                    return (
                      <div className="col-lg-6  mt-4" key={"list" + index}>
                        <div className=" wt-card">
                          <div
                            className=""
                            data-toggle="sos"
                            data-target="#sos"
                            id="about"
                          >
                            <div className="row ">
                              <div className="d-flex ">
                                <div className="col-lg-2  col-md-2 ">
                                  <div>
                                    <img
                                      className="profile"
                                      src={list.avatar}
                                    />
                                  </div>
                                </div>

                                <div className="col-lg-10 col-md-10 ">
                                  <div className="d-flex">
                                    <div className="col-lg-4 col-md-4 col-sm-4 responsivepro">
                                      <div className="heading">
                                        <h5 className="">{list.unique_id}</h5>
                                      </div>
                                    </div>

                                    <div className="col-lg-8 col-md-8 col-sm-8">
                                      <div className=" rating text-end ">
                                        <StarRatingComponent
                                          name="rate2"
                                          editing={false}
                                          starCount={5}
                                          value={list.rating}
                                        />
                                        <span>{list.rating}</span>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="d-flex">
                                    <div className="col-lg-9 col-md-9">
                                      <div className=" chips-content">
                                        {list.interests &&
                                          list.interests.map((chip, index) => {
                                            return (
                                              <div
                                                className="chips mt-2"
                                                key={"chip" + index}
                                              >
                                                <p>{chip.name_of_category}</p>
                                              </div>
                                            );
                                          })}
                                      </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-2 justify-content-end d-flex">
                                      <div className="">
                                        <button
                                          className="chat-btn-connection"
                                          style={{ border: "none" }}
                                        >
                                          <Link
                                            state={list}
                                            to={
                                              list.chatroom[0].private_chat_room
                                                ? "/Chats"
                                                : list.chatroom[0]
                                                    .private_chat_room == null
                                                ? swal(
                                                    "Chat should be initialize from WT."
                                                  )
                                                : ""
                                            }
                                            onClick={() => {
                                              setCurrentUser(list.id);
                                            }}
                                          >
                                            <img src={chat} alt="" />
                                          </Link>
                                        </button>
                                        {/* <Link to="/Chats">
                                  <img src={chat} alt="" />
                                </Link> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            )}

            {/* ---------------3re secion--- */}

            {/* -----Right section----- */}
          </div>
        </div>
        <Footer />
      </main>
    </>
  );
};

export default Home;
