import React, { useEffect, useState } from "react";
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  useParams,
} from "react-router-dom";
import "./assets/css/main.scss";
import Home from "./pages/Home/WebMarshall/Home";
import ForgotPassword from "./sharedComponents/ForgotPassword";
import GetStarted from "./sharedComponents/GetStarted";
import Login from "./sharedComponents/Login";
import Models from "./sharedComponents/Models";
import Verification from "./sharedComponents/Verification";
import Header from "./sharedComponents/Header";
import Footer from "./sharedComponents/Footer";
import Emergency from "./pages/Home/WebTraveller/Emergency";
import Aboutus from "./pages/Home/WebTraveller/Aboutus";
import TermandCondition from "./pages/Home/TermandCondition";
import PrivacyandPolicy from "./pages/Home/WebTraveller/PrivacyandPolicy";
import TranscationHistory from "./pages/Home/WebMarshall/TranscationHistory";
import TransferMoney from "./pages/Home/WebMarshall/TransferMoney";
import UPIPayments from "./pages/Home/WebMarshall/UPIPayments";
import Subscription from "./pages/Home/WebTraveller/Subscription";
import BankPayments from "./pages/Home/WebMarshall/BankPaymnets";
import Profile from "./pages/Home/WebMarshall/Profile";
import SelectMarshall from "./pages/Home/WebTraveller/SelectMarshall";
import FAQ from "./pages/Home/WebTraveller/FAQ";
import DocumentVault from "./pages/Home/WebTraveller/DocumentVault";
import Chats from "./pages/Chats/Chats";
import Profile_2 from "./pages/Home/WebTraveller/Profile_2";
import ChatsMarshall from "./pages/Chats/ChatsMarshall";
import { useContext } from "react";
import { AppContext } from "./context/user/AppContext";
import { Provider } from "react-redux";
import store from "./Redux/store";
import ApiConfig from "./api/ApiConfig";
import { simpleGetCallWithErrorResponse } from "./api/ApiServices";
import SubscribeWeb from "./pages/Home/WebTraveller/SubscribeWeb";
import ContactUs from "./pages/ContactUs";
import AboutUsHome from "./pages/AboutUsHome";

function App() {
  const currentRoute = useLocation().pathname;
  const {
    role,
    logedIn,
    setLoggedIn,
    profileDetails,
    setProfileDetails,
    customerData,
    webLogin,
    userDetails,
  } = useContext(AppContext);
  const navigate = useNavigate();
  const params = useLocation().pathname;
  const token = params.split("/")[2];
  useEffect(() => {
    if (webLogin) {
      navigate("/Subscription");
    } else navigate("/");
    return () => {};
  }, [webLogin]);

  useEffect(() => {
    if (
      // userDetails &&
      // userDetails.account_varification === "Verified" &&
      logedIn &&
      role === "Traveler"
    ) {
      navigate("/SelectMarshall");
    } else if (
      // userDetails &&
      // userDetails.account_varification === "Verified" &&
      logedIn &&
      role === "TravelMarshall"
    )
      navigate("/home");
    if (!logedIn) {
      navigate("/");
    }
    return () => {};
  }, [logedIn]);

  useEffect(() => {
    if (!logedIn) {
      navigate("/");
    } else {
      navigate(currentRoute);
    }
    getProfileDetails();
    return () => {};
  }, []);

  const getProfileDetails = () => {
    simpleGetCallWithErrorResponse(
      ApiConfig.TREVFERR_PROFILE,
      JSON.stringify({ ...customerData, user_id: profileDetails.id })
    ).then((res) => {
      // console.log(res);
      let user_profile = res.json.user_profile;
      setProfileDetails({
        is_subscription: res.json.is_subscription,
        plan: res.json.plan,
        expire_date: res.json.expire_date,
        email: user_profile.email,
        phone_number: user_profile.phone_number,
        emergency_contact_no: user_profile.emergency_contact_no,
        interest: user_profile.interest,
        bio: user_profile.bio,
      });
    });
  };

  return (
    <div>
      {/* <Header/> */}
      <Provider store={store}>
        <Routes>
          <Route path="/" element={<GetStarted logedIn={logedIn} />} />

          <Route path="Login" element={<Login setLoggedIn={setLoggedIn} />} />

          <Route
            path="Header"
            element={logedIn && <Header setLoggedIn={setLoggedIn} />}
          />

          <Route path="Footer" element={<Footer />} />
          <Route path="ForgotPassword" element={<ForgotPassword />} />
          <Route path="Verification" element={<Verification />} />
          <Route path="Home" element={<Home />} />
          <Route path="Emergency" element={<Emergency />} />
          <Route path="Aboutus" element={<Aboutus />} />
          <Route path="TermandCondition" element={<TermandCondition />} />
          <Route path="PrivacyandPolicy" element={<PrivacyandPolicy />} />
          <Route path="FAQ" element={<FAQ />} />
          <Route path="TranscationHistory" element={<TranscationHistory />} />
          <Route path="TransferMoney" element={<TransferMoney />} />
          <Route path="DocumentVault" element={<DocumentVault />} />
          <Route path="UPIPayments" element={<UPIPayments />} />
          <Route path="Subscription" element={<Subscription />} />
          <Route path="SubscribeWeb/:token" element={<SubscribeWeb />} />
          <Route path="BankPayments" element={<BankPayments />} />
          <Route path="Profile" element={<Profile />} />
          <Route path="Profile_2" element={<Profile_2 />} />
          <Route path="SelectMarshall" element={<SelectMarshall />} />
          <Route path="Chats" element={<Chats />} />
          <Route path="ChatsMarshall" element={<ChatsMarshall />} />
          <Route path="ContactUs" element={<ContactUs />} />
          <Route path="AboutUsHome" element={<AboutUsHome />} />

          <Route path="Models" element={<Models />} />
        </Routes>
      </Provider>
      {/* <Footer/> */}
    </div>
  );
}

export default App;
