import React, { useState } from "react";
import Header from "../../../sharedComponents/Header";
import Footer from "../../../sharedComponents/Footer";

import back from "../../../assets/images/PayMethods/back.svg";
import { Link, useNavigate } from "react-router-dom";
import ApiConfig from "../../../api/ApiConfig";
import swal from "sweetalert";
import { postMultipartWithAuthCallWithErrorResponse } from "../../../api/ApiServices";
const BankPayments = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [bankDetails, setBankDetails] = useState({
    transfer_option: "BANK_ACCOUNT",
    account_number: "",
    r_account_number: "",
    IFSC_code: "",
    bank_name: "",
    account_name: "",
  });

  const [errMsg, setErrMsg] = useState({
    transfer_option: "BANK_ACCOUNT",
    account_number: "",
    IFSC_code: "",
    r_account_number: "",
    bank_name: "",
    account_name: "",
  });
  const addBankDetails = async () => {
    if (
      bankDetails.transfer_option === "" ||
      bankDetails.account_number === "" ||
      bankDetails.IFSC_code === "" ||
      bankDetails.bank_name === "" ||
      bankDetails.account_name === ""
    ) {
      if (bankDetails.transfer_option === "") {
        setErrMsg({ ...errMsg, transfer_option: "Account number is requied" });
        // window.scrollTo(0, 0);

        return;
      }
      if (bankDetails.account_number === "") {
        setErrMsg({ ...errMsg, account_number: "Account number is requied" });
        // window.scrollTo(0, 0);

        return;
      }
      if (bankDetails.r_account_number === "") {
        setErrMsg({
          ...errMsg,
          r_account_number: '"Please enter confirm password!"',
        });
        return;
      }
      if (bankDetails.r_account_number !== bankDetails.account_number) {
        setErrMsg({
          ...errMsg,
          r_account_number: '"Password and Confirm Password do not match"',
        });
        return;
      }
      if (bankDetails.IFSC_code === "") {
        setErrMsg({ ...errMsg, IFSC_code: "IFSC Code is requied" });
        // window.scrollTo(0, 0);

        return;
      }
      if (bankDetails.bank_name === "") {
        setErrMsg({ ...errMsg, bank_name: "Bank Name is requied" });
        // window.scrollTo(0, 0);

        return;
      }
      if (bankDetails.account_name === "") {
        setErrMsg({ ...errMsg, account_name: "Account Name is requied" });
        // window.scrollTo(0, 0);

        return;
      }
    } else {
      let formData = new FormData();
      formData.append("transfer_option", bankDetails.transfer_option);
      formData.append("account_number", bankDetails.account_number);
      formData.append("IFSC_code", bankDetails.IFSC_code);
      formData.append("bank_name", bankDetails.bank_name);
      formData.append("account_name", bankDetails.account_name);
      // let details = JSON.stringify({
      //   transfer_option: bankDetails.transfer_option,
      //   account_number: bankDetails.account_number,
      //   IFSC_code: bankDetails.IFSC_code,
      //   bank_name: bankDetails.bank_name,
      //   account_name: bankDetails.account_name,
      // });
      // formData.append("details", details);
      setLoading(true);
      postMultipartWithAuthCallWithErrorResponse(
        ApiConfig.ADD_BANK_DETAILS,
        formData
      )
        .then((res) => {
          // console.log(res);
          setLoading(false);
          swal("Account Added Successfully");
          if (res) navigate("/transferMoney");
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <>
      <Header />

      <main className="home-getStarted">
        <div className="">
          <div className="about_banner"></div>
          {/* <img src={emergency} alt="" /> */}
        </div>
        <div className="container mt-2" style={{ marginBottom: "200px" }}>
          <h5 htmlFor="" className="Home_req">
            Add Recipients Details
          </h5>

          <div className="row">
            <div className="col-lg-3"></div>
            <div className="col-lg-5">
              <div className="justify-content-center">
                <div className="card-doc-bank p-2 " id="cards">
                  <div className=" border-bottom">
                    <div className="d-flex justify-content-between">
                      <Link to="/TransferMoney">
                        <img src={back} alt="" className="m-3" />
                      </Link>
                      <div
                        htmlFor=""
                        className="payment_heading text-center  pt-3 pe-5"
                      >
                        Add Bank Payment
                      </div>
                      <div></div>
                    </div>
                  </div>
                  <div className="  p-3">
                    <div className=" mb-2">
                      <label htmlFor="" className="bank_text pb-2 ms-2">
                        Transfer Options
                      </label>
                      <select
                        className=" form-select mb-3 ms-2"
                        aria-label="Default select example"
                      >
                        <option selected value="BANK_ACCOUNT">
                          BANK_ACCOUNT
                        </option>
                        {/* <option value="2"></option> */}
                        {/* <option value="3"> SBI</option> */}
                      </select>
                      <div>
                        <label
                          for="exampleFormControlInput1"
                          className="bank_text pb-2 ms-2"
                        >
                          Account Number
                        </label>
                        <input
                          type="number"
                          className="form-control  ms-2 upi-input2"
                          id="account_number"
                          pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                          value={bankDetails.account_number}
                          onChange={(e) => {
                            setErrMsg({ ...errMsg, account_number: "" });
                            setBankDetails({
                              ...bankDetails,
                              account_number: e.target.value,
                            });
                          }}
                        />
                        {errMsg.account_number.length > 0 && (
                          <span className="text-danger">
                            {errMsg.account_number}
                          </span>
                        )}
                      </div>
                      <div>
                        <label
                          for="exampleFormControlInput1"
                          className="bank_text pb-2 pt-2 ms-2"
                        >
                          Re-enter Account Number
                        </label>

                        <input
                          type="number"
                          className="form-control ms-2 upi-input2"
                          id="r_account_number"
                          pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                          aria-label="location"
                          onChange={(e) => {
                            setErrMsg({ ...errMsg, r_account_number: "" });
                            setBankDetails({
                              ...bankDetails,
                              r_account_number: e.target.value,
                            });
                          }}
                        />
                        {errMsg.r_account_number.length > 0 && (
                          <span className="text-danger">
                            {errMsg.r_account_number}
                          </span>
                        )}
                      </div>
                      <div>
                        <label
                          for="exampleFormControlInput1"
                          className="bank_text pb-2 pt-2 ms-2"
                        >
                          IFSC
                        </label>

                        <input
                          type="text"
                          className="form-control ms-2 upi-input2"
                          id="IFSC_code "
                          value={bankDetails.IFSC_code}
                          onChange={(e) => {
                            setErrMsg({ ...errMsg, IFSC_code: "" });
                            setBankDetails({
                              ...bankDetails,
                              IFSC_code: e.target.value,
                            });
                          }}
                        />
                      </div>
                      {errMsg.IFSC_code.length > 0 && (
                        <span className="text-danger">{errMsg.IFSC_code}</span>
                      )}
                      <div>
                        <label
                          for="exampleFormControlInput1"
                          className="bank_text pb-2 pt-2 ms-2"
                        >
                          Bank Name
                        </label>

                        <input
                          type="text"
                          className="form-control ms-2 upi-input2"
                          id="bank_name "
                          value={bankDetails.bank_name}
                          onChange={(e) => {
                            setErrMsg({ ...errMsg, bank_name: "" });
                            setBankDetails({
                              ...bankDetails,
                              bank_name: e.target.value,
                            });
                          }}
                        />
                      </div>
                      {errMsg.bank_name.length > 0 && (
                        <span className="text-danger">{errMsg.bank_name}</span>
                      )}
                      <div>
                        <label
                          for="exampleFormControlInput1"
                          className="bank_text pb-2 pt-2 ms-2"
                        >
                          Account Name
                        </label>

                        <input
                          type="text"
                          className="form-control ms-2 upi-input2"
                          id="account_name "
                          value={bankDetails.account_name}
                          onChange={(e) => {
                            setErrMsg({ ...errMsg, account_name: "" });
                            setBankDetails({
                              ...bankDetails,
                              account_name: e.target.value,
                            });
                          }}
                        />
                      </div>
                      {errMsg.account_name.length > 0 && (
                        <span className="text-danger">
                          {errMsg.account_name}
                        </span>
                      )}
                    </div>

                    <p className="text-left bank_p ms-2">
                      This information will be securely saved as per Travferr's
                      Terms of Services and Privacy Policy.
                    </p>

                    <div className="row justify-content-end pb-2 mt-2">
                      <div className="col-lg-6 col-md-5">
                        <button
                          className="add-btn text-center"
                          id=""
                          onClick={addBankDetails}
                        >
                          <span>Add</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </main>
    </>
  );
};

export default BankPayments;
