import React, { useContext, useEffect, useState } from "react";
import Footer from "../../../sharedComponents/Footer";
import Header from "../../../sharedComponents/Header";
import {
  simpleGetCallWithErrorResponse,
  updateProfile,
} from "../../../api/ApiServices";
import { AppContext } from "../../../context/user/AppContext";
import ApiConfig from "../../../api/ApiConfig";
import swal from "sweetalert";
import Moment from "react-moment";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const Profile_2 = () => {
  const [interestData, setInterestData] = useState({});

  const [profileInfo, setProfileInfo] = useState({
    email: "",
    avatar_id: "",
    phone_number: "",
    emergency_contact_no: "",
    country_code: "",
    interest: [],
    bio: "",
  });
  const { customerData, userDetails, profileDetails } = useContext(AppContext);

  const [loading, setLoading] = useState(false);
  function refreshPage() {
    UpdateProfile();
    setTimeout(() => {
      window.location.reload(false);
    }, 0);
  }
  useEffect(() => {
    getProfileInfo();
    return () => {};
  }, []);
  useEffect(() => {
    getUserInterest();
    return () => {};
  }, [loading]);
  const getProfileInfo = () => {
    setLoading(true);
    simpleGetCallWithErrorResponse(
      ApiConfig.TREVFERR_PROFILE,
      JSON.stringify({ ...customerData, user_id: profileInfo.id })
    ).then((res) => {
      // console.log(res);
      let user_profile = res.json.user_profile;
      setProfileInfo({
        email: user_profile.email,
        phone_number: user_profile.phone_number,
        emergency_contact_no: user_profile.emergency_contact_no,
        country_code: user_profile.country_code,
        interest: user_profile.interest,
        bio: user_profile.bio,
      });
      setLoading(false);
    });
  };

  const getUserInterest = () => {
    simpleGetCallWithErrorResponse(ApiConfig.GET_INTEREST)
      .then((res) => {
        // console.log(res);
        let int = res.json.map((inte) => {
          if (
            profileInfo.interest.filter((int) => int.id === inte.id).length > 0
          ) {
            return { ...inte, checked: true };
          } else {
            return { ...inte, checked: false };
          }
        });

        setInterestData(int);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const UpdateProfile = () => {
    setLoading(true);

    let detail = JSON.stringify({
      email: profileInfo.email,
      phone_number: profileInfo.phone_number,
      interest: interestData
        .filter((int) => {
          if (int.checked === true) return { id: int.id };
        })
        .map((int) => {
          return { id: int.id };
        }),
      emergency_contact_no: profileInfo.emergency_contact_no,
      country_code: profileInfo.country_code,
      bio: profileInfo.bio,
      avatar_id:
        profileInfo.avatar_id === "" || null ? null : profileInfo.avatar_id,
    });
    const formData = new FormData();
    formData.append("details", detail);

    updateProfile(ApiConfig.UPDATE_PROFILE, formData)
      .then((res) => {
        // console.log(res);
        swal("Profile Update Successfully");
        setLoading(false);
      })

      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <Header />

      <main className="home-getStarted">
        <div className="">
          <div className="about_banner"></div>
          {/* <img src={emergency} alt="" /> */}
        </div>
        <div className="container mt-2" style={{ marginBottom: "50px" }}>
          <div className="">
            <h5 htmlFor="" className="Home_req">
              {customerData.unique_id}
            </h5>
            <h6 className="text-danger">
              Subscription expires on{" "}
              <Moment format="YYYY/MM/DD">
                {profileDetails.expire_date ? profileDetails.expire_date : "  "}
              </Moment>
            </h6>
          </div>
          {loading ? (
            <div
              className="d-flex spinner-border mx-auto mt-2"
              style={{
                color: "#3D313A",
                width: "70px",
                height: "70px",
              }}
            />
          ) : (
            <div className="contanier">
              <div className="row">
                <div className="Profile-card">
                  <div className="col-lg-12">
                    <div className="d-flex justify-content-center pt-3">
                      <label htmlFor="" className="text-center profile-heading">
                        {userDetails && userDetails.full_name}
                      </label>
                    </div>
                  </div>
                  <hr />
                  <div className="card-body  profile-body">
                    <form
                      action=""
                      className="profile-group justify-content-between"
                    >
                      <div className="emailIgroup">
                        <label className="bank_text" for="inputEmail4">
                          Email ID
                        </label>
                        <input
                          type="email"
                          className="form-control email-input mt-2"
                          id="inputEmail4"
                          placeholder="Email"
                          value={profileInfo.email}
                          onChange={(e) => {
                            // setErrMsg({ ...errMsg, email: "" });
                            setProfileInfo({
                              ...profileInfo,
                              email: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <div className="emailIgroup">
                        <label className="bank_text" for="inputEmail4">
                          Phone Number
                        </label>
                        <input
                          maxLength={16}
                          className="form-control email-input mt-2"
                          id="inputEmail4"
                          placeholder="Phone Number"
                          value={profileInfo.phone_number}
                          onChange={(e) => {
                            // setErrMsg({ ...errMsg, phone_number: "" });
                            setProfileInfo({
                              ...profileInfo,
                              phone_number: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </form>
                    <form
                      action=""
                      className=" profile-group justify-content-between mt-3"
                    >
                      <div className="row">
                        <div className="emailIgroup">
                          <label className="bank_text" for="inputEmail4">
                            Country Code
                          </label>
                          {/* <input
                            maxLength={4}
                            className="form-control email-input mt-1"
                            id="inputEmail4"
                            value={profileInfo.country_code}
                            onChange={(e) => {
                              // setErrMsg({ ...errMsg, emergency_contact_no: "" });
                              setProfileInfo({
                                ...profileInfo,
                                country_code: e.target.value,
                              });
                            }}
                          /> */}
                          <PhoneInput
                            country={"us"}
                            value={profileInfo.country_code}
                            onChange={(e) =>
                              setProfileInfo({
                                ...profileInfo,
                                country_code: e,
                              })
                            }
                          />
                        </div>
                        <div className="emailIgroup">
                          <label className="bank_text" for="inputEmail4">
                            Emergency Contact No.
                          </label>
                          <input
                            maxLength={16}
                            className="form-control email-input mt-1 col-6"
                            id="inputEmail4"
                            placeholder="+919999999999"
                            value={profileInfo.emergency_contact_no}
                            onChange={(e) => {
                              // setErrMsg({ ...errMsg, emergency_contact_no: "" });
                              setProfileInfo({
                                ...profileInfo,
                                emergency_contact_no: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>

                      <div className="emailIgroup">
                        <label className="bank_text" for="inputEmail4">
                          Alternate Contact Number
                        </label>
                        <textarea
                          type="number"
                          id="exampleFormControlTextarea1"
                          className="form-control "
                          rows="1"
                          value={profileInfo.bio}
                          onChange={(e) => {
                            // setErrMsg({ ...errMsg, phone_number: "" });
                            setProfileInfo({
                              ...profileInfo,
                              bio: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </form>

                    <div>
                      <div className="row  mt-3">
                        <div className="col-lg-4">
                          <label for="inputAddress2" className="bank_text ">
                            Interests
                          </label>
                        </div>
                        <div className="col-lg-8 ">
                          <div className="text-end">
                            <label for="inputAddress2" className="bank_text">
                              {profileInfo.interest &&
                                profileInfo.interest.length}
                              /14
                            </label>
                          </div>
                        </div>
                        <div className="interest-content col-lg-12 col-xs-6 ">
                          {interestData &&
                            interestData.length &&
                            interestData.map((data, index) => {
                              return (
                                <div
                                  className=" col-lg-2 interest"
                                  key={"data" + index}
                                >
                                  <div className="position-relative">
                                    <div className="bank-round-checkbox1">
                                      <input
                                        type="checkbox"
                                        id={"checkbox" + index}
                                        checked={data.checked}
                                        onChange={(e) => {
                                          let int = interestData.map((int) => {
                                            if (data.id === int.id)
                                              return {
                                                ...int,
                                                checked: e.target.checked,
                                              };
                                            else return int;
                                          });
                                          setInterestData(int);
                                        }}
                                      />
                                      <label
                                        htmlFor={"checkbox" + index}
                                      ></label>
                                    </div>
                                    <p
                                      className="category_name"
                                      style={{
                                        position: "absolute",
                                        top: "30px",
                                        left: "10px",
                                        fontSize: "13px",

                                        color: "#FFF",
                                      }}
                                    >
                                      {data.name_of_category}
                                    </p>
                                  </div>
                                  <img
                                    src={data.category_image}
                                    alt=""
                                    className="interest-img"
                                    style={{
                                      width: "196px",
                                      height: "61px",
                                      borderRadius: "12px",
                                    }}
                                  />
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>

                    <div className=" ">
                      <div className=" col-lg-12">
                        <div className="text-end  button_update">
                          <button className="update-btn text-center " id="">
                            <span
                              className="text-center"
                              onClick={() => refreshPage()}
                            >
                              {loading ? (
                                <div
                                  className="spinner-border"
                                  style={{
                                    textAlign: "right",
                                    color: "#FFFBF3",
                                  }}
                                />
                              ) : (
                                "Update Profile"
                              )}
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <Footer />
      </main>
    </>
  );
};

export default Profile_2;
