import { AppContext } from "./AppContext";

import React, { useState } from "react";

const AppState = (props) => {
  const [logedIn, setLoggedIn] = useState(
    localStorage.getItem("logedIn") ? localStorage.getItem("logedIn") : false
  );
  const [customerData, setCustomerData] = useState({
    id: localStorage.getItem("id") ? localStorage.getItem("id") : null,
    unique_id: localStorage.getItem("unique_id")
      ? localStorage.getItem("unique_id")
      : null,

    auth_token: localStorage.getItem("auth_token")
      ? localStorage.getItem("auth_token")
      : null,
    role: localStorage.getItem("role") ? localStorage.getItem("role") : null,
  });
  const [userDetails, setUserDetails] = useState(
    localStorage.getItem("userDetails")
      ? JSON.parse(localStorage.getItem("userDetails"))
      : null
  );
  const [role, setRole] = useState(localStorage.getItem("role"));
  const [currentUser, setCurrentUser] = useState("");
  const [webLogin, setWebLogin] = useState(false);

  const [profileDetails, setProfileDetails] = useState({
    full_name: "",
    email: "",
    phone_number: "",
    interest: [],
    bio: "",
    avatar_id: "",
    is_subscription: "",
    // emergency_contact_no:"",
    documents: [
      {
        document_name: "",
        document_image: "",
        id_number: "",
        id: "",
      },
    ],
  });
  return (
    <AppContext.Provider
      value={{
        customerData,
        setCustomerData,
        userDetails,
        setUserDetails,
        currentUser,
        setCurrentUser,
        role,
        setRole,
        webLogin,
        setWebLogin,
        logedIn,
        setLoggedIn,
        profileDetails,
        setProfileDetails,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export default AppState;
