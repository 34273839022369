// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup, FacebookAuthProvider } from 'firebase/auth'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyB1wPJZcMF4wom9mO1TUoSvHfFtZCtskGs",
    authDomain: "travferr-98254.firebaseapp.com",
    projectId: "travferr-98254",
    storageBucket: "travferr-98254.appspot.com",
    messagingSenderId: "882898085129",
    appId: "1:882898085129:web:4b2060b75c3ec7265a9fd8",
    measurementId: "G-6Z1FX31CYN"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
auth.languageCode = 'it';
const provider = new GoogleAuthProvider();
const facebook = new FacebookAuthProvider()
export const signInWithGoogle = () => signInWithPopup(auth, provider)
export const signInWithFaceBook = () => signInWithPopup(auth, facebook)
