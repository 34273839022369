import React, { useContext, useEffect, useState } from "react";
import Footer from "../../../sharedComponents/Footer";
import close from "../../../assets/images/close.svg";
import { Modal } from "react-bootstrap";
import Header from "../../../sharedComponents/Header";
import {
  deleteWithAuthCall,
  getWithAuthCall,
  postMultipartWithAuthCallWithErrorResponse,
  simplePostCall,
} from "../../../api/ApiServices";
import { AppContext } from "../../../context/user/AppContext";
import ApiConfig from "../../../api/ApiConfig";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";

const DocumentVault = () => {
  const [add, setAdd] = useState(false);
  const [documents, setDocuments] = useState({});
  const [loading, setLoading] = useState(false);
  const { customerData } = useContext(AppContext);
  const navigate = useNavigate();

  const [state, setState] = useState({
    id: "",
    file_title: "",
    file: "",
  });
  const [errMsg, setErrMsg] = useState({ file: "", file_title: "" });

  useEffect(() => {
    getDocList();
    return () => {};
  }, []);

  const getDocList = () => {
    setLoading(true);
    getWithAuthCall(
      ApiConfig.DOCUMENT_LIST,
      JSON.stringify({ ...customerData })
    ).then((res) => {
      // console.log(res);
      setDocuments(res.List_Documents);
      setLoading(false);
    });
  };

  const create = () => {
    setLoading(true);

    if (state.file_title === "") {
      setErrMsg({ ...errMsg, file_title: "File Name is required" });
      // window.scrollTo(0, 0);
      return;
    }
    if (!state.file.name && !state.file.length > 0) {
      setErrMsg({ ...errMsg, file: "Document is requied" });
      // window.scrollTo(0, 0);
      return;
    } else {
      const formData = new FormData();
      formData.append("file_title", state.file_title);
      formData.append("file", state.file);
      postMultipartWithAuthCallWithErrorResponse(
        ApiConfig.ADD_DOCUMENT,
        formData
      )
        .then((res) => {
          // console.log(res);
          getDocList();
          setAdd("");
          swal("Document Added Successfully");
          setLoading(false);
        })
        .catch((err) => console.log(err));
    }
  };

  const DeleteDoc = (id) => {
    setLoading(true);
    deleteWithAuthCall(ApiConfig.DELETE_DOC + id)
      .then((res) => {
        // console.log(res);
        getDocList();
        swal("Document Deleted Successfully");
      })

      .catch((err) => {
        setLoading(false);

        console.log(err);
      });
  };

  return (
    <>
      <Header />

      <main className="home-getStarted">
        <div className="">
          <div className="about_banner"></div>
        </div>
        <div className="container mt-2" style={{ marginBottom: "200px" }}>
          <div className="row  ">
            <div className="TranHistory justify-content-between">
              <div className="col-lg-7 ">
                <h5 htmlFor="" className="emr_fileld pt-2">
                  Document Vault
                </h5>
              </div>

              <div className="col-lg-2 form-group  position-relative doc_add_fileld ">
                <button
                  className="btn doc_Add-btn"
                  type="button"
                  data-toggle="add"
                  data-target="#add"
                  id="bids"
                  onClick={() => setAdd(!add)}
                >
                  <span className="add-doc-text">Add Document</span>
                  <span className="add-doc-icon">
                    <i className="bi bi-plus-lg"></i>
                  </span>

                  {/* <i className="bi bi-plus-lg"></i> */}
                </button>
              </div>
            </div>
          </div>

          <>
            {documents ? (
              documents.length > 0 &&
              documents.map((doc, index) => {
                return (
                  <>
                    <div className="row mt-4" key={"doc" + index}>
                      <div className="col-lg-12 mt-2">
                        <div className="card-doc ">
                          <div className="card-body ">
                            <div className="row p-1">
                              <div className=" d-flex justify-content-between">
                                <div className="col-lg-6 d-flex">
                                  <img
                                    src={doc.file}
                                    className=""
                                    alt=""
                                    style={{ width: "30px" }}
                                  />
                                  <div>
                                    <label className="PYcard-title  pt-4 ps-4">
                                      {doc.file_title}
                                    </label>
                                  </div>
                                </div>
                                <div className=" mt-3">
                                  <a href="#" onClick={() => DeleteDoc(doc.id)}>
                                    <img src={close} alt="" />
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })
            ) : (
              <>
                {loading ? (
                  <div
                    className="d-flex spinner-border mx-auto mt-2"
                    style={{
                      color: "#3D313A",
                      width: "70px",
                      height: "70px",
                    }}
                  />
                ) : (
                  <h4 className="text-center text-danger mt-5">
                    No Data Found
                  </h4>
                )}
              </>
            )}
          </>
        </div>
        <Footer />
      </main>
      <Modal
        responsive-md
        className="modal-md"
        id="add"
        role="dialog"
        show={add}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <a href=""></a>
        <div className="justify-content-between d-flex">
          <p className="text-left">Add Document</p>
          <a href="#">
            <Modal.Header
              closeButton
              onClick={() => {
                setAdd("");
              }}
            />
          </a>
        </div>
        <div className="form-group doc-name-1 " style={{ marginTop: "50px" }}>
          <label htmlFor="" className="model_document">
            Document Name
          </label>
          <input
            className="form-control model_Text mt-2"
            id=""
            rows="1"
            placeholder="Enter Document Name"
            value={state.file_title}
            onChange={(e) => {
              setErrMsg({ ...errMsg, file_title: "" });
              setState({ ...state, file_title: e.target.value });
            }}
          />
          {errMsg.file_title.length > 0 && (
            <div className="text-center text-danger">
              <span> {errMsg.file_title}</span>
            </div>
          )}
        </div>

        <form className="mt-4 model_document">
          <label for="AddFile" className="addfile">
            Add File
          </label>
          <div className=" justify-content-between align-items-start model_Text mt-2">
            <div className="form-group">
              <input
                type="file"
                className="form-control email-input  "
                onChange={(e) => {
                  setErrMsg({ ...errMsg, file: "" });
                  setState({ ...state, file: e.target.files[0] });
                }}
              />
            </div>
          </div>
          {errMsg.file.length > 0 && (
            <div className="text-center text-danger">
              <span> {errMsg.file}</span>
            </div>
          )}
        </form>
        {/* <div className="btn-popup-modal justify-content-evenly  d-flex mt-4 pt-3">
          <a href="" className="m-1 ms-5">
            <button className="btn popdis-btn">Cancel</button>
          </a>

          <a href="" className="m-1 me-5">
            <button className="btn report-btn">Add</button>
          </a>
        </div> */}
        <div
          className="d-flex justify-content-center doc-add-btns "
          style={{ marginTop: "100px" }}
        >
          <div>
            <a
              href="#"
              onClick={() => {
                setAdd("");
              }}
            >
              <button className="cancel-btn-chats111">Cancel</button>
            </a>
          </div>
          <div>
            {" "}
            <button className="cancel-btn-report11" onClick={create}>
              {/* {loading ? (
                <div
                  className="spinner-border"
                  style={{
                    textAlign: "right",
                    color: "#FFFBF3",
                  }}
                />
              ) : ( */}
              Add
              {/* )} */}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DocumentVault;
