import React, { useEffect, useState } from "react";
import Footer from "../../sharedComponents/Footer";
import user_1 from "../../assets/images/Home/user_1.svg";
import user_3 from "../../assets/images/Chats/user_3.svg";
import options from "../../assets/images/Chats/options.svg";
import online_icon from "../../assets/images/Chats/online_icon.svg";
import { Link, useLocation } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Header from "../../sharedComponents/Header";

import disconnect from "../../assets/images/model/disconnect.svg";
import report_icon from "../../assets/images/model/report_icon.svg";
import image from "../../assets/images/Chats/image.svg";
import send from "../../assets/images/Chats/send.svg";
import typing from "../../assets/images/Chats/typing.png";
import yello from "../../assets/images/model/yello.svg";
import grey from "../../assets/images/model/grey.svg";
import cancel from "../../assets/images/cancel.svg";
import up_arrow from "../../assets/images/Navbar/up_arrow.svg";
import { simpleGetCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import openSocket from "socket.io-client";

const ChatsMarshall = ({ setLoggedIn }) => {
  const [show, setShow] = useState(false);
  const [report, setReport] = useState(false);
  const [block, setBlock] = useState(false);
  const [disconnet, setdisconnet] = useState(false);
  const [rating, setRating] = useState(false);
  const [chatList, setChatList] = useState([{}]);
  const [loading, setLoading] = useState(false);
  const [activeChat, setActiveChat] = useState({});
  const [selectedChat, setSelectedChat] = useState({});
  const [message, setMessage] = useState("");
  const data = useLocation().state;

  let option = {
    // timeout: 20000,
    query: {
      UID: localStorage.getItem("id"),
    },
  };

  const socket = openSocket("http://travferr.redbytes.in:5050/", option);

  useEffect(() => {
    return () => {};
  }, [selectedChat]);

  const getChat = () => {};

  useEffect(() => {
    getChatList();

    socket.on("connect", (message) => {
      // console.log the message for posterity
      // trigger the callback passed in when
      // our App component calls connect
    });
    socket.emit("join_group", {
      from: selectedChat.unique_id,
      from_id: localStorage.getItem("id"),
      gp: activeChat.private_chat_room,
      to_id: activeChat.private_chat_to,
    });
    socket.on("message created", async (data) => {
      // console.log("data....", data);
      alert(data.message);
    });
    return () => {};
  }, []);

  const sendMessage = (data) => {
    socket.emit("new message", {
      conversation_type: "text",
      files: [],
      message: message,
      receiverId: activeChat.private_chat_to,
      room: activeChat.private_chat_room,
      senderID: localStorage.getItem("id"),
      type: "private",
      username: selectedChat.unique_id,
      userpic: selectedChat.avatar,
    });
  };

  const getChatList = () => {
    simpleGetCallWithErrorResponse(ApiConfig.CHAT_LIST_HISTORY).then((res) => {
      // console.log(res);
      setLoading(false);
      setChatList(res.json.list);
      if (res.json.list && res.json.list.length > 0)
        setSelectedChat(res.json.list[0]);
    });
  };

  const handleClose = () => setShow(false);
  return (
    <>
      <main className="home-getStarted">
        <Header setLoggedIn={setLoggedIn} />
        <Modal
          responsive-md
          className="modal-md"
          id="rating"
          role="dialog"
          show={show}
          onHide={handleClose}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton></Modal.Header>

          <div className="text-center">
            <div className="img-rating">
              <img src={user_3} alt="" />
            </div>
            <div>
              <p className="user-name mt-3 mb-3">
                TM65413
                <br />{" "}
              </p>
            </div>
            <div className="rating-star">
              <p className="text-center popup-heading mt-1 mb-2">
                Do you like the service?
              </p>
              <img src={yello} alt="" />
              <img src={yello} alt="" />

              <img src={yello} alt="" />
              <img src={yello} alt="" />
              <img src={grey} alt="" />
            </div>
            <p className="text-center model_rate mt-2">Tap a Star to Rate</p>
            <label htmlFor="" className="model_feedback mb-3 mt-3">
              Give your valuable feedback
            </label>
            <div>
              <div className="">
                <select
                  className=" form-select mb-2"
                  aria-label="Default select example"
                >
                  <option selected>TM was behaviour was professional</option>
                  <option value="1"> Behaviour </option>
                  <option value="2"> TM was behaviour was professional</option>
                  <option value="3"> professional</option>
                </select>
              </div>
            </div>
            <div className="form-group text-report mt-3">
              <textarea
                className="form-control model_text2"
                id="exampleFormControlTextarea1"
                rows="4"
                placeholder="Leave a review (Optional)"
              ></textarea>
            </div>
          </div>
          <div className="btn-popup-modal justify-content-evenly  d-flex">
            <div className="pt-2 ms-5">
              <a href="">
                <button className="popdis-btn">Dismiss</button>
              </a>
            </div>
            <div className="pt-2  me-5">
              <a href="">
                <button className="popup-btn" onClick={() => setShow(show)}>
                  Submit
                </button>
              </a>
            </div>
          </div>
        </Modal>

        <div className="container" style={{ marginBottom: "200px" }}>
          {/* <h5 htmlFor="" className="chat_head">
            Chats
          </h5> */}

          <div className="container chatcontainer">
            <div className="row">
              {/* <Header/> */}
              <h5 htmlFor="" className="chat_head">
                Chats
              </h5>
            </div>
            <div className="chats_outside  ">
              <div className="col-lg-12  p-4 chats_outside ">
                <div className="card p-2  chats_inside" id="card">
                  <div className="row">
                    <div className="col-lg-3">
                      <label htmlFor="" className="ACTIVE">
                        ACTIVE WT
                      </label>
                      <div className="row ">
                        <div className="row ">
                          {chatList &&
                            chatList.length &&
                            chatList.map((list, index) => {
                              return (
                                <div
                                  className=" mt-2"
                                  onClick={() => {
                                    setActiveChat(list.chat.chat_room_details);
                                    setSelectedChat(list);
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  <div
                                    className="card card_active p-2"
                                    id="card"
                                    key={"list" + index}
                                  >
                                    <div className=" d-flex justify-content-between">
                                      <div className="d-flex  ">
                                        <img
                                          src={list.avatar}
                                          className="marchall_img"
                                          style={{ width: "35px" }}
                                          alt=""
                                        />
                                        <div className="ms-2">
                                          <p className="userid">
                                            {list.unique_id}
                                            <p className="man" id="man">
                                              {list.chat &&
                                                list.chat.conversation_message}
                                            </p>
                                          </p>
                                        </div>
                                      </div>

                                      <p className=" time  pt-1 ">3 min ago</p>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                        <label htmlFor="" className="recent_chat mt-3 ps-3">
                          RECENT CHAT HISTORY
                        </label>

                        <div className=" mt-2">
                          <div className="card  card_deactive" id="card">
                            <div className="p-2">
                              <div className=" d-flex justify-content-between">
                                <div className="d-flex  ">
                                  <img
                                    src={user_3}
                                    className="marchall_img"
                                    alt=""
                                  />
                                  <div className="ms-2">
                                    <p className="userid pt-2 ">TM65413</p>
                                    <p className="date1" id="man">
                                      05 / 02 / 2022
                                    </p>
                                  </div>
                                </div>
                                <div className="text-center mt-1">
                                  <a href="">
                                    <img
                                      src={cancel}
                                      alt=""
                                      className=" mt-2 me-2"
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className=" mt-2">
                          <div className="card card_deactive " id="card">
                            <div className="p-2">
                              <div className=" d-flex justify-content-between">
                                <div className="d-flex  ">
                                  <img
                                    src={user_3}
                                    className="marchall_img"
                                    alt=""
                                  />
                                  <div className="ms-2">
                                    <p className="userid  pt-2 ">TM65413</p>
                                    <p className="date1" id="man">
                                      05 / 02 / 2022
                                    </p>
                                  </div>
                                </div>
                                <a href="">
                                  <img
                                    src={cancel}
                                    alt=""
                                    className="mt-2 me-2"
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className=" mt-2">
                          <div className="card card_deactive ">
                            <div className="p-2">
                              <div className=" d-flex justify-content-between">
                                <div className="d-flex  ">
                                  <img
                                    src={user_1}
                                    className="marchall_img"
                                    alt=""
                                  />
                                  <div className="ms-2">
                                    <p className="userid pt-2 ">TM65413</p>
                                    <p className="date1 " id="man">
                                      05 / 02 / 2022
                                    </p>
                                  </div>
                                </div>
                                <div className="text-center mt-1">
                                  <a href="">
                                    <img
                                      src={cancel}
                                      alt=""
                                      className="mt-2 me-2"
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className=" mt-2 pb-3">
                          <div className="card card_deactive ">
                            <div className="p-2">
                              <div className=" d-flex justify-content-between">
                                <div className="d-flex  ">
                                  <img
                                    src={user_3}
                                    className="marchall_img"
                                    alt=""
                                  />
                                  <div className="ms-2">
                                    <p className="userid pt-2 ">TM65413</p>
                                    <p className="date1 " id="man">
                                      05 / 02 / 2022
                                    </p>
                                  </div>
                                </div>
                                <div className="text-center mt-1">
                                  <a href="">
                                    <img
                                      src={cancel}
                                      alt=""
                                      className="mt-2 me-2"
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* -----Right section----- */}
                    <div className="col-lg-9">
                      <div className="header">
                        {/* <!-- Navbar Start --> */}
                        <nav
                          className="nav navbar   navbar-expand navbar-dark Chat_header"
                          id="card"
                        >
                          <div className="container ">
                            <Link className="navbar-brand d-flex pt-3" to="/">
                              <div className="online_Active">
                                <img src={user_1} alt="" height="40" />
                                <img
                                  src={online_icon}
                                  alt=""
                                  className="online"
                                />
                              </div>
                              <div className=" ms-2 ">
                                <p className="user_id">
                                  {selectedChat.unique_id}
                                  <p htmlFor="" className="d-block switch">
                                    Active
                                  </p>
                                </p>
                              </div>
                            </Link>
                            <div className="chat-dropdown dropdown">
                              <a
                                href=""
                                className="icon-dropdown"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <img
                                  src={options}
                                  className="ms-3 "
                                  alt=""
                                  height="24"
                                />
                              </a>
                              <ul className="dropdown-menu ">
                                <li>
                                  <button
                                    type="button"
                                    className="dropdown-item  disconnet "
                                    data-toggle="modal"
                                    data-target="#disconnet"
                                    id="bids"
                                    onClick={() => setdisconnet(!disconnet)}
                                  >
                                    <p className="dis-text">Disconnect </p>
                                    <img
                                      src={up_arrow}
                                      className="arrow"
                                      alt=""
                                    />
                                  </button>
                                </li>
                                <li>
                                  <button
                                    className="dropdown-item disconnet"
                                    type="button"
                                    data-toggle="modal"
                                    data-target="#reports"
                                    id="bids"
                                    onClick={() => setReport(!report)}
                                  >
                                    <p className="dis-text">Report</p>
                                  </button>
                                </li>
                                <li>
                                  <button
                                    className="dropdown-item disconnet"
                                    type="button"
                                    data-toggle="modal"
                                    data-target="#block"
                                    id="bids"
                                    onClick={() => setBlock(!block)}
                                  >
                                    <p className="dis-text">Block</p>
                                  </button>
                                </li>
                              </ul>
                            </div>
                            {/* ------------Disconnect Model ------ */}
                            <Modal
                              responsive-md
                              className="modal-xlg"
                              id="disconnet"
                              role="dialog"
                              show={disconnet}
                              size="md"
                              aria-labelledby="contained-modal-title-vcenter"
                              centered
                            >
                              <div className="container">
                                <div className="text-center justify-content-center">
                                  <div>
                                    <img src={disconnect} alt="" />
                                    <p className="d-block model_lael pt-3">
                                      Disconnect
                                    </p>
                                  </div>
                                </div>
                                <div>
                                  <p className="text-center popup-heading mt-4">
                                    Are you sure you want to disconnect with
                                    TM62816?
                                  </p>
                                </div>
                                {/* <div className="btn-popup-modal justify-content-evenly  d-flex">
                                  <div className="pt-2 ms-2">
                                    <a href="">
                                      <button className="popdis-btn">
                                        Cancel
                                      </button>
                                    </a>
                                  </div>
                                  <div className="pt-2  me-2">
                                    
                                      <button
                                        className="Disconnect-btn"
                                        onClick={() => setShow(!show)}
                                        data-toggle="modal"
                                        data-target="#rating"
                                        id="rating"
                                      >
                                        Disconnect
                                      </button>
                                 
                                  </div>
                                </div> */}
                                <div className="d-flex justify-content-center">
                                  <div>
                                    <a href="">
                                      <button className="cancel-btn-chats1">
                                        Cancel
                                      </button>
                                    </a>
                                  </div>
                                  <div>
                                    <button
                                      className="cancel-btn-chats2"
                                      onClick={() => setShow(!show)}
                                      data-toggle="modal"
                                      data-target="#rating"
                                      id="rating"
                                    >
                                      Disconnect
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </Modal>
                            {/* ---------------------Report Model ----------- */}
                            <Modal
                              responsive-md
                              className="modal-md"
                              id="reports"
                              role="dialog"
                              show={report}
                              size="lg"
                              aria-labelledby="contained-modal-title-vcenter"
                              centered
                            >
                              <div className="text-center justify-content-center">
                                <div>
                                  <img src={report_icon} alt="" />
                                  <label
                                    htmlFor=""
                                    className="d-block model_lael pt-3"
                                  >
                                    Report World Traveller
                                  </label>
                                </div>
                              </div>
                              <div>
                                <div className="pt-3">
                                  <p className="text-left popup-heading m-2">
                                    Why are you reporting this travel marshall?
                                  </p>
                                  <select
                                    className=" form-select mb-3 "
                                    aria-label="Default select example"
                                  >
                                    <option selected>
                                      Behaviour was Inappropriate
                                    </option>
                                    <option value="1"> Behaviour </option>
                                    <option value="2">
                                      {" "}
                                      conduct that is unwarranted
                                    </option>
                                    <option value="3">
                                      {" "}
                                      perceived as disruptive
                                    </option>
                                  </select>
                                </div>
                              </div>
                              <div className="form-group m-1 ">
                                <label className="popup-heading p-1">
                                  Please describe your reason for reporting .
                                </label>
                                <textarea
                                  className="form-control text-area model_text"
                                  id=""
                                  rows="3"
                                  placeholder="Type here"
                                ></textarea>
                              </div>
                              <div className="row mt-2">
                                {/* <div className="btn-popup-modal justify-content-evenly  d-flex ">
                                  <div className="pt-2 ms-5">
                                    <a href="">
                                      <button className="popdis-btn">
                                        Cancel
                                      </button>
                                    </a>
                                  </div>
                                  <div className="pt-2  me-5">
                                    <a href="">
                                      <button className="report-btn">
                                        Report
                                      </button>
                                    </a>
                                  </div>
                                </div> */}
                                <div className="d-flex justify-content-center">
                                  <div>
                                    <a href="">
                                      <button className="cancel-btn-chats1">
                                        Cancel
                                      </button>
                                    </a>
                                  </div>
                                  <div>
                                    <a href="">
                                      {" "}
                                      <button className="cancel-btn-report">
                                        Report
                                      </button>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </Modal>
                            {/* ---------------Block Model------------ */}
                            <Modal
                              responsive-md
                              className="modal-xlg"
                              id="block"
                              role="dialog"
                              show={block}
                              size="md"
                              aria-labelledby="contained-modal-title-vcenter"
                              centered
                            >
                              <div className="text-center justify-content-center">
                                <div>
                                  <img src={disconnect} alt="" />
                                  <label
                                    htmlFor=""
                                    className="d-block model_lael pt-3"
                                  >
                                    Block
                                  </label>
                                </div>
                              </div>
                              <div>
                                <p className="text-center popup-heading mt-4">
                                  Are you sure you want to Block with TM62816?
                                </p>
                              </div>
                              {/* <div className="btn-popup-modal justify-content-evenly  d-flex">
                                <div className="pt-2 ms-5">
                                  <a href="">
                                    <button className="popdis-btn">
                                      Cancel
                                    </button>
                                  </a>
                                </div>
                                <div className="pt-2  me-5">
                                  <a href="">
                                    <button className="Disconnect-btn">
                                      Block
                                    </button>
                                  </a>
                                </div>
                              </div> */}
                              <div className="d-flex justify-content-center">
                                <div>
                                  <a href="">
                                    <button className="cancel-btn-chats1">
                                      Cancel
                                    </button>
                                  </a>
                                </div>
                                <div>
                                  <a href="">
                                    <button className="cancel-btn-chats2">
                                      Block
                                    </button>
                                  </a>
                                </div>
                              </div>
                            </Modal>
                          </div>
                        </nav>
                        <div className="body p-3">
                          <div className="chat_section">
                            <p className="message d-block">
                              Yeah. Right! As if that would happen!
                            </p>
                            <p className="user_message">
                              Anywho, gotta roll. G'night!
                            </p>
                            <p className="message d-block">Goodnight!</p>
                            <p className="user_message">Wahat'sup!!!!!</p>
                            <p className="message d-block">hi Good Mooring</p>
                            <p className="message d-block">Have a Nice Day</p>
                            <div className="dy-line">
                              <div className="or mt-4 mb-4">
                                <div id="line">
                                  <span className="today">TODAY</span>
                                </div>
                              </div>
                            </div>
                            <p className="user_message">
                              We can do that for the next day.
                            </p>
                            <p className="message d-block">
                              sure please shear your refund ID
                            </p>{" "}
                            <p className="user_message">23442</p>
                            <p className="message d-block">
                              your refund ID Curreculty being processed.
                            </p>
                            <p className="user_message">
                              I want My refund Now,.
                            </p>
                          </div>

                          <div className="msg_input">
                            <img src={typing} alt="" className="typing_icon" />
                            <div className="footer">
                              <form action="" style={{ position: "relative" }}>
                                <img src={image} alt="" className="image_img" />
                                <input
                                  type="text"
                                  className="footer_input "
                                  placeholder="Type a message"
                                  onChange={(e) => setMessage(e.target.value)}
                                />

                                <a href="#">
                                  <img
                                    src={send}
                                    onClick={sendMessage}
                                    alt=""
                                    className="send1 mt-1"
                                  />
                                </a>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </main>
    </>
  );
};

export default ChatsMarshall;
