import React, { useContext, useEffect, useState } from "react";
import ApiConfig from "../../../api/ApiConfig";

import { simplePostCall } from "../../../api/ApiServices";
import { AppContext } from "../../../context/user/AppContext";
import { useParams } from "react-router-dom";
import logo from "../../../assets/images/travferrlogo.png";

export default function SubscribeWeb() {
  const { setCustomerData, setRole, setWebLogin, setLoggedIn } =
    useContext(AppContext);
  const params = useParams();
  const [token, setToken] = useState(params.token);

  useEffect(() => {
    // refreshPage();
    AuthVerify();
    return () => {};
  }, []);
  // function refreshPage() {
  //   setTimeout(() => {
  //     window.location.reload(false);
  //   }, 0);
  // }
  const AuthVerify = () => {
    simplePostCall(
      ApiConfig.AUTH_VERIFY,
      JSON.stringify({
        token,
      })
    )
      .then((res) => {
        // console.log(res);
        if (res.user_details) {
          localStorage.setItem("auth_token", res.user_details.auth_token);
          localStorage.setItem("id", res.user_details.id);
          localStorage.setItem("unique_id", res.user_details.unique_id);
          localStorage.setItem("role", res.user_details.role);
          localStorage.setItem("userDetails", JSON.stringify(res.user_details));
          setCustomerData({
            id: res.user_details.id,
            unique_id: res.user_details.unique_id,
            auth_token: res.user_details.auth_token,
            role: res.user_details.role,
            avatar: res.user_details.avatar ? (
              res.user_details.avatar
            ) : (
              <img
                src={logo}
                alt=""
                height="120"
                className="travfer-logo-main1"
              />
            ),
          });
          setRole(res.user_details.role);
          setLoggedIn(true);
          setWebLogin(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div>
      <div
        className="d-flex spinner-border position-absolute "
        style={{
          color: "#3D313A",
          width: "70px",
          top: "30%",
          left: "50%",
          height: "70px",
        }}
      />
    </div>
  );
}
