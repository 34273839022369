import React, { useState } from "react";
import Header from "../../../sharedComponents/Header";
import Footer from "../../../sharedComponents/Footer";
import Privacy from "./Privacy";

const PrivacyandPolicy = () => {
  return (
    <>
      <Header />

      <main className="home-getStarted">
        <div className="">
          <div className="about_banner"></div>
          {/* <img src={emergency} alt="" /> */}
        </div>
        <div className="container mt-2" style={{ marginBottom: "200px" }}>
          <h5 htmlFor="" className="Home_req ">
            Privacy Policy
          </h5>

          <div className="row ">
            <Privacy />
          </div>
        </div>
        <Footer />
      </main>
    </>
  );
};

export default PrivacyandPolicy;
