import React, { useContext, useState } from "react";
import Lottie from "lottie-react";
import animationData from "../assets/84794.json";
import travferrlogo from "../assets/images/travferrlogo.png";
// import { Link } from "react-router-dom";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { AppContext } from "../context/user/AppContext";

const GetStarted = ({ logedIn }) => {
  const { role } = useContext(AppContext);
  const navigate = useNavigate();
  const [btnExplore, setBtnExplore] = useState(false);
  // const handleExplore = () => {
  //   if (!logedIn) {
  //     setBtnExplore(true);
  //   }
  //   // else if (logedIn && role === "Traveller") {
  //   //   navigate("/SelectMarshall");
  //   // } else if (logedIn && role === "TravelMarshall") {
  //   //   navigate("/home");
  //   // }
  // };

  return (
    <div className="container-fluid">
      <div className="backgroung-img-travfer1">
        <div className="get-navbar1 ">
          <Lottie
            loop={true}
            animationData={animationData}
            className="sunShine"
            id="sunShine"
          />
        </div>
        <div className="container maincontainer">
          {/* <!-- Navbar Start --> */}
          <nav className="navbar  navbar-expand-lg navbar-dark">
            <div className="container maincontainer">
              {logedIn && (
                <Link
                  className="navbar-brand"
                  to={
                    logedIn && role === "Traveler" ? "/SelectMarshall" : "/home"
                  }
                >
                  <img
                    src={travferrlogo}
                    alt=""
                    height="130"
                    className="travfer-main-start-logo"
                    // onClick={handleExplore}
                  />
                </Link>
              )}
              {!logedIn && (
                <Link className="navbar-brand" to="#">
                  <img
                    src={travferrlogo}
                    alt=""
                    height="130"
                    className="travfer-main-start-logo"
                    // onClick={handleExplore}
                  />
                </Link>
              )}
              <div>
                <button
                  className="navbar-toggler"
                  id="disable-btn-2"
                  // className={`navbar-toggler ${ ? "cx-active" : null}`}
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarNav"
                  aria-controls="navbarNav"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
              </div>
              {/* //..............offcanvas...................// */}

              <button
                className="btn "
                id="disablebtn1"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasScrolling"
                aria-controls="offcanvasScrolling"
              >
                <span className="navbar-toggler-icon text-gray"></span>
              </button>

              <div
                className="offcanvas offcanvas-start"
                data-bs-scroll="true"
                data-bs-backdrop="false"
                tabIndex="-1"
                id="offcanvasScrolling"
                aria-labelledby="offcanvasScrollingLabel"
              >
                <div className="offcanvas-header">
                  <h5 className="offcanvas-title" id="offcanvasScrollingLabel">
                    <Link className="navbar-brand" to="/">
                      <img
                        src={travferrlogo}
                        alt=""
                        style={{ height: "100px", marginLeft: "-6px" }}
                        className="travfer-offcanvas-logo"
                      />
                    </Link>
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="offcanvas-body">
                  {/* <p>Try scrolling the rest of the page to see this option in action.</p> */}

                  <ul className="navbar-nav1-canvas  nav mt-1">
                    <li className="nav-item">
                      <Link className="nav-link " to="/Login">
                        Login
                      </Link>
                    </li>
                    <li className="nav-item ">
                      {/* <img src={homesvg} alt="" className="me-4 pt-2" /> */}
                      <NavLink
                        className=" nav-link  "
                        to={
                          logedIn && role === "Traveler"
                            ? "/profile_2"
                            : role === "TravelMarshal"
                            ? "/profile"
                            : "/login"
                        }
                      >
                        Profile
                      </NavLink>
                    </li>
                    {/* <li className="nav-item ">
                      <img src={chattingsvg} alt="" className="me-4 pt-2" />
                      <NavLink className=" nav-link  " to="/AboutUsHome">
                        About Us
                      </NavLink>
                    </li> */}
                    <li className="nav-item ">
                      {/* <img src={chattingsvg} alt="" className="me-4 pt-2" /> */}
                      <NavLink className=" nav-link  " to="ContactUs">
                        Contact Us
                      </NavLink>
                    </li>

                    {/* <li
                      className="nav-item "
                      role="presentation"
                      data-toggle="pill"
                    >
                      <img src={homesvg} alt="" className="me-4 pt-2" />
                      <NavLink
                        className="nav-link "
                        to={
                          role === "TravelMarshall"
                            ? "/Home"
                            : "/SelectMarshall"
                        }
                      >
                        Home
                      </NavLink>

                    </li> */}

                    {/* <li className="nav-item ">
                      <img src={chattingsvg} alt="" className="me-4 pt-2" />
                      <NavLink className=" nav-link  " to="/Chats">
                        Chat{" "}
                      </NavLink>
                    </li> */}
                    {/* <li className="nav-item ">
                      <img src={menu} alt="" className="me-4 pt-2" />
                      <NavLink className=" nav-link " to="/Subscription">
                        Wall
                      </NavLink>
                    </li> */}
                    {/* <li className="nav-item  ">
                      <img src={siren} alt="" className="me-4 pt-2" />
                      <NavLink className=" nav-link " to="/Emergency">
                        Emergency{" "}
                      </NavLink>
                    </li> */}
                  </ul>
                </div>
              </div>

              <div
                className="collapse navbar-collapse navbar-bakground"
                id="navbarNav"
              >
                <div className="mx-auto"></div>
                {logedIn ? (
                  <ul className="navbar-nav">
                    <li className="nav-item">
                      <Link className="nav-link " to="/Login">
                        Login
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link "
                        to={
                          role === "TravelMarshall" ? "/Profile" : "/Profile_2"
                        }
                      >
                        Profile
                      </Link>
                    </li>
                    {/* <li className="nav-item">
                      <Link className="nav-link " to="/Aboutus">
                        About Us{" "}
                      </Link>
                    </li> */}
                    <li className="nav-item">
                      <Link className="nav-link " to="/ContactUs">
                        Contact Us
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link " to="/Emergency">
                        Emergency{" "}
                      </Link>
                    </li>
                  </ul>
                ) : (
                  <ul className="navbar-nav">
                    <li className="nav-item">
                      <Link className="nav-link " to="/Login">
                        Login
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link "
                        to={
                          logedIn && role === "Traveler"
                            ? "/profile_2"
                            : role === "TravelMarshal"
                            ? "/profile"
                            : "/login"
                        }
                        // onClick={handleExplore}
                      >
                        Profile
                      </Link>
                    </li>
                    {/* <li className="nav-item">
                      <Link className="nav-link " to="/AboutUsHome">
                        About Us
                      </Link>
                    </li> */}
                    <li className="nav-item">
                      <Link className="nav-link " to="/ContactUs">
                        Contact Us
                      </Link>
                    </li>
                  </ul>
                )}
              </div>
            </div>
          </nav>
          {/* <!-- Navbar End --> */}

          <div className="content-text-web">
            <div>
              <p className="text-travfer">
                Lets Enjoy <br />
                your trip with <br />
                Travfer
              </p>
            </div>
            <div>
              <p className="text-travfer2">
                Thinking of taking a break from every day's busy life? Planning
                to go out of the country with your loved ones to have some fun
                and quality time in a cost-effective way?
              </p>
            </div>

            <div>
              <a href="https://travferr.com/home/">
                <button className="explore-btn-travfer-web">Explore Now</button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetStarted;
