const BASE_URL = "https://app.travferr.com/api/";

const ApiConfig = {
  BASE_URL,
  /* WebMarshall API url Start*/
  TREVFERR_LOGIN_API: BASE_URL + "login/",
  TREVFERR_FORGATPASSWORD: BASE_URL + "password_reset/",
  TREVFERR_PROFILE: BASE_URL + "user_profile/",
  UPDATE_PROFILE: BASE_URL + "user_profile/",
  SET_PASSWORD: BASE_URL + "set_password/",
  SUBSCRIPTION_LIST: BASE_URL + "list_subscription/",
  MARSHALL_LIST: BASE_URL + "travel_marshells/?location=",
  CHAT_LIST_HISTORY: BASE_URL + "chatlisthistory/",
  ACCEPTED_LIST: BASE_URL + "Accepted_requests_marshall",
  PRIVACY_POLICY: BASE_URL + "privacy_policy/",
  ABOUT_US: BASE_URL + "about_us/",
  TERMS_CONDITION: BASE_URL + "terms_and_condition/",
  TRANSACTION_HISTORY: BASE_URL + "transaction_history/",
  SUBSCRIPTION_HISTORY: BASE_URL + "subscription_history/",
  FAQ_LIST: BASE_URL + "faq/",
  DOCUMENT_LIST: BASE_URL + "documents_WT/",
  ADD_DOCUMENT: BASE_URL + "documents_WT/",
  EMERGENCY_CONTACT: BASE_URL + "emergency_contact/?area=",
  CITY_LIST: BASE_URL + "city_data/?city=",
  TRANSACTION_HISTORY: BASE_URL + "transaction_history/",
  REQ_LIST: BASE_URL + "request_list/",
  GET_INTEREST: BASE_URL + "interests_list/",
  POST_SOS: BASE_URL + "sos/",
  DELETE_DOC: BASE_URL + "documents_WT/?id=",
  CREATE_PLAN: BASE_URL + "create_plan/",
  VERIFY_PAYMENT: BASE_URL + "verify_plan_list/",
  GET_CHAT: BASE_URL + "chat-room-history/",
  GET_CITYLIST: BASE_URL + "city_and_area/",
  BLOCK_USER: BASE_URL + "block_user/",
  SEND_PUSH: BASE_URL + "send_notification/",
  CHANGE_CHAT_STATUS: BASE_URL + "chat_status/",
  SAVE_CHAT_FILE: BASE_URL + "save-chat-files/",
  FEEDBACK: BASE_URL + "user_feedback_WT/",
  SOCIAL_LOGIN: BASE_URL + "web_login/",
  ADD_BANK_DETAILS: BASE_URL + "user_bank_details/",
  DELETE_BANK_INFO: BASE_URL + "user_bank_details/?pk=",
  BANK_DETAILS_LIST: BASE_URL + "user_bank_details/",
  CHANGE_TIME: BASE_URL + "update_date_time/",
  AUTH_VERIFY: BASE_URL + "verify_token/",
  CONTACT_US: BASE_URL + "contact_us_web/",
  REPORT_DROPDOWN: BASE_URL + "report_dropdown/",
  FEEDBACK_DROPDOWN: BASE_URL + "feedback_dropdown/",
  REPORT_TM: BASE_URL + "report_tm/",
  AUTO_TRANSACTION: BASE_URL + "auto_payment/",
  /* Transporter API url End*/
};
export default ApiConfig;
