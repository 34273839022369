import React, { useEffect, useState } from "react";
import Header from "../../../sharedComponents/Header";
import Footer from "../../../sharedComponents/Footer";
import { getWithAuthCallWithtext } from "../../../api/ApiServices";
import ApiConfig from "../../../api/ApiConfig";

const Aboutus = () => {
  const [aboutUs, setAboutUs] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAbout();
    return () => {};
  }, []);

  function getAbout() {
    setLoading(true);

    getWithAuthCallWithtext(ApiConfig.ABOUT_US)
      .then((data) => {
        setAboutUs(data.text);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <>
      <Header />

      <main className="home-getStarted">
        <div className="">
          <div className="about_banner"></div>
          {/* <img src={emergency} alt="" /> */}
        </div>
        <div className="container mt-2" style={{ marginBottom: "200px" }}>
          <h5 htmlFor="" className="Home_req ">
            About Us
          </h5>

          <div className="row ">
            {loading ? (
              <div
                className="spinner-border mx-auto mt-2"
                style={{
                  color: "#3D313A",
                  width: "70px",
                  height: "70px",
                }}
              />
            ) : (
              <p dangerouslySetInnerHTML={{ __html: aboutUs }}></p>
            )}
          </div>
        </div>
        <Footer />
      </main>
    </>
  );
};

export default Aboutus;
