import React, { useContext, useEffect, useState } from "react";
import Footer from "../../../sharedComponents/Footer";
import travferrlogo from "../../../assets/images/travferrlogo.png";
import Header from "../../../sharedComponents/Header";
import {
  postWithAuthCall,
  simpleGetCallWithErrorResponse,
} from "../../../api/ApiServices";
import { AppContext } from "../../../context/user/AppContext";
import ApiConfig from "../../../api/ApiConfig";
// Import the package
import useRazorpay from "react-razorpay";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";

const Subscription = () => {
  const [subscriptionList, setSubscriptionList] = useState([{}]);
  const { profileDetails, role } = useContext(AppContext);
  const [selectedPlan, setSelectedPlan] = useState("");
  const [loading, setLoading] = useState(false);
  const [appNavigate, setAppNavigate] = useState("");
  const navigate = useNavigate();
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  const Razorpay = useRazorpay();
  let timer1 = setTimeout(() => setLoading(true), 1000);
  useEffect(() => {
    return () => {};
  }, [selectedPlan]);

  useEffect(() => {
    getSubscriptionList();
    return () => {};
  }, []);

  const setApiDataTosub = async (planId) => {
    if (loading) {
      setSelectedPlan(planId);
    } else return;
    setLoading(true);
    let requstBody = JSON.stringify({ plan_id: planId });

    // await fetch(ApiConfig.CREATE_PLAN, {
    //   method: "POST",
    //   mode: "cors",
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //     Authorization: "Token " + "9c2872f0796d5c3c11f6eafde7fe971156ff1e64",
    //   },
    //   body: requstBody,
    // })
    //   .then((t) => t.json())
    postWithAuthCall(ApiConfig.CREATE_PLAN, requstBody)
      .then((data) => {
        // console.log(data);
        displayRazorpay(data, planId);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);

        console.log(error);
      });
  };

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const refreshPage = () => {
    window.location.reload();
  };

  async function displayRazorpay(data, planId) {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load.");
      return;
    }

    var options = {
      key: data.key_id,
      subscription_id: data.subscription_id,

      name: data.name,
      description: "Monthly Test Plan",
      image: travferrlogo,
      handler: function (response) {
        // alert(response.razorpay_payment_id);
        // alert(response.razorpay_subscription_id);
        // alert(response.razorpay_signature);
        verifyPayment(response, planId);
        // console.log("paymentId", response);
      },

      theme: {
        color: "#F37254",
      },
    };

    var paymentObject = new Razorpay(options);
    paymentObject.open();
  }

  const verifyPayment = (res, planId) => {

    setLoading(true);

    let requstBody = JSON.stringify({
      razorpay_subscription_id: res.razorpay_subscription_id,
      razorpay_payment_id: res.razorpay_payment_id,
      razorpay_signature: res.razorpay_signature,
      plan_id: planId,
    });
    // console.log(requstBody);
    postWithAuthCall(ApiConfig.VERIFY_PAYMENT, requstBody)
      .then((data) => {
        // console.log(data);
        swal(data.details).then(() => {
          if (userAgent.includes("Android") || userAgent.includes("iPhone")) {
            window.location.replace(
              "https://worldtraveltravferr.page.link/jTpt"
            );
          }
          if (role === "Traveler") {
            navigate("/SelectMarshall");
          } else if (role === "TravelMarshall") {
            navigate("/SelectMarshall");
          }
        });
        getSubscriptionList();

        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getSubscriptionList = () => {
    setLoading(true);

    simpleGetCallWithErrorResponse(ApiConfig.SUBSCRIPTION_LIST).then((res) => {
      // console.log(res);
      setSubscriptionList(res.json.data);
      setLoading(false);
    });
  };

  return (
    <>
      <Header />

      <main className="home-getStarted" style={{ overflowX: "hidden" }}>
        <div className="">
          <div className="about_banner"></div>
          {/* <img src={emergency} alt="" /> */}
        </div>
        <div className="container mt-2" style={{ marginBottom: "200px" }}>
          <form className="row ">
            <div className="">
              <h5 htmlFor="" className="Home_req mt-4 mb-3">
                Choose Your Plan
              </h5>
            </div>
          </form>
          <div className="container">
            <div className="row">
              {subscriptionList &&
                subscriptionList.length &&
                subscriptionList.map((list, index) => {
                  return (
                    <div className="col-lg-4 mb-3">
                      <div className="justify-content-center">
                        <div
                          className={
                            list.recuring === "daily"
                              ? "card  sub_card1 "
                              : list.recuring === "monthly" || "weekly"
                              ? "card  sub_card2"
                              : list.recuring === "yearly"
                              ? "card  sub_card3"
                              : ""
                          }
                          key={"list" + index}
                        >
                          <div
                            className="col-lg-12 text-end  pe-4  position-relative"
                            style={{ marginTop: "25px" }}
                          >
                            {" "}
                            {profileDetails.is_subscription == false && (
                              <div onClick={() => setApiDataTosub(list.id)}>
                                <input
                                  id={list.id}
                                  className="radio-custom"
                                  name="radio-group"
                                  type="radio"
                                  // checked
                                />
                                <label
                                  for={list.id}
                                  className="radio-custom-label"
                                ></label>
                              </div>
                            )}
                          </div>

                          <div className="">
                            <div className="justify-content-center p-4">
                              <div
                                htmlFor=""
                                className="monthly_body  text-center "
                              >
                                <label
                                  htmlFor=""
                                  className="month_text p-3"
                                  style={{
                                    fontSize: "20px",
                                    color: "#EB1D36",
                                    backgroundColor: "rgba(241,84,18, 0.2)",
                                  }}
                                >
                                  {list.plan_name}
                                </label>
                              </div>
                            </div>
                            <div className="justify-content-center p-4">
                              <div
                                htmlFor=""
                                className="monthly_body  text-center "
                              >
                                <label htmlFor="" className="month_text p-3">
                                  {list.recuring}
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="card-body p-0">
                            <div className="row justify-content-center pb-2 mt-2">
                              <label className="text-center sub_para pb-4">
                                {list.plan_description}
                              </label>
                              <label
                                htmlFor=""
                                className=" text-center plan pt-3"
                              >
                                ₹ {list.pricing}
                              </label>
                              {selectedPlan !== list.id ? (
                                <button
                                  className={
                                    profileDetails.is_subscription == false
                                      ? "subscribe-btn ms-2 mt-2  mb-2 "
                                      : "subscribe-btn ms-2 mt-2 mb-2 disabled btn"
                                  }
                                  onClick={() => setApiDataTosub(list.id)}
                                >
                                  {
                                    <span className="subscribe_text p-2 ">
                                      {profileDetails.is_subscription == true &&
                                      profileDetails.plan === list.id
                                        ? " Subscribed "
                                        : "Subscribe Now"}
                                    </span>
                                  }
                                </button>
                              ) : (
                                <>
                                  {loading ? (
                                    <div className="loading-spinner ms-2  mb-2" />
                                  ) : (
                                    <button
                                      className={
                                        profileDetails.is_subscription ==
                                          true &&
                                        profileDetails.plan === list.id
                                          ? "subscribe-btn ms-2 mt-2 mb-2 "
                                          : "subscribe-btn ms-2 mt-2 mb-2 btn disabled"
                                      }
                                      onClick={() => setApiDataTosub(list.id)}
                                    >
                                      <span className="subscribe_text p-2">
                                        {profileDetails.is_subscription ==
                                          true &&
                                        profileDetails.plan === list.id
                                          ? "Subscribed"
                                          : "Subscribe Now"}
                                      </span>
                                    </button>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          {/* )} */}
        </div>
        <Footer />
      </main>
    </>
  );
};

export default Subscription;
