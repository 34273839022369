import React, { useContext, useState } from "react";
import Footer from "../../../sharedComponents/Footer";
import { Modal } from "react-bootstrap";
import location from "../../../assets/images/Emergency/location.svg";
import chat from "../../../assets/images/Home/chat.svg";
import marshall from "../../../assets/images/model/marshall.svg";
import Header from "../../../sharedComponents/Header";
import { Link, useNavigate } from "react-router-dom";
import { AppContext } from "../../../context/user/AppContext";
import ApiConfig from "../../../api/ApiConfig";
import StarRatingComponent from "react-star-rating-component";
import swal from "sweetalert";

import AutoComplete from "../../../sharedComponents/AutoComplete";
import {
  postWithAuthCall,
  simpleGetCallWithErrorResponse,
} from "../../../api/ApiServices";
const SelectMarshall = ({ setLoggedIn }) => {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const { customerData } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState("");
  const [remainingCount, setRemainingCount] = useState("");
  const [currentList, setcurrentList] = useState([{}]);
  const [searchData, setSearchData] = useState(null);
  const [message, setMessage] = useState("");
  const [subscribe, setSubscribe] = useState(false);

  const [address, setAddress] = useState({
    address: "",
    latitude: "",
    longitude: "",
  });
  const [connect, setConnect] = useState(false);
  const search = () => {
    setLoading(true);

    simpleGetCallWithErrorResponse(
      ApiConfig.MARSHALL_LIST + customerData.location,
      JSON.stringify({
        ...customerData,
        ...address,
      })
    ).then((res) => {
      // console.log(res);
      setSearchData(res.json.list);
      setMessage(res.json.message);
      setSubscribe(res.json.is_subscription);
      setTotalCount(res.json.plan_tm_count);
      setRemainingCount(res.json.guide_count);
      setLoading(false);
    });
  };

  const sendContactConnect = (user_id) => {
    setConnect(false);
    let requstBody = JSON.stringify({
      chat_status: "Connect",
      tm_id: user_id,
      location: customerData.location,
    });

    setLoading(true);
    postWithAuthCall(ApiConfig.CHANGE_CHAT_STATUS, requstBody)
      .then((data) => {
        // console.log(data);
        if (totalCount === remainingCount) {
          swal(data.message);
        }
        swal(data.message).then(() => {
          setLoading(false);
          setShow(show);
        });
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const Subscription = () => {
    if (subscribe === true && remainingCount != totalCount) {
      navigate("/chats");
    } else if (subscribe === false) {
      navigate("/Subscription");
    } else if (remainingCount === totalCount) {
      navigate("/SelectMarshall");
    }
  };

  const handleClick = () => {
    setShow(!show);
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    // send state to server with e.g. `window.fetch`

    setTimeout(() => {
      search(e.target.value);
    }, 1000);
  };

  return (
    <>
      <Header setLoggedIn={setLoggedIn} />
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-md"
        responsive-md
        id="about"
        role="about"
      >
        <Modal.Header closeButton></Modal.Header>
        <div className="row">
          <div className="justify-content-between d-flex">
            <p className="text-left marshall_head">About Travel Marshal</p>
          </div>
          <div className="col-lg-6 pt-3">
            <img src={marshall} alt="" className="w-100 h-100" />
          </div>
          <div className="col-lg-6 ">
            <div>
              <label className="marshall_usernam text-left ">
                {currentList.unique_id}
                <br />{" "}
              </label>
            </div>
            <p className="text-left popup-heading mt-4">Interests</p>

            <div className="row mt-3">
              <div className="d-flex flex-wrap ">
                {currentList.interest &&
                  currentList.interest.map((item, index) => {
                    return (
                      <img
                        src={item.category_image}
                        alt=""
                        className="marshall_img"
                      />
                    );
                  })}
              </div>
            </div>
          </div>
          <label htmlFor="" className="marshall_bio mt-3">
            Bio
          </label>
          <p className="bio_details">{currentList.bio}</p>
        </div>
      </Modal>

      <main className="home-getStarted">
        <div className="">
          <div className="home_Banner"></div>
          {/* <img src={home_Banner} alt="" /> */}
        </div>
        <div className="container mt-2" style={{ marginBottom: "200px" }}>
          <form className="row" onSubmit={onFormSubmit}>
            <div className="TranHistory justify-content-between">
              <div className="col-lg-7 ">
                <h5 htmlFor="" className="connection mt-4">
                  Select your Marshal
                </h5>
              </div>

              <div className=" col-lg-2 form-group  location_fileld mt-4 ">
                <AutoComplete />
                <button
                  type="submit"
                  style={{
                    border: "none",
                    backgroundColor: "transparent",
                    marginTop: "-20px",
                  }}
                >
                  <img src={location} alt="" className="location_icon" />
                  <i className="bi bi-search search_icon" />
                </button>
              </div>
            </div>
          </form>
          {/* <!--card's --> */}

          <>
            <div>
              <div className="row ">
                {searchData ? (
                  searchData.length &&
                  searchData.map((list, index) => {
                    return (
                      <>
                        <div className="col-lg-6  mt-4" key={"list" + index}>
                          <div className=" wt-card">
                            <div
                              className=""
                              data-toggle="sos"
                              data-target="#sos"
                              id="about"
                            >
                              <div className="row ">
                                <div className="d-flex ">
                                  <div
                                    className="col-lg-2  col-md-2 "
                                    onClick={() =>
                                      handleClick(setcurrentList(list))
                                    }
                                    state={list.unique_id}
                                  >
                                    <img
                                      className="profile"
                                      src={list.avatar}
                                      alt=""
                                    />
                                  </div>

                                  <div className="col-lg-10 col-md-10 ">
                                    <div className="d-flex justify-content-between">
                                      <div className="col-lg-4 col-md-4 col-sm-4 responsivepro">
                                        <div className="heading">
                                          <h5 className="">{list.unique_id}</h5>
                                        </div>
                                      </div>

                                      <div
                                        className="rating"
                                        style={{ marginTop: "-5px" }}
                                      >
                                        <StarRatingComponent
                                          name="rate2"
                                          editing={false}
                                          starCount={5}
                                          value={list.rating}
                                        />
                                        <span>{list.rating}</span>
                                      </div>
                                    </div>

                                    <div className="d-flex">
                                      <div className="col-lg-9 col-md-9">
                                        <div className=" chips-content">
                                          {list.interest &&
                                            list.interest.map((chip, index) => {
                                              return (
                                                <div
                                                  className="chips mt-2"
                                                  key={"chip" + index}
                                                >
                                                  <p>{chip.name_of_category}</p>
                                                </div>
                                              );
                                            })}
                                        </div>
                                      </div>
                                      <div
                                        className="col-lg-3 col-md-3 col-sm-2"
                                        style={{ float: "right" }}
                                        onClick={Subscription}
                                      >
                                        <button
                                          // className="btn chatm"
                                          className="connect-chat-btn1"
                                          onClick={() => {
                                            sendContactConnect(list.user_id);
                                          }}
                                        >
                                          <img
                                            src={chat}
                                            alt=""
                                            className="img-conncet-icon"
                                          />
                                          connect
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })
                ) : (
                  <>
                    <h5 className="text-danger text-center mt-4">
                      Please Select Location & search for TM
                    </h5>
                    {loading ? (
                      <div
                        className="spinner-border mx-auto mt-2"
                        style={{
                          color: "#3D313A",
                          width: "70px",
                          height: "70px",
                        }}
                      />
                    ) : (
                      <h3 className="text-danger text-center mt-4">
                        {message}
                      </h3>
                    )}
                  </>
                )}
              </div>

              {/* -----Right section----- */}
            </div>
          </>
        </div>
        <Footer />
      </main>
    </>
  );
};

export default SelectMarshall;
