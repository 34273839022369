import {SET_LOCATION, SET_ARRAYLOCATION, SET_RECENTSEARCH} from '../types';
export const updateFirstName = location => dispatch => {
  try {
    dispatch({
      type: SET_LOCATION,
      payload: location,
    });
  } catch (error) {
    console.log('Error', error);
  }
};
export const updateArray = locationArr => dispatch => {
  try {
    dispatch({
      type: SET_ARRAYLOCATION,
      payload: locationArr,
    });
  } catch (error) {
    console.log('Error', error);
  }
};
export const recentSearch = search => dispatch => {

  try {
    dispatch({
      type: SET_RECENTSEARCH,
      payload: search,
    });
  } catch (error) {
    console.log('Error', error);
  }
};
