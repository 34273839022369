import React, { useContext, useEffect, useState } from "react";
import Header from "../../../sharedComponents/Header";
import Footer from "../../../sharedComponents/Footer";
import ApiConfig from "../../../api/ApiConfig";
import { simpleGetCallWithErrorResponse } from "../../../api/ApiServices";
import { AppContext } from "../../../context/user/AppContext";

const FAQ = () => {
  const [faqs, setFaqs] = useState("");
  const [loading, setLoading] = useState(false);
  const { customerData } = useContext(AppContext);
  const [role] = useState(localStorage.role);
  useEffect(() => {
    getFaq();
    return () => {};
  }, []);

  const getFaq = () => {
    setLoading(true);

    simpleGetCallWithErrorResponse(
      ApiConfig.FAQ_LIST,
      JSON.stringify({ ...customerData })
    ).then((res) => {
      // console.log(res);
      setLoading(false);
      setFaqs(res.json.FAQ);
    });
  };
  return (
    <>
      <Header />

      <main className="home-getStarted">
        <div className="">
          <div className="about_banner"></div>
        </div>
        <div className="container mt-2" style={{ marginBottom: "200px" }}>
          <h5 htmlFor="" className="Home_req">
            F.A.Q
          </h5>

          <div className="row">
            {loading ? (
              <div
                className="spinner-border mx-auto mt-2"
                style={{
                  color: "#3D313A",
                  width: "70px",
                  height: "70px",
                }}
              />
            ) : (
              <div className="col-lg-12">
                {faqs &&
                  faqs.length &&
                  faqs.map((faq, index) => {
                    return (
                      <div
                        className="accordion mt-2"
                        id="accordionPanelsStayOpenExample"
                      >
                        <div
                          className="accordion-item mt-3"
                          key={"faq" + index}
                        >
                          <h1
                            className="accordion-header"
                            id="panelsStayOpen-headingOne"
                          >
                            <button
                              className="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#panelsStayOpen-collapseOne"
                              aria-expanded="true"
                              aria-controls="panelsStayOpen-collapseOne"
                            >
                              {faq.faq_question}
                            </button>
                          </h1>
                          <div
                            id="panelsStayOpen-collapseOne"
                            className="accordion-collapse collapse show"
                            aria-labelledby="panelsStayOpen-headingOne"
                          >
                            <div className="accordion-body">
                              {faq.faq_answer}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
        </div>

        <Footer />
      </main>
    </>
  );
};

export default FAQ;
