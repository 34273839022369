import React, { useContext, useEffect, useState } from "react";
import Header from "../../../sharedComponents/Header";
import Footer from "../../../sharedComponents/Footer";
import { AppContext } from "../../../context/user/AppContext";
import ApiConfig from "../../../api/ApiConfig";
import {
  simpleGetCallWithErrorResponse,
  putMultipartWithAuthCallWithErrorResponse,
} from "../../../api/ApiServices";
import swal from "sweetalert";

const Profile = ({ setLoggedIn }) => {
  const [passport, setPassport] = useState({
    document_name: "",
    document_image: "",
    id_number: "",
    id: "",
  });
  const [adhar, setAdhar] = useState({
    document_name: "",
    document_image: "",
    id_number: "",
    id: "",
  });
  const [pan, setPan] = useState({
    document_name: "",
    document_image: "",
    id_number: "",
    id: "",
  });
  const { customerData, profileDetails, setProfileDetails, userDetails } =
    useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [interestData, setInterestData] = useState({});

  useEffect(() => {
    getProfileDetails();
  }, []);
  useEffect(() => {
    getUserInterest();
  }, [loading]);
  useEffect(() => {}, [profileDetails, passport, pan, adhar]);

  const getProfileDetails = () => {
    setLoading(true);

    simpleGetCallWithErrorResponse(
      ApiConfig.TREVFERR_PROFILE
      // JSON.stringify({ ...customerData, user_id: profileDetails.unique_id })
    ).then((res) => {
      let user_profile = res.json.user_profile;
      let passport =
        user_profile.documents &&
        user_profile.documents.filter(
          (doc) => doc.document_name === "passport"
        )[0];
      if (passport) {
        setPassport(passport);
      }
      let pan =
        user_profile.documents &&
        user_profile.documents.filter(
          (doc) => doc.document_name === "pan card"
        )[0];
      if (pan) {
        setPan(pan);
      }
      let adhar =
        user_profile.documents &&
        user_profile.documents.filter(
          (doc) => doc.document_name === "aadhar card"
        )[0];
      if (adhar) {
        setAdhar(adhar);
      }
      setProfileDetails({ ...user_profile });

      setLoading(false);
    });
  };
  const getUserInterest = () => {
    simpleGetCallWithErrorResponse(ApiConfig.GET_INTEREST).then((res) => {
      let int = res.json.map((inte) => {
        if (
          profileDetails.interest.filter((int) => int.id === inte.id).length > 0
        ) {
          return { ...inte, checked: true };
        } else {
          return { ...inte, checked: false };
        }
      });

      setInterestData(int);
    });
  };

  const UpdateProfile = () => {
    setLoading(true);

    let detail = JSON.stringify({
      email: profileDetails.email,
      phone_number: profileDetails.phone_number,
      interest: interestData
        .filter((int) => {
          if (int.checked === true) return { id: int.id };
        })
        .map((int) => {
          return { id: int.id };
        }),
      // emergency_contact_no: profileDetails.emergency_contact_no,
      bio: profileDetails.bio,
      avatar_id:
        profileDetails.avatar_id == "" || null
          ? null
          : profileDetails.avatar_id,
      location: profileDetails.location,
    });
    const formData = new FormData();
    formData.append("details", detail);
    formData.append("documents", document);

    // // formdata.append("auth_token", customerData.auth_token);
    // formdata.append("avatar_id", customerData.id);
    // formdata.append("unique_id", customerData.unique_id);
    // formdata.append("full_name", profileDetails.full_name);
    // formdata.append("email", profileDetails.email);
    // formdata.append("phone_number", profileDetails.phone_number);
    // formdata.append("bio", profileDetails.bio);

    formData.append("aadhar_card", adhar.document_image);
    formData.append("aadhar_number", adhar.id_number);
    formData.append("pan_card", pan.document_image);
    formData.append("pan_number", pan.id_number);
    formData.append("passport", passport.document_image);
    formData.append("passport_number", passport.id_number);

    putMultipartWithAuthCallWithErrorResponse(
      ApiConfig.UPDATE_PROFILE,
      formData
    )
      .then((res) => {
        // console.log(res);
        if (res.json.result) {
          setLoading(false);
          swal("Profile Update Successfully");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Header setLoggedIn={setLoggedIn} />

      <main className="home-getStarted">
        <div className="">
          <div className="about_banner"></div>
          {/* <img src={emergency} alt="" /> */}
        </div>
        <div className="container mt-2" style={{ marginBottom: "50px" }}>
          <h5 htmlFor="" className="Home_req">
            {customerData.unique_id}
          </h5>
          {loading ? (
            <div
              className="spinner-border mx-auto mt-2"
              style={{
                color: "#3D313A",
                width: "70px",
                height: "70px",
              }}
            />
          ) : (
            <div className="container Profile-card">
              <div className="row">
                <div className="col-lg-12 border-bottom pt-3 pb-3">
                  <p className="profile-heading">
                    {userDetails && userDetails.full_name}
                  </p>
                </div>

                <div className="profile-body">
                  <div className="profile-input ">
                    <div className="  profile-group">
                      <div className=" emailIgroup ">
                        <label className="bank_text" for="inputEmail4">
                          Email ID
                        </label>
                        <input
                          type="email"
                          className="form-control email-input mt-2"
                          id="inputEmail4"
                          placeholder="Email"
                          value={profileDetails.email}
                          onChange={(e) => {
                            // setErrMsg({ ...errMsg, email: "" });
                            setProfileDetails({
                              ...profileDetails,
                              email: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <br />
                      <br />
                      {/* <div className=" emailIgroup ">
                      <label className="bank_text" for="inputEmail4">
                        Emergency contact Number
                      </label>
                      <input
                        type="email"
                        className="form-control email-input mt-2"
                        id="inputEmail4"
                        placeholder="Phone Number"
                        value={profileDetails.e}
                        onChange={(e) => {
                          // setErrMsg({ ...errMsg, phone_number: "" });
                          setProfileDetails({
                            ...profileDetails,
                            phone_number: e.target.value,
                          });
                        }}
                      />
                    </div> */}
                      <div className=" emailIgroup ">
                        <label className="bank_text" for="inputEmail4">
                          Phone Number
                        </label>
                        <input
                          type="email"
                          className="form-control email-input mt-2"
                          id="inputEmail4"
                          placeholder="Phone Number"
                          value={profileDetails.phone_number}
                          onChange={(e) => {
                            // setErrMsg({ ...errMsg, phone_number: "" });
                            setProfileDetails({
                              ...profileDetails,
                              phone_number: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <p className="bank_text mt-4">Describe yourself / Bio</p>
                    <textarea
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      rows="4"
                      value={profileDetails.bio}
                      onChange={(e) => {
                        // setErrMsg({ ...errMsg, phone_number: "" });
                        setProfileDetails({
                          ...profileDetails,
                          bio: e.target.value,
                        });
                      }}
                      placeholder="Hi, I’m a travel addict originally from sleepy Suffolk in the UK. I quit my job in Dec 2012 and have been travelling slowly, independently and on a budget around India, Southeast Asia and Australia ever since."
                    ></textarea>
                  </div>
                  <div className="row mt-3">
                    <div className="col-lg-6">
                      <p className="bank_text ">Interests</p>
                    </div>
                    <div className="col-lg-6 text-end ">
                      <p className="bank_text">
                        {profileDetails.interest &&
                          profileDetails.interest.length}
                        /14
                      </p>
                    </div>
                  </div>

                  <div className="interest-content col-lg-12 col-xs-6 ">
                    {interestData &&
                      interestData.length &&
                      interestData.map((data, index) => {
                        return (
                          <div
                            className=" col-lg-2 interest"
                            key={"data" + index}
                          >
                            <div className="position-relative">
                              <div className="bank-round-checkbox1">
                                <input
                                  type="checkbox"
                                  id={"checkbox" + index}
                                  checked={data.checked}
                                  onChange={(e) => {
                                    let int = interestData.map((int) => {
                                      if (data.id === int.id)
                                        return {
                                          ...int,
                                          checked: e.target.checked,
                                        };
                                      else return int;
                                    });
                                    setInterestData(int);
                                  }}
                                />
                                <label htmlFor={"checkbox" + index}></label>
                              </div>
                              <p
                                className="category_name"
                                style={{
                                  position: "absolute",
                                  top: "30px",
                                  left: "10px",
                                  fontSize: "13px",

                                  color: "#FFF",
                                }}
                              >
                                {data.name_of_category}
                              </p>
                            </div>
                            <img
                              src={data.category_image}
                              alt=""
                              className="interest-img"
                              style={{
                                width: "196px",
                                height: "61px",
                                borderRadius: "12px",
                              }}
                            />
                          </div>
                        );
                      })}
                  </div>

                  <div className=" mt-4">
                    {/* <div className="profile-input">
                      <div className=" profile-group">
                        <div className=" emailIgroup">
                          <label className="bank_text" for="inputEmail4">
                            Aadhar Card
                          </label>
                          <input
                            type="file"
                            className="form-control email-input mt-1"
                            id="file"
                            onChange={(e) => {
                              setAdhar({
                                ...adhar,
                                document_image: e.target.files[0],
                                document_name: "aadhar card",
                              });
                            }}
                          />
                        </div>
                        <div className=" emailIgroup">
                          <label className="bank_text" for="inputEmail4">
                            Adhaar Number
                          </label>
                          <input
                            type="text"
                            className="form-control email-input mt-1"
                            id="inputEmail4"
                            placeholder="Aadhar Number"
                            value={adhar.id_number}
                            onChange={(e) => {
                              setAdhar({
                                ...adhar,
                                id_number: e.target.value,
                                document_name: "aadhar card",
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div> */}
                    {/* <div className="profile-input mt-4">
                      <div className=" profile-group">
                        <div className=" emailIgroup">
                          <label className="bank_text" for="inputEmail4">
                            Passport
                          </label>
                          <div className="position-relative">
                            <input
                              type="file"
                              className="form-control email-input mt-1 "
                              id="file"
                              onChange={(e) => {
                                setPassport({
                                  ...passport,
                                  document_image: e.target.files[0],
                                  document_name: "passport",
                                });
                              }}
                            />

                          </div>
                        </div>
                        <div className=" emailIgroup">
                          <label className="bank_text" for="inputEmail4">
                            Passport Number
                          </label>
                          <div className="">
                            <input
                              type="text"
                              className="form-control email-input mt-1 "
                              id="inputEmail4"
                              placeholder="Passport Number"
                              value={passport.id_number}
                              onChange={(e) => {
                                setPassport({
                                  ...passport,
                                  id_number: e.target.value,
                                  document_name: "passport",
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div> */}
                    {/* <div className="profile-input mt-4">
                      <div className="profile-group">
                        <div className=" emailIgroup ">
                          <label for="AddFile" className="bank_text">
                            PAN Card
                          </label>
                          <div className="position-relative">
                            <input
                              type="file"
                              className="form-control email-input mt-1 "
                              id="file"
                              onChange={(e) => {
                                setPan({
                                  ...pan,
                                  document_image: e.target.files[0],
                                  document_name: "pan card",
                                });
                              }}
                            />
                           
                          </div>
                        </div>
                        <div className=" emailIgroup">
                          <label className="bank_text" for="inputEmail4">
                            Permanent Account Number
                          </label>
                          <input
                            type="text"
                            className="form-control email-input mt-1"
                            id="inputEmail4"
                            placeholder="Permanent Account Number"
                            value={pan.id_number}
                            onChange={(e) => {
                              setPan({
                                ...pan,
                                id_number: e.target.value,
                                document_name: "pan card",
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div> */}
                    <div className="">
                      <div className=" col-lg-12">
                        <div className="text-end  button_update">
                          <button className="update-btn text-center " id="">
                            <span
                              className="text-center"
                              onClick={UpdateProfile}
                            >
                              {loading ? (
                                <div
                                  className="spinner-border"
                                  style={{
                                    textAlign: "right",
                                    color: "#FFFBF3",
                                  }}
                                />
                              ) : (
                                "Update Profile"
                              )}
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <Footer />
      </main>
    </>
  );
};

export default Profile;
