import React, { useEffect, useState } from "react";
import login_banner from "../assets/images/login_banner.png";
import email3d from "../assets/images/email3d.png";
import email from "../assets/images/email.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { simplePostCall } from "../api/ApiServices";
import ApiConfig from "../api/ApiConfig";
import swal from "sweetalert";

const ForgotPassword = () => {
  const navigate = useNavigate();
  let ForgotData = useLocation().state;
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({ ...ForgotData, email: "" });
  const [errMsg, setErrMsg] = useState({ email: "" });

  const [data, setData] = useState({});

  const resetPassword = (e) => {
    e.preventDefault();
    setLoading(true);

    if (state.email === "") {
      setErrMsg({ ...errMsg, email: "Enter Email ID " });
      return;
    } else {
      simplePostCall(
        ApiConfig.TREVFERR_FORGATPASSWORD,
        JSON.stringify({ ...state })
      )
        .then((res) => {
          // console.log("res", res);

          setData(res);
          if (res.detail === "OTP sent on your email.") {
            navigate("/Verification", { state: { ...state } });
            swal(res.detail);
          } else {
            swal(res.detail);
          }

          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <main className="login-main">
      <section className="login-section">
        <div className="row">
          <div className="col-md-5 col-lg-5">
            <div className="left-section-login">
              <img src={login_banner} />
            </div>
          </div>

          <div className="col-md-7 col-lg-3 m-auto verification-section">
            <div className="text-center">
              <div>
                <img src={email3d} className="mb-1" />
              </div>
              <div>
                <h5 className="show-text-1">Show us it's you!</h5>
                <p className="verify-text-1">
                  {" "}
                  Please verify your email to continue
                </p>
              </div>
            </div>

            <div className="form-group  email_fileld">
              <label className="email-lable-1 mt-4" htmlFor="email">
                Email Address
              </label>

              <input
                type="email"
                className="form-control emailInput mt-2 lg-3"
                id="exampleFormControlInput1"
                placeholder="name@example.com"
                onChange={(e) => {
                  setErrMsg({ ...errMsg, email: "" });
                  setState({ ...state, email: e.target.value });
                }}
              />
              {
                <div className="text-center text-danger">
                  <span> {errMsg.email}</span>
                </div>
              }
              <a href="">
                <img src={email} alt="" className="email_icon" />
              </a>
              <div className="text-center">
                <button
                  className="send-varification-btn mt-5"
                  onClick={resetPassword}
                >
                  {loading ? (
                    <div
                      className="spinner-border"
                      style={{ textAlign: "right", color: "#FFFBF3" }}
                    />
                  ) : (
                    "Send Verification Code"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default ForgotPassword;
