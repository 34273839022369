import React, { useContext, useEffect, useRef, useState } from "react";
import Header from "../../sharedComponents/Header";
import Footer from "../../sharedComponents/Footer";
import user_1 from "../../assets/images/Home/user_1.svg";
import user_3 from "../../assets/images/Chats/user_3.svg";
import options from "../../assets/images/Chats/options.svg";
import online_icon from "../../assets/images/Chats/online_icon.svg";
import disconnect_icon from "../../assets/images/cross-circle.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import disconnect from "../../assets/images/model/disconnect.svg";
import report_icon from "../../assets/images/model/report_icon.svg";
import image from "../../assets/images/Chats/image.svg";
import send from "../../assets/images/Chats/send.svg";
import up_arrow from "../../assets/images/Navbar/up_arrow.svg";
import {
  PostCallWithErrorResponse,
  postMultipartWithAuthCall,
  postWithAuthCall,
  postWithAuthCallWithErrorResponse,
  simpleGetCall,
  simpleGetCallWithErrorResponse,
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import openSocket from "socket.io-client";
import { ThreeDots } from "react-loader-spinner";
import { Rating } from "react-simple-star-rating";
import swal from "sweetalert";
import { AppContext } from "../../context/user/AppContext";
const Chats = () => {
  const [chat_hours, setChat_hours] = useState(null);
  const previousPageData = useLocation().state;
  const [rating, setRating] = useState(false);
  const navigate = useNavigate();
  const { role } = useContext(AppContext);
  const [update, setUpdate] = useState(0);
  const [page, setPage] = useState(1);
  const [messages, setMessages] = useState([]);
  const [show, setShow] = useState(false);
  const [report, setReport] = useState(false);
  const [selectedChat, setSelectedChat] = useState({ ...previousPageData });
  const [reportOptions, setReportOptions] = useState({});
  const [feedbackOptions, setFeedbackOptions] = useState({});
  const [block, setBlock] = useState(false);
  const [disconnet, setdisconnet] = useState(false);
  const [feedback, setFeedback] = useState({
    feedback_given_to: selectedChat && selectedChat.id,
    subject: "",
    message: "",
    rating: 1,
  });
  const [reporting, setReporting] = useState({
    reported_to: selectedChat && selectedChat.id,
    subject: "",
    message: "",
  });
  const [chatList, setChatList] = useState([{}]);
  // console.log("chatList", chatList);Fre
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [activeChat, setActiveChat] = useState({ ...previousPageData });
  const [notconnectedChatList, setNOtConnectedChatList] = useState([]);
  const [noPages, setNoPages] = useState(true);
  const [chatHours, setChatHours] = useState(false);
  const [socket, setSocket] = useState(null);
  let option = {
    timeout: 20000,
    extraHeaders: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "text/html",
    },
    query: {
      UID: localStorage.getItem("id"),
    },
  };

  function detectMouseWheelDirection(e) {
    var delta = null,
      direction = false;
    if (!e) {
      // if the event is not provided, we get it from the window object
      e = window.event;
    }
    if (e && e.wheelDelta) {
      // will work in most cases
      delta = e.wheelDelta / 60;
    } else if (e.detail) {
      // fallback for Firefox
      delta = -e.detail / 2;
    }
    if (delta !== null) {
      direction = delta > 0 ? "up" : "down";
    }

    return direction;
  }
  const feedBackTM = (e) => {
    e.preventDefault();
    setShow(false);
    if (feedback.message == "") {
      swal("Please select subject!");
      return;
    }
    if (feedback.message == "") {
      alert("Please add your message!");
      return;
    }
    let requstBody = JSON.stringify({
      feedback_given_to: selectedChat && selectedChat.id,
      subject: feedback.subject,
      message: feedback.message,
      rating: feedback.rating,
    });
    // console.log(requstBody);
    setLoading(true);
    postWithAuthCall(ApiConfig.FEEDBACK, requstBody)
      .then((data) => {
        setLoading(false);
        swal(data.detail);
        setRating("");
        navigate("/SelectMarshall");
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        swal("Something went wrong please try again!");
      });
  };
  const reportTM = (e) => {
    e.preventDefault();

    let requstBody = JSON.stringify({
      reported_to: selectedChat && selectedChat.id,
      subject: reporting.subject,
      message: reporting.message,
    });
    // console.log(requstBody);
    setLoading(true);
    postWithAuthCall(ApiConfig.REPORT_TM, requstBody)
      .then((data) => {
        setLoading(false);
        swal(data.detail);
        setRating("");
        navigate("/SelectMarshall");
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        swal("Something went wrong please try again!");
      });
  };

  // const scroll = (e) => {
  //   document.body.style.overflow = "hidden";
  //   var scrollDirection = detectMouseWheelDirection(e);
  //   if (scrollDirection == "up" && !loading && noPages >= page) {
  //     setPage((page) => page + 1);
  //   } else if (!loading) {
  //     page > 0 && setPage((page) => page - 1);
  //   }
  // };
  useEffect(() => {
    setSocket(openSocket("https://app.travferr.com:5050/", option));
    let scrollChat = document.getElementById("scrollChat");
    let onmousewheel;
    // if (scrollChat) {
    //   scrollChat.onmouseenter = () => {
    //     document.onmousewheel = function (e) {
    //       scroll(e);
    //     };
    //     onmousewheel = window.addEventListener("DOMMouseScroll", scroll);
    //   };
    //   scrollChat.onmouseleave = () => {
    //     document.body.style.overflow = "";
    //     document.onmousewheel = null;
    //     window.addEventListener("DOMMouseScroll", null);
    //   };
    // }
    getChatList();
    return () => {};
  }, []);

  useEffect(() => {
    if (page <= noPages) if (page > 0) getChatHystory();
    return () => {};
  }, [page]);
  const sendMessage = (data) => {
    if (
      message &&
      message.length > 0 &&
      activeChat.private_chat_room &&
      activeChat.private_chat_room.length > 0
    ) {
      setLoading(true);

      socket &&
        socket.emit("new message", {
          conversation_type: "text",
          files: [],
          message: message,
          receiverId: selectedChat.id,
          room: activeChat.private_chat_room,
          senderID: localStorage.getItem("id"),
          type: "private",
          username: selectedChat.unique_id,
          userpic: selectedChat.avatar,
        });
      sendPushNotification();
      setLoading(false);
    }
  };
  const sendContactDisconect = () => {
    setdisconnet(false);
    let requstBody = JSON.stringify({
      chat_status: "Disconnect",
      tm_id: selectedChat.id,
    });

    setLoading(true);
    postWithAuthCall(ApiConfig.CHANGE_CHAT_STATUS, requstBody)
      .then((data) => {
        setLoading(false);
        swal(data.message).then(() => {
          setRating(!rating);
        });
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const sendPushNotification = () => {
    let requstBody = JSON.stringify({
      sender_id: localStorage.getItem("id"),
      receiver_id: selectedChat.id,
    });

    postWithAuthCall(ApiConfig.SEND_PUSH, requstBody)
      .then((data) => {})
      .catch((error) => console.log("push send error", error));
  };

  const getChatList = () => {
    simpleGetCallWithErrorResponse(ApiConfig.CHAT_LIST_HISTORY).then((res) => {
      setLoading(false);
      let chatData = res.json.list.filter((chat) => {
        if (
          chat.connect_status &&
          chat.connect_status.chat_status === "Connect"
        )
          return chat;
      });
      // console.log("res", res);
      setChat_hours(res.json.chat_hours);
      setUpdate(1);
      setActiveChat(
        chatData &&
          chatData.length &&
          chatData[0].chat &&
          chatData[0].chat.chat_room_details
      );
      setSelectedChat(chatData[0]);
      setChatList(chatData);
      setNOtConnectedChatList(
        res.json.list.filter((chat) => {
          if (
            chat.connect_status &&
            chat.connect_status.chat_status != "Connect"
          )
            return chat;
        })
      );
    });
  };
  const continueChat = async () => {
    let requstBody = JSON.stringify({
      guide_id: selectedChat.id,
    });

    postWithAuthCall(ApiConfig.CHANGE_TIME, requstBody)
      .then((data) => {
        navigate("/Chats");
      })
      .catch((error) => console.log("push send error", error));
  };

  const uploadFile = (data) => {
    let formData = new FormData();
    formData.append("files", data);
    postMultipartWithAuthCall(ApiConfig.SAVE_CHAT_FILE, formData)
      .then((data) => {
        setMessage(data.files[0].path_url);
        // sendMessage()
      })
      .catch((error) => {
        console.log("register error", error);
        alert("Something went wrong please try again!");
        this.setState({ isActivity: false });
      });
  };

  const getChatHystory = () => {
    if (selectedChat && selectedChat.id) {
      let url =
        ApiConfig.GET_CHAT + "?recv_id=" + selectedChat.id + "&page=" + page;

      simpleGetCallWithErrorResponse(url)
        .then((res) => {
          setChat_hours(res.json.chat_hours);
          setNoPages(res.json.num_pages);
          setLoading(false);
          setMessages(res.json.list.reverse());
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };
  socket &&
    socket.on("message created", async (data) => {
      setMessage("");
      setPage(1);
      getChatHystory();
    });

  function diff_hours(dt2, dt1) {
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60 * 60;
    return Math.abs(Math.round(diff));
  }
  useEffect(() => {
    // if (update === 1) {
    if (
      activeChat.private_chat_room &&
      activeChat.private_chat_room.length > 0
    ) {
      if (chat_hours) {
        if (selectedChat.connect_status) {
          let startConversationDate = selectedChat.connect_status.date;
          let result = diff_hours(new Date(), new Date(startConversationDate));
          if (chat_hours <= result) {
            setChatHours(true);
          }
        }
      }
      socket &&
        socket.emit("join_group", {
          from: selectedChat && selectedChat.unique_id,
          from_id: localStorage.getItem("id"),
          gp: activeChat.private_chat_room,
          to_id: selectedChat && selectedChat.id,
        });
    } else if (role === "TravelMarshall") {
      swal("Chat not initialized from wt");
      // }
    }
    getChatHystory();
    return () => {};
  }, [selectedChat]);
  const handleRating = (rate) => {
    setFeedback({ ...feedback, rating: rate >= 20 ? rate / 20 : 1 });
    // other logic
  };

  useEffect(() => {
    getChatHystory();
  }, []);
  const handleClose = () => {
    setShow(false);
    setRating(false);
  };
  const onFormSubmit = (e) => {
    e.preventDefault();
    // send state to server with e.g. `window.fetch`

    sendMessage();
  };

  const getReport = () => {
    simpleGetCall(ApiConfig.REPORT_DROPDOWN).then((res) => {
      // console.log(res);
      setReportOptions(res.list_report);
    });
  };
  const getFeedback = () => {
    simpleGetCall(ApiConfig.FEEDBACK_DROPDOWN).then((res) => {
      // console.log(res);
      setFeedbackOptions(res.list_feedback);
    });
  };
  useEffect(() => {
    getReport();
    getFeedback();

    return () => {};
  }, []);

  return (
    <>
      <main className="home-getStarted">
        <Header />

        <Modal
          responsive-md
          className="modal-md"
          id="rating"
          role="dialog"
          show={rating}
          onHide={handleClose}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton></Modal.Header>

          <div className="text-center">
            <div className="img-rating">
              <img src={selectedChat && selectedChat.avatar} alt="" />
            </div>
            <div>
              <p className="user-name mt-3 mb-3">
                {selectedChat && selectedChat.unique_id}
                <br />{" "}
              </p>
            </div>
            <div className="rating-star">
              <p className="text-center popup-heading mt-1 mb-2">
                Do you like the service?
              </p>
              <Rating
                onClick={handleRating}
                ratingValue={1} /* Available Props */
              />
              {/* <img src={yello} alt="" />
              <img src={yello} alt="" />

              <img src={yello} alt="" />
              <img src={yello} alt="" />
              <img src={grey} alt="" /> */}
            </div>
            <p className="text-center model_rate mt-2">Tap a Star to Rate</p>
            <label htmlFor="" className="model_feedback mb-3 mt-3">
              Give your valuable feedback
            </label>
            <div>
              <div className="">
                <select
                  className=" form-select mb-2"
                  aria-label="Default select example"
                  value={feedback.subject}
                  onChange={(e) => setFeedback({ subject: e.target.value })}
                >
                  {feedbackOptions &&
                    feedbackOptions.length &&
                    feedbackOptions.map((feedback) => {
                      return (
                        <option value={feedback.data}>{feedback.data}</option>
                      );
                    })}
                  {/* {<option>TM was behaviour was professional</option>} */}
                </select>
              </div>
            </div>
            <div className="form-group text-report mt-3">
              <textarea
                className="form-control model_text2"
                id="exampleFormControlTextarea1"
                rows="4"
                placeholder="Leave a review (Optional)"
                onChange={(e) =>
                  setFeedback({ ...feedback, message: e.target.value })
                }
              ></textarea>
            </div>
          </div>
          <div className="btn-popup-modal justify-content-evenly  d-flex ">
            <div className="pt-2 ms-5">
              <a href="">
                <button className="popdis-btn">Dismiss</button>
              </a>
            </div>
            <div className="pt-2  me-5">
              <a href="">
                <button className="popup-btn" onClick={(e) => feedBackTM(e)}>
                  Submit
                </button>
              </a>
            </div>
          </div>
        </Modal>
        <Modal
          role="dialog"
          show={show}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          {" "}
          <Modal.Header
            onClick={() => setShow(!show)}
            closeButton
          ></Modal.Header>
          <Modal.Body>
            <div className="w-100 h-100">
              <iframe src={message} width="100%" height={"500px"} />
            </div>
          </Modal.Body>
        </Modal>

        <div className="container" style={{ marginBottom: "50px" }}>
          {/* <h5 htmlFor="" className="chat_head">
            Chats
          </h5> */}

          <div className="container chatcontainer">
            <div className="row">
              {/* <Header /> */}
              <h5 htmlFor="" className="chat_head">
                Chats
              </h5>
            </div>

            <div className="chats_outside  ">
              <div className="col-lg-12  p-4 chats_outside ">
                <div className="card p-2  chats_inside" id="card">
                  <div className="row">
                    <div className="col-lg-3">
                      <label htmlFor="" className="ACTIVE">
                        ACTIVE WT
                      </label>
                      <div className="row ">
                        {chatList ? (
                          chatList.length > 0 &&
                          chatList
                            .filter(
                              (chat) =>
                                chat.connect_status &&
                                chat.connect_status.chat_status === "Connect"
                            )
                            .map((list, index) => {
                              return (
                                <div
                                  className=" mt-2"
                                  onClick={() => {
                                    setActiveChat({
                                      ...list.chat.chat_room_details,
                                      chat_status:
                                        list.connect_status.chat_status,
                                    });
                                    setSelectedChat(list);
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  <div
                                    className="card card_active p-2"
                                    id="card"
                                    key={"list" + index}
                                  >
                                    <div className=" d-flex justify-content-between">
                                      <div className="d-flex  ">
                                        <img
                                          src={list.avatar}
                                          className="marchall_img rounded-circle"
                                          style={{ width: "35px" }}
                                          alt=""
                                        />
                                        <div className="ms-2">
                                          <p className="userid">
                                            {list.unique_id}
                                            <p
                                              className="man Col-2 d-inline-block text-truncate"
                                              id="man"
                                              style={{ width: "100px" }}
                                            >
                                              {list.chat &&
                                                list.chat.conversation_message}
                                            </p>
                                          </p>
                                        </div>
                                      </div>
                                      <p className="time pt-1">
                                        {list.chat &&
                                          list.chat.conversation_datetime}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                        ) : (
                          <p>"No Active Chats"</p>
                        )}
                        <label htmlFor="" className="recent_chat mt-3 ps-3">
                          RECENT CHAT HISTORY
                        </label>
                        {notconnectedChatList && notconnectedChatList.length ? (
                          notconnectedChatList
                            .filter(
                              (chat, index) =>
                                chat.connect_status &&
                                chat.connect_status.chat_status !== "Connect"
                            )
                            .map((list, index) => {
                              return (
                                <div
                                  className=" mt-2"
                                  style={{ cursor: "pointer" }}
                                  key={"list" + index}
                                  onClick={() => {
                                    setActiveChat({
                                      ...list.chat.chat_room_details,
                                      chat_status:
                                        list.connect_status.chat_status,
                                    });
                                    setSelectedChat(list);
                                  }}
                                >
                                  <div
                                    className="card card_active p-2"
                                    id="card"
                                  >
                                    <div className=" d-flex justify-content-between">
                                      <div className="d-flex  ">
                                        <img
                                          src={list.avatar}
                                          className="marchall_img rounded-circle"
                                          style={{ width: "35px" }}
                                          alt=""
                                        />
                                        <div className="ms-2">
                                          <p className="userid">
                                            {list.unique_id}
                                            <p
                                              className="man Col-2  text-truncate"
                                              id="man"
                                              style={{ width: "100px" }}
                                            >
                                              {list.chat &&
                                                list.chat.conversation_message}
                                            </p>
                                          </p>
                                        </div>
                                      </div>
                                      <p className="time pt-1 ">
                                        {list.chat &&
                                          list.chat.conversation_datetime}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                        ) : (
                          <p
                            className="text-left m-1 text-danger"
                            style={{ fontSize: "10px" }}
                          >
                            No recent chats
                          </p>
                        )}
                      </div>
                    </div>
                    {/* -----Right section----- */}
                    <div className="col-lg-9">
                      <div className="header">
                        {/* <!-- Navbar Start --> */}
                        <nav
                          className="nav navbar   navbar-expand navbar-dark Chat_header"
                          id="card"
                        >
                          <div className="container ">
                            <Link className="navbar-brand d-flex pt-3" to="/">
                              <div className="online_Active">
                                <img
                                  src={
                                    (selectedChat && selectedChat.avatar) ||
                                    user_1
                                  }
                                  alt=""
                                  height="40"
                                  className="rounded-circle"
                                />
                                <img
                                  src={
                                    selectedChat &&
                                    selectedChat.connect_status &&
                                    selectedChat.connect_status.chat_status ===
                                      "Connect"
                                      ? online_icon
                                      : disconnect_icon
                                  }
                                  alt=""
                                  className="online"
                                />
                              </div>
                              <div className=" ms-2 ">
                                <p className="user_id">
                                  {selectedChat && selectedChat.unique_id}
                                  <p htmlFor="" className="d-block switch">
                                    {selectedChat &&
                                    selectedChat.connect_status &&
                                    selectedChat.connect_status.chat_status ===
                                      "Connect"
                                      ? "Active"
                                      : "Disconnected"}
                                  </p>
                                </p>
                              </div>
                            </Link>
                            <div className="chat-dropdown dropdown">
                              {selectedChat &&
                                selectedChat.connect_status &&
                                selectedChat.connect_status.chat_status ===
                                  "Connect" && (
                                  <a
                                    href=""
                                    className="icon-dropdown"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <img
                                      src={options}
                                      className="ms-3 "
                                      alt=""
                                      height="24"
                                    />
                                  </a>
                                )}
                              <ul className="dropdown-menu ">
                                <li>
                                  <button
                                    className="dropdown-item disconnet"
                                    type="button"
                                    data-toggle="modal"
                                    data-target="#reports"
                                    id="bids"
                                    onClick={() => setReport(!report)}
                                  >
                                    <p className="dis-text">Report</p>
                                  </button>
                                </li>

                                {localStorage.getItem("role") ===
                                  "Traveler" && (
                                  <>
                                    <li>
                                      <button
                                        type="button"
                                        className="dropdown-item  disconnet "
                                        data-toggle="modal"
                                        data-target="#disconnet"
                                        id="bids"
                                        onClick={() => setdisconnet(!disconnet)}
                                      >
                                        <p className="dis-text">Disconnect </p>
                                        <img
                                          src={up_arrow}
                                          className="arrow"
                                          alt=""
                                        />
                                      </button>
                                    </li>

                                    <li>
                                      <button
                                        className="dropdown-item disconnet"
                                        type="button"
                                        data-toggle="modal"
                                        data-target="#block"
                                        id="bids"
                                        onClick={() => {
                                          setBlock(!block);
                                        }}
                                      >
                                        <p className="dis-text">Block</p>
                                      </button>
                                    </li>
                                  </>
                                )}
                              </ul>
                            </div>
                            {/* ------------Disconnect Model ------ */}
                            <Modal
                              responsive-md
                              className=""
                              id="disconnet"
                              role="dialog"
                              show={disconnet}
                              size="md"
                              aria-labelledby="contained-modal-title-vcenter"
                              centered
                            >
                              <div className="container">
                                <div className="text-center justify-content-center">
                                  <div>
                                    <img src={disconnect} alt="" />
                                    <p className="d-block model_lael pt-3">
                                      Disconnect
                                    </p>
                                  </div>
                                </div>
                                <div>
                                  <p className="text-center popup-heading mt-4">
                                    Are you sure you want to disconnect with{" "}
                                    {selectedChat && selectedChat.unique_id}?
                                  </p>
                                </div>

                                <div className="d-flex justify-content-center">
                                  <div>
                                    <button
                                      className="cancel-btn-chats1"
                                      onClick={() => setdisconnet("")}
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      className="cancel-btn-chats2"
                                      onClick={() => {
                                        sendContactDisconect();
                                      }}
                                      data-toggle="modal"
                                      data-target="#rating"
                                      id="rating"
                                    >
                                      Disconnect
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </Modal>
                            {/* ---------------------Report Model ----------- */}
                            <Modal
                              responsive-md
                              className="modal-md"
                              id="reports"
                              role="dialog"
                              show={report}
                              size="lg"
                              aria-labelledby="contained-modal-title-vcenter"
                              centered
                            >
                              <div>
                                <div className="text-center justify-content-center">
                                  <div>
                                    <img src={report_icon} alt="" />
                                    <label
                                      htmlFor=""
                                      className="d-block model_lael pt-3"
                                    >
                                      Report World Traveller
                                    </label>
                                  </div>
                                </div>
                                <div>
                                  <div className="pt-3">
                                    <p className="text-left popup-heading m-2">
                                      Why are you reporting this travel
                                      marshall?
                                    </p>
                                  </div>
                                </div>
                                <div className="form-group m-1 ">
                                  <select
                                    className=" form-select mb-3  me-2"
                                    aria-label="Default select example"
                                    onChange={(e) =>
                                      setReporting({
                                        ...reporting,
                                        subject: e.target.value,
                                      })
                                    }
                                  >
                                    {reportOptions &&
                                      reportOptions.length &&
                                      reportOptions.map((report) => {
                                        return (
                                          <option value={report.data}>
                                            {report.data}
                                          </option>
                                        );
                                      })}
                                  </select>
                                  <label className="popup-heading p-1">
                                    Please describe your reason for reporting .
                                  </label>
                                  <textarea
                                    className="form-control text-area model_text"
                                    id=""
                                    rows="3"
                                    placeholder="Type here"
                                    onChange={(e) =>
                                      setReporting({
                                        ...reporting,
                                        message: e.target.value,
                                      })
                                    }
                                  ></textarea>
                                </div>
                              </div>
                              <div className="row mt-2">
                                <div className="d-flex justify-content-center">
                                  <div>
                                    <button
                                      className="cancel-btn-chats1"
                                      onClick={() => setReport("")}
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                  <div>
                                    <a href="">
                                      {" "}
                                      <button
                                        onClick={reportTM}
                                        className="cancel-btn-report"
                                      >
                                        Report
                                      </button>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </Modal>
                            {/* ---------------Block Model------------ */}
                            <Modal
                              responsive-md
                              className="modal-xlg"
                              id="block"
                              role="dialog"
                              show={block}
                              size="md"
                              aria-labelledby="contained-modal-title-vcenter"
                              centered
                            >
                              <div className="text-center justify-content-center">
                                <div>
                                  <img src={disconnect} alt="" />
                                  <label
                                    htmlFor=""
                                    className="d-block model_lael pt-3"
                                  >
                                    Block
                                  </label>
                                </div>
                              </div>
                              <div>
                                <p className="text-center popup-heading mt-4">
                                  Are you sure you want to Block with{" "}
                                  {selectedChat && selectedChat.unique_id}?
                                </p>
                              </div>

                              <div className="d-flex justify-content-center">
                                <div>
                                  <button
                                    className="cancel-btn-chats1"
                                    onClick={() => setBlock("")}
                                  >
                                    Cancel
                                  </button>
                                </div>
                                <div>
                                  <a
                                    href="#"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setBlock(!block);
                                      PostCallWithErrorResponse(
                                        ApiConfig.BLOCK_USER,
                                        {
                                          block_TM: selectedChat.id,
                                          block: false,
                                        }
                                      ).then((res) => {
                                        // console.log(res);
                                        sendContactDisconect();
                                        swal(res.json.message);
                                      });
                                    }}
                                  >
                                    <button className="cancel-btn-chats2">
                                      Block
                                    </button>
                                  </a>
                                </div>
                              </div>
                            </Modal>
                          </div>
                        </nav>
                        <div className="body p-3">
                          <div
                            className="chat_section"
                            id="scrollChat"
                            style={{ minHeight: "500px" }}
                          >
                            <button
                              style={{
                                border: "1px solid #F7BD80",
                                borderRadius: "10px",
                                padding: "2px",
                                background: "none",
                                color: "#F7BD80",
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                setPage((page) => page + 1);
                              }}
                            >
                              Load chats
                            </button>
                            {loading && (
                              <ThreeDots
                                color="#212529"
                                height={30}
                                width={30}
                              />
                            )}
                            {messages.map((msg, index) => {
                              if (
                                msg.conversation_from ==
                                Number(localStorage.getItem("id"))
                              ) {
                                if (
                                  msg.conversation_message.includes(".png") ||
                                  msg.conversation_message.includes(".jpg") ||
                                  msg.conversation_message.includes(".jpeg") ||
                                  msg.conversation_message.includes(".gif")
                                ) {
                                  return (
                                    <div
                                      className="user_message"
                                      key={"msg" + index}
                                    >
                                      <div className="mb-2">
                                        <a
                                          href="#"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setShow(!show);
                                            setMessage(
                                              msg.conversation_message
                                            );
                                          }}
                                          className="text-decoration-none  btn-sm btn-dark"
                                        >
                                          View in window
                                        </a>
                                      </div>
                                      <img
                                        src={msg.conversation_message}
                                        style={{
                                          width: "100px",
                                          height: "100px",
                                        }}
                                      />
                                    </div>
                                  );
                                } else if (
                                  msg.conversation_message.includes(".pdf")
                                ) {
                                  return (
                                    <div
                                      className="user_message"
                                      key={"msg" + index}
                                    >
                                      <div className="mb-2">
                                        <a
                                          href="#"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setShow(!show);
                                            setMessage(
                                              msg.conversation_message
                                            );
                                          }}
                                          className="text-decoration-none  btn-sm btn-dark"
                                        >
                                          View in window
                                        </a>
                                      </div>
                                      <embed
                                        width="250"
                                        height="300"
                                        name="plugin"
                                        src={msg.conversation_message}
                                        type="application/pdf"
                                      />
                                      {/* <PdfReaser
                                        src={msg.conversation_message}
                                      /> */}
                                    </div>
                                  );
                                } else
                                  return (
                                    <p
                                      className="user_message"
                                      key={"msg" + index}
                                    >
                                      {msg.conversation_message}
                                    </p>
                                  );
                              } else {
                                if (
                                  msg.conversation_message.includes(".png") ||
                                  msg.conversation_message.includes(".jpg") ||
                                  msg.conversation_message.includes(".jpeg") ||
                                  msg.conversation_message.includes(".gif")
                                ) {
                                  return (
                                    <div
                                      className="message d-block"
                                      key={"msg" + index}
                                    >
                                      <div className="mb-2">
                                        <a
                                          href="#"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setShow(!show);
                                            setMessage(
                                              msg.conversation_message
                                            );
                                          }}
                                          className="text-decoration-none  btn-sm btn-dark"
                                        >
                                          View in window
                                        </a>
                                      </div>
                                      <img
                                        src={msg.conversation_message}
                                        style={{
                                          width: "100px",
                                          height: "100px",
                                        }}
                                      />
                                    </div>
                                  );
                                } else if (
                                  msg.conversation_message.includes(".pdf")
                                ) {
                                  return (
                                    <div
                                      className="message d-block"
                                      key={"msg" + index}
                                    >
                                      <div className="mb-2">
                                        <a
                                          href="#"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setShow(!show);
                                            setMessage(
                                              msg.conversation_message
                                            );
                                          }}
                                          className="text-decoration-none  btn-sm btn-dark"
                                        >
                                          View in window
                                        </a>
                                      </div>
                                      <embed
                                        width="250"
                                        height="300"
                                        name="plugin"
                                        src={msg.conversation_message}
                                        type="application/pdf"
                                      />
                                    </div>
                                  );
                                } else
                                  return (
                                    <p
                                      className="message d-block"
                                      key={"msg" + index}
                                    >
                                      {msg.conversation_message}
                                    </p>
                                  );
                              }
                            })}
                          </div>

                          <div className="msg_input">
                            {loading && (
                              <ThreeDots
                                color="#212529"
                                height={30}
                                width={30}
                              />
                            )}
                            {selectedChat &&
                              selectedChat.connect_status &&
                              selectedChat.connect_status.chat_status ===
                                "Connect" && (
                                <div className="footer">
                                  <form
                                    onSubmit={onFormSubmit}
                                    style={{ position: "relative" }}
                                  >
                                    <a
                                      href="#"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        document
                                          .getElementById("uploadFile")
                                          .click();
                                      }}
                                    >
                                      <img
                                        src={image}
                                        alt=""
                                        className="image_img"
                                      />
                                    </a>
                                    <input
                                      type="file"
                                      className="footer_input "
                                      placeholder="Type a message"
                                      onChange={(e) =>
                                        uploadFile(e.target.files[0])
                                      }
                                      style={{ display: "none" }}
                                      id="uploadFile"
                                    />

                                    <input
                                      type="text"
                                      className="footer_input "
                                      placeholder="Type a message"
                                      value={message}
                                      onChange={(e) =>
                                        setMessage(e.target.value)
                                      }
                                    />
                                    <button
                                      type="submit"
                                      style={{
                                        border: "none",
                                        backgroundColor: "transparent",
                                        marginTop: "-20px",
                                      }}
                                    >
                                      <img
                                        // onClick={() => sendMessage()}
                                        src={send}
                                        alt=""
                                        className="send1 mt-1"
                                      />
                                    </button>
                                  </form>
                                </div>
                              )}
                            {selectedChat &&
                              selectedChat.connect_status &&
                              selectedChat.connect_status.chat_status !=
                                "Connect" && (
                                <div
                                  className="col-12 p-3 text-secondary text-center"
                                  style={{
                                    backgroundColor: "#FFFBF3",
                                    borderRadius: "10px",
                                  }}
                                >
                                  <p>
                                    You can't send messages to this chat because
                                    you're disconnected with this Travel
                                    Marshall
                                  </p>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ------------Disconnect Model ------ */}
        {role === "TravelMarshall" && (
          <Modal
            responsive-md
            className=""
            id="chatHours"
            role="dialog"
            show={chatHours}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <div className="container">
              {/* <div className="text-center justify-content-center">
              <div>
                <img src={disconnect} alt="" />
                <p className="d-block model_lael pt-3">Disconnect</p>
              </div>
            </div> */}
              <div>
                <p className="text-center popup-heading mt-4">
                  Would You Like to ?
                </p>
              </div>

              <div className="d-flex justify-content-center">
                <div>
                  <button className="cancel-btn-chats1" onClick={continueChat}>
                    Continue
                  </button>
                </div>
                <div>
                  <button
                    className="cancel-btn-chats2"
                    onClick={() => {
                      sendContactDisconect();
                    }}
                    data-toggle="modal"
                    data-target="#rating"
                    id="rating"
                  >
                    Disconnect
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        )}

        <Footer />
      </main>
    </>
  );
};

export default Chats;
