import React, { useContext, useEffect, useState } from "react";
import sossvg from "../assets/images/sos.svg";
import travferrlogo from "../assets/images/travferrlogo.png";
import { Modal } from "react-bootstrap";
import document_icon from "../assets/images/Navbar/document_icon.svg";
import faq from "../assets/images/Navbar/faq.svg";
import up_arrow from "../assets/images/Navbar/up_arrow.svg";
import profile from "../assets/images/Navbar/profile.svg";
import right_arrow from "../assets/images/Navbar/right_arrow.svg";
import transactions from "../assets/images/Navbar/transactions.svg";
import aboutus from "../assets/images/Navbar/aboutus.svg";
import contact from "../assets/images/phone-out.svg";
import logoutimg from "../assets/images/sign-out.svg";
import terms_and_conditons from "../assets/images/Navbar/terms_and_conditons.svg";
import privacy_policy from "../assets/images/Navbar/privacy_policy.svg";
import creditcard1 from "../assets/images/PayMethods/payment_icon.svg";
import { Link, NavLink } from "react-router-dom";
import homesvg from "../assets/images/home.svg";
import siren from "../assets/images/siren.svg";
import menu from "../assets/images/menu.svg";
import eco from "../assets/images/Profile/Eco.png";
import chattingsvg from "../assets/images/chatting.svg";
// import chatsvg from "../assets/images/chat.svg"
import { AppContext } from "../context/user/AppContext";
import ApiConfig from "../api/ApiConfig";

import { postWithAuthCall } from "../api/ApiServices";
import swal from "sweetalert";

const Header = () => {
  const { userDetails, role, setLoggedIn, logedIn, profileDetails } =
    useContext(AppContext);
  const [singout, setSingout] = useState(false);
  const logout = (e) => {
    // e.preventDefault();
    localStorage.removeItem("id");
    localStorage.removeItem("unique_id");
    localStorage.removeItem("auth_token");
    localStorage.removeItem("role");
    localStorage.removeItem("userDetails");
    localStorage.clear();
    setLoggedIn(false);
  };

  const [lat, setlat] = useState("");
  const [lon, setlon] = useState("");

  const [loading, setLoading] = useState(false);
  const [sos, setSos] = useState(false);

  useEffect(() => {
    let geolocation = require("geolocation");

    geolocation.getCurrentPosition(function (err, position) {
      if (err) throw err;
      setlat(position.coords.latitude);
      setlon(position.coords.longitude);
    });
    return () => {};
  }, []);

  const sendLatlon = async () => {
    let requstBody = JSON.stringify({
      latitude: lat.toString(),
      longitude: lon.toString(),
    });
    // console.log(ApiConfig.POST_SOS, requstBody);
    postWithAuthCall(ApiConfig.POST_SOS, requstBody)
      .then((res) => {
        // console.log(res);
        setSos("");
        swal("SOS Sent Successfully");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <main className="Header">
        <div className="container">
          {/* <!-- Navbar Start --> */}
          <nav className="navbar  navbar-expand-lg navbar-dark">
            <div className="container">
              <Link
                className="navbar-brand"
                to={
                  logedIn && role === "TravelMarshall"
                    ? "/Home"
                    : role === "TravelMarshall"
                    ? "/SelectMarshall"
                    : "/"
                }
                // to="/"
              >
                <img
                  src={travferrlogo}
                  alt=""
                  height="120"
                  className="travfer-logo-main1"
                />
              </Link>
              <span className="aavtar-visibility1"></span>
              <button
                className="navbar-toggler btn btn-outline-warning bg-warning"
                id="disable-btn-2"
                style={{
                  backgroundColor:
                    "transparent linear-gradient(180deg, #FDCA94 0%, #EBA55D 100%) 0% 0% no-repeat padding-box",
                }}
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon text-gray"></span>
              </button>

              {/* //..............offcanvas...................// */}
              <button
                className="btn "
                id="disablebtn1"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasScrolling"
                aria-controls="offcanvasScrolling"
              >
                {" "}
                <span className="navbar-toggler-icon text-gray"></span>{" "}
              </button>

              <div
                className="offcanvas offcanvas-start"
                data-bs-scroll="true"
                data-bs-backdrop="false"
                tabIndex="-1"
                id="offcanvasScrolling"
                aria-labelledby="offcanvasScrollingLabel"
              >
                <div className="offcanvas-header">
                  <h5 className="offcanvas-title" id="offcanvasScrollingLabel">
                    <Link
                      className="navbar-brand"
                      to={
                        logedIn && role === "TravelMarshall"
                          ? "/Home"
                          : role === "TravelMarshall"
                          ? "/SelectMarshall"
                          : "/"
                      }
                    >
                      <img
                        className="travfer-drawer1"
                        src={travferrlogo}
                        alt=""
                        style={{ height: "100px", marginLeft: "-6px" }}
                      />
                    </Link>
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="offcanvas-body">
                  {/* <p>Try scrolling the rest of the page to see this option in action.</p> */}
                  <ul className="navbar-nav1-canvas  nav mt-1">
                    {/* <................icon drawer............> */}
                    <li>
                      <div
                        className="accordion accordion-flush "
                        id="accordionFlushExample"
                      >
                        <div
                          className="accordion-item"
                          style={{ background: "#FFFBF3", boxShadow: "none" }}
                        >
                          <h2
                            className="accordion-header"
                            id="flush-headingOne"
                          >
                            {/* <button className=" collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne"> */}

                            {userDetails && userDetails.avatar.avatar ? (
                              <img
                                src={userDetails.avatar.avatar}
                                alt=""
                                className=" collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseOne"
                                aria-expanded="false"
                                aria-controls="flush-collapseOne"
                                width="50px"
                              />
                            ) : (
                              <>
                                {logedIn && (
                                  <img
                                    src={travferrlogo}
                                    alt=""
                                    className=" collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseOne"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseOne"
                                    width="50px"
                                  />
                                )}
                              </>
                            )}

                            {/* </button> */}
                          </h2>
                          <div
                            id="flush-collapseOne"
                            className="accordion-collapse collapse"
                            aria-labelledby="flush-headingOne"
                            data-bs-parent="#accordionFlushExample"
                          >
                            {/* <div className="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> className. This is the first item's accordion body.</div> */}
                            <ul className="icon-drawer1 ">
                              <li className="list-unstyled mt-2  ">
                                <Link to="/Profile" className=" ">
                                  <div className="d-flex ms-2 promenu m-2">
                                    <div className="d-flex justify-content-around">
                                      <img className="" src={profile} alt="" />
                                      <p
                                        className=" "
                                        style={{
                                          fontSize: "12px",
                                          marginLeft: "20px",
                                        }}
                                      >
                                        Profile{" "}
                                      </p>
                                    </div>
                                  </div>
                                  {/* <img className=" me-3" src={right_arrow} alt="" /> */}
                                </Link>
                              </li>
                              <li className="list-unstyled mt-3">
                                <Link to="/ContactUs" className=" ">
                                  <div className="d-flex ms-2 promenu m-2">
                                    <div className="d-flex justify-content-around">
                                      <img className="" src={contact} alt="" />
                                      <p
                                        className=" "
                                        style={{
                                          fontSize: "12px",
                                          marginLeft: "20px",
                                        }}
                                      >
                                        Contact Us{" "}
                                      </p>
                                    </div>
                                  </div>
                                  {/* <img className=" me-3" src={right_arrow} alt="" /> */}
                                </Link>
                              </li>

                              {role === "TravelMarshall" && (
                                <>
                                  <li className="list-unstyled  mt-2 ">
                                    <Link
                                      to="/TransferMoney"
                                      className="icon_arrow"
                                    >
                                      <div className="d-flex ms-2 mt-4">
                                        <img
                                          className=""
                                          src={creditcard1}
                                          alt=""
                                        />
                                        <p
                                          className=""
                                          style={{
                                            marginLeft: "18px",
                                            fontSize: "12px",
                                          }}
                                        >
                                          Payment
                                        </p>
                                      </div>
                                    </Link>
                                  </li>
                                </>
                              )}

                              {role === "Traveler" && (
                                <>
                                  <li className="list-unstyled  mt-2 ">
                                    <Link
                                      to="/DocumentVault"
                                      className="icon_arrow"
                                    >
                                      <div className="d-flex ms-2 mt-4">
                                        <img
                                          className=""
                                          src={document_icon}
                                          alt=""
                                        />
                                        <p
                                          className=""
                                          style={{
                                            marginLeft: "16px",
                                            fontSize: "12px",
                                          }}
                                        >
                                          Document Vault
                                        </p>
                                        {/* <img className=" me-3" src={right_arrow} alt="" style={{marginLeft:"112px"}}/> */}
                                      </div>
                                    </Link>
                                  </li>
                                </>
                              )}
                              <li className="list-unstyled mt-2 ">
                                <Link to="/TranscationHistory">
                                  <div className="d-flex ms-2 mt-4">
                                    <img
                                      className=""
                                      src={transactions}
                                      alt=""
                                    />
                                    <p
                                      className=""
                                      style={{
                                        fontSize: "12px",
                                        marginLeft: "12px",
                                      }}
                                    >
                                      Transaction History
                                    </p>
                                  </div>
                                </Link>
                              </li>
                              <li className="list-unstyled mt-2  ">
                                <Link to="/Aboutus" className="icon_arrow ">
                                  <div className="d-flex ms-2 mt-4">
                                    <img className="" src={aboutus} alt="" />
                                    <p
                                      className=""
                                      style={{
                                        marginLeft: "20px",
                                        fontSize: "12px",
                                      }}
                                    >
                                      About Us{" "}
                                    </p>
                                    {/* <img className=" " src={right_arrow} alt="" style={{marginLeft:"100px"}}/> */}
                                  </div>
                                </Link>
                              </li>
                              {role === "Traveler" && (
                                <li className="list-unstyled mt-2 ">
                                  <Link to="/FAQ">
                                    <div className="d-flex ms-2 mt-4">
                                      <img className="" src={faq} alt="" />
                                      <p
                                        className=""
                                        style={{
                                          marginLeft: "16px",
                                          fontSize: "12px",
                                        }}
                                      >
                                        FAQ
                                      </p>
                                    </div>
                                    {/* <img className=" me-3" src={right_arrow} alt="" /> */}
                                  </Link>
                                </li>
                              )}

                              <li className="list-unstyled  mt-2">
                                <Link
                                  to="/TermandCondition"
                                  className="icon_arrow"
                                >
                                  <div className="d-flex ms-2 mt-4">
                                    <img
                                      className=""
                                      src={terms_and_conditons}
                                      alt=""
                                    />
                                    <p
                                      className=""
                                      style={{
                                        marginLeft: "20px",
                                        fontSize: "12px",
                                      }}
                                    >
                                      Terms and Conditions
                                    </p>
                                  </div>
                                  {/* <img className=" me-3" src={right_arrow} alt="" /> */}
                                </Link>
                              </li>
                              <li className="list-unstyled  mt-2">
                                <Link to="/PrivacyandPolicy">
                                  <div className="d-flex ms-2 mt-4">
                                    <img
                                      className=""
                                      src={privacy_policy}
                                      alt=""
                                    />
                                    <p
                                      className=""
                                      style={{
                                        marginLeft: "20px",
                                        fontSize: "12px",
                                      }}
                                    >
                                      Privacy Policy
                                    </p>
                                  </div>
                                  {/* <img className=" me-3" src={right_arrow} alt="" /> */}
                                </Link>
                              </li>
                              {logedIn && (
                                <li className="list-unstyled mt-2 mb-1">
                                  <Link
                                    to="#"
                                    className="icon_arrow"
                                    data-toggle="singout"
                                    data-target="#singout"
                                    id="singout"
                                    onClick={() => setSingout(!singout)}
                                  >
                                    <div className="d-flex ms-2 mt-4">
                                      <img
                                        className=""
                                        src={logoutimg}
                                        alt=""
                                      />
                                      <p
                                        className=""
                                        style={{
                                          marginLeft: "23px",
                                          fontSize: "12px",
                                        }}
                                      >
                                        Log out
                                      </p>
                                    </div>

                                    {/* <img className=" me-3" src={right_arrow} alt="" /> */}
                                  </Link>
                                </li>
                              )}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </li>
                    {/* <................icon drawer endss............> */}
                    {logedIn ? (
                      <>
                        <li
                          className="nav-item "
                          role="presentation"
                          data-toggle="pill"
                        >
                          <img
                            src={homesvg}
                            alt=""
                            className="me-4 pt-2 ms-2"
                          />
                          <NavLink
                            className="nav-link "
                            to={
                              logedIn && role === "TravelMarshall"
                                ? "/Home"
                                : role === "Traveler"
                                ? "/SelectMarshall"
                                : "/"
                            }
                          >
                            Home
                          </NavLink>
                        </li>
                        <li className="nav-item ">
                          <img
                            src={chattingsvg}
                            alt=""
                            className="me-4 pt-2 ms-2"
                          />
                          <NavLink className=" nav-link  " to="/Chats">
                            Chat{" "}
                          </NavLink>
                        </li>
                        {role === "Traveler" && (
                          <li className="nav-item ">
                            <img src={menu} alt="" className="me-4 pt-2 ms-2" />
                            <NavLink className=" nav-link " to="/Subscription">
                              Subscription
                            </NavLink>
                          </li>
                        )}
                        <li className="nav-item  ">
                          <img src={siren} alt="" className="me-4 pt-2 ms-2" />
                          <NavLink className=" nav-link " to="/Emergency">
                            Emergency{" "}
                          </NavLink>
                        </li>
                      </>
                    ) : (
                      <>
                        <li className="nav-item">
                          <Link
                            className="nav-link "
                            to="#"
                            // onClick={handleExplore}
                          >
                            Home
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link className="nav-link " to="/AboutUsHome">
                            About Us
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link className="nav-link " to="/ContactUs">
                            Contact Us
                          </Link>
                        </li>
                      </>
                    )}
                    {logedIn && role === "Traveler" && (
                      <li className="nav-item " id="">
                        <img src={sossvg} alt="" className="me-4 pt-2 ms-2 " />
                        <NavLink
                          className="nav-link      "
                          style={{ borderBottom: "none" }}
                          data-toggle="sos"
                          data-target="#sos"
                          id="bids"
                          onClick={() => setSos(!sos)}
                          to="#"
                        >
                          <button className="sos-btn-canvas">SOS</button>
                        </NavLink>
                      </li>
                    )}
                  </ul>
                </div>
              </div>

              <div
                className="collapse navbar-collapse header-body "
                id="navbarNav"
              >
                <div className="mx-auto"></div>
                <ul className="navbar-nav nav">
                  {logedIn ? (
                    <>
                      <li
                        className="nav-item "
                        role="presentation"
                        data-toggle="pill"
                      >
                        <NavLink
                          className="nav-link "
                          to={
                            logedIn && role === "TravelMarshall"
                              ? "/Home"
                              : role === "Traveler"
                              ? "/SelectMarshall"
                              : "/"
                          }
                        >
                          Home
                        </NavLink>
                      </li>
                      <li className="nav-item ">
                        <NavLink className=" nav-link  " to="/Chats">
                          Chat{" "}
                        </NavLink>
                      </li>
                      {role === "Traveler" && (
                        <li className="nav-item ">
                          <NavLink className=" nav-link " to="/Subscription">
                            Subscription
                          </NavLink>
                        </li>
                      )}
                      <li className="nav-item  ">
                        <NavLink className=" nav-link " to="/Emergency">
                          Emergency{" "}
                        </NavLink>
                      </li>
                    </>
                  ) : (
                    <>
                      <li className="nav-item">
                        <Link
                          className="nav-link "
                          to="/"
                          // onClick={handleExplore}
                        >
                          Home
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link " to="/AboutUsHome">
                          About Us
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link " to="/ContactUs">
                          Contact Us
                        </Link>
                      </li>
                    </>
                  )}
                  {logedIn && role === "Traveler" && (
                    <li className="nav-item me-4" id="sos">
                      <NavLink
                        className="nav-link     text-center "
                        style={{ borderBottom: "none" }}
                        data-toggle="sos"
                        data-target="#sos"
                        id="bids"
                        onClick={() => setSos(!sos)}
                        to="#"
                      >
                        <span id="sos_text" className="sos">
                          {" "}
                          SOS
                        </span>{" "}
                      </NavLink>
                    </li>
                  )}
                </ul>
                <div
                  className="icon-dropdown icon-dropdown-1 dropdown"
                  id="avtardropdown"
                >
                  {logedIn && (
                    <a
                      href=""
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      className="modal-lg"
                      size="md"
                      aria-labelledby="contained-modal-title-vcenter"
                      role="dialog"
                    >
                      <a className="nav-link text-left" href="#">
                        {userDetails && userDetails.avatar.avatar ? (
                          <img
                            src={userDetails.avatar.avatar}
                            alt=""
                            width="50px"
                          />
                        ) : (
                          <img src={travferrlogo} alt="" width="50px" />
                        )}
                      </a>
                    </a>
                  )}
                  <ul className="dropdown-menu model" id="dropdown-custome1">
                    {role === "TravelMarshall" && (
                      <li className="list-unstyled mt-2 border-bottom ">
                        <Link to="/Profile" className="icon_arrow ">
                          <div className="d-flex ms-2 promenu m-2">
                            <img src={up_arrow} className="up_arrow" alt="" />
                            <img className="" src={profile} alt="" />
                            <p className="contnt  ps-3 ">Profile </p>
                          </div>
                          <img className=" me-3" src={right_arrow} alt="" />
                        </Link>
                      </li>
                    )}
                    {role === "Traveler" && (
                      <li className="list-unstyled mt-2 border-bottom ">
                        <Link to="/Profile_2" className="icon_arrow ">
                          <div className="d-flex ms-2 promenu m-2">
                            <img src={up_arrow} className="up_arrow" alt="" />
                            <img className="" src={profile} alt="" />
                            <p className="contnt  ps-3 ">Profile </p>
                          </div>
                          <img className=" me-3" src={right_arrow} alt="" />
                        </Link>
                      </li>
                    )}
                    {role === "TravelMarshall" && (
                      <>
                        <li className="list-unstyled mt-2 border-bottom ">
                          <Link to="/TransferMoney" className="icon_arrow ">
                            <div className="d-flex ms-2 promenu m-2">
                              <img className="" src={creditcard1} alt="" />
                              <p className="contnt  ps-3 ">Payment </p>
                            </div>
                            <img className=" me-3" src={right_arrow} alt="" />
                          </Link>
                        </li>
                      </>
                    )}
                    {role === "Traveler" && (
                      <>
                        <li className="list-unstyled border-bottom mt-2 ">
                          <Link to="/DocumentVault" className="icon_arrow">
                            <div className="d-flex ms-2">
                              <img className="" src={document_icon} alt="" />
                              <p
                                className="contnt  p-2 pb-2"
                                style={{ marginLeft: "9px" }}
                              >
                                Document Vault
                              </p>
                            </div>
                            <img className=" me-3" src={right_arrow} alt="" />
                          </Link>
                        </li>
                      </>
                    )}

                    <li className="list-unstyled mt-2 border-bottom ">
                      <Link to="/TranscationHistory" className="icon_arrow ">
                        <div className="d-flex ms-2 promenu m-2">
                          <img className="" src={transactions} alt="" />
                          <p className="contnt  ps-2">
                            {role === "Traveler"
                              ? "Subscription History"
                              : "Transaction History"}{" "}
                          </p>
                        </div>
                        <img className=" me-3" src={right_arrow} alt="" />
                      </Link>
                    </li>

                    <li className="list-unstyled mt-2 border-bottom ">
                      <Link to="/Aboutus" className="icon_arrow ">
                        <div className="d-flex ms-2 promenu m-2">
                          <img className="" src={aboutus} alt="" />
                          <p
                            className="contnt  ps-3"
                            style={{ marginLeft: "3px" }}
                          >
                            About Us{" "}
                          </p>
                        </div>
                        <img className=" me-3" src={right_arrow} alt="" />
                      </Link>
                    </li>
                    <li className="list-unstyled mt-2 border-bottom ">
                      <Link to="/ContactUs" className="icon_arrow ">
                        <div className="d-flex ms-2 promenu m-2">
                          <img className="" src={contact} alt="" />
                          <p
                            className="contnt  ps-3"
                            style={{ marginLeft: "3px" }}
                          >
                            Contact Us{" "}
                          </p>
                        </div>
                        <img className=" me-3" src={right_arrow} alt="" />
                      </Link>
                    </li>
                    {role === "Traveler" && (
                      <li className="list-unstyled border-bottom mt-2 ">
                        <Link to="/FAQ" className="icon_arrow">
                          <div className="d-flex ms-2">
                            <img className="" src={faq} alt="" />
                            <p
                              className="contnt  p-2 pb-2"
                              style={{ marginLeft: "10px" }}
                            >
                              FAQ
                            </p>
                          </div>
                          <img className=" me-3" src={right_arrow} alt="" />
                        </Link>
                      </li>
                    )}

                    <li className="list-unstyled border-bottom mt-2">
                      <Link to="/TermandCondition" className="icon_arrow">
                        <div className="d-flex ms-2">
                          <img className="" src={terms_and_conditons} alt="" />
                          <p className="contnt  p-2 ps-3 pb-2">
                            Terms and Conditions
                          </p>
                        </div>
                        <img className=" me-3" src={right_arrow} alt="" />
                      </Link>
                    </li>
                    <li className="list-unstyled border-bottom mt-2">
                      <Link to="/PrivacyandPolicy" className="icon_arrow">
                        <div className="d-flex ms-2">
                          <img className="" src={privacy_policy} alt="" />
                          <p className="contnt  p-2 ps-3 pb-2">
                            Privacy Policy
                          </p>
                        </div>
                        <img className=" me-3" src={right_arrow} alt="" />
                      </Link>
                    </li>
                    {logedIn && (
                      <li className="list-unstyled mt-2 mb-1">
                        <Link
                          to="#"
                          className="icon_arrow"
                          data-toggle="singout"
                          data-target="#singout"
                          id="singout"
                          onClick={() => setSingout(!singout)}
                        >
                          <div className="d-flex ms-2">
                            <img className="" src={logoutimg} alt="" />
                            <p className="contnt  p-2 ps-3 pb-2">Log out</p>
                          </div>

                          <img className=" me-3" src={right_arrow} alt="" />
                        </Link>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </div>

        {/* ------SOS Modal--- */}
        <Modal
          responsive-md
          className="modal   "
          id="sos"
          role="dialog"
          show={sos}
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <div>
            <div className="">
              <p className="text-center sos-heading mt-2 p-2">
                Are you sure you wish to send SOS alert?
              </p>
            </div>
            <div className="btn-popup-modal justify-content-evenly  d-flex mt-2">
              <a href="#" className="m-2 ms-5">
                <button className="btn-sos-btn1 " onClick={() => setSos("")}>
                  NO
                </button>
              </a>

              <a href="#" className="m-2 me-5" onClick={() => sendLatlon()}>
                <button className="btn-report-btn1" id="sosbtn">
                  YES
                </button>
              </a>
            </div>
          </div>
        </Modal>
        {/* ------Logout--- */}
        <Modal
          responsive-md
          className="modal me-2 "
          id="singout"
          role="dialog"
          show={singout}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <div className="">
            <div>
              <h4 className="singoout">Logout</h4>
              <p className="text-center sos-heading mt-2 p-3">
                Are you sure, do you want to logout?
              </p>
            </div>
            <div className="d-flex justify-content-center">
              <div>
                <a href="#">
                  <button
                    className="cancel-btn-chats11"
                    onClick={() => setSingout("")}
                  >
                    No
                  </button>
                </a>
              </div>
              <div>
                <Link to="#" onClick={(e) => logout(e)}>
                  {" "}
                  <button className="cancel-btn-report1">Yes</button>
                </Link>
              </div>
            </div>
          </div>
        </Modal>
      </main>
    </div>
  );
};

export default Header;
