import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import traferr from "../assets/images/Navbar/traferr.png";
import aavtar from "../assets/images/Navbar/aavtar.png";
import profile from "../assets/images/Navbar/profile.svg";
import transactions from "../assets/images/Navbar/transactions.svg";
import transfer_money from "../assets/images/Navbar/transfer_money.svg";
import aboutus from "../assets/images/Navbar/aboutus.svg";
import terms_and_conditons from "../assets/images/Navbar/terms_and_conditons.svg";
import privacy_policy from "../assets/images/Navbar/privacy_policy.svg";
import logout from "../assets/images/Navbar/logout.svg";
import right_arrow from "../assets/images/Navbar/right_arrow.svg";
import up_arrow from "../assets/images/Navbar/up_arrow.svg";
import document_icon from "../assets/images/Navbar/document_icon.svg";
import faq from "../assets/images/Navbar/faq.svg";
import options from "../assets/images/model/options.svg";
import disconnect from "../assets/images/model/disconnect.svg";
import star_highlighted from "../assets/images/model/star_highlighted.svg";
import user_3 from "../assets/images/model/user_3.svg";
import police_icon_modal from "../assets/images/model/police_icon_modal.svg";
import ic_star_grey from "../assets/images/model/ic_star_grey.svg";
import report_icon from "../assets/images/model/report_icon.svg";
import marshall from "../assets/images/model/marshall.svg";
import camping from "../assets/images/model/camping.png";
import cultural from "../assets/images/model/cultural.png";
import jungle from "../assets/images/model/jungle.png";
import beach from "../assets/images/model/beach.png";
const Models = () => {
  const [show, setShow] = useState(false);
  const [report, setReport] = useState(false);
  const [block, setBlock] = useState(false);
  const [rating, setRating] = useState(false);
  const [police, setPolice] = useState(false);
  const [document, setDocument] = useState(false);
  const [sos, setSos] = useState(false);
  const [state, setState] = useState(false);

  return (
    <div>
      <section className="">
        <div className="icon-dropdown dropdown">
          <a
            href=""
            className=""
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img src={aavtar} alt="" height="24" />
          </a>
          <ul className="dropdown-menu">
            <li className="list-unstyled mt-2">
              <Link to="#" className="icon_arrow border-bottom">
                <div className="d-flex ms-2 promenu">
                  <img src={up_arrow} className="up_arrow" alt="" />
                  <img src={profile} alt="" />
                  <h5 className="contnt  m-2">Profile </h5>
                </div>
                <img className=" me-3" src={right_arrow} alt="" />
              </Link>
            </li>

            {/* <li className="list-unstyled border-bottom mt-2">
                    <Link to="#" className="icon_arrow">
                      <div className="d-flex ms-2">
                        <img className="" src={document_icon} alt="" />
                        <h5 className="contnt  m-2">Document Vault</h5>
                      </div>
                      <img className=" me-3" src={right_arrow} alt="" />
                    </Link>
                  </li> */}
            <li className="list-unstyled border-bottom mt-2">
              <Link to="#" className="icon_arrow">
                <div className="d-flex ms-2">
                  <img className="" src={transactions} alt="" />
                  <h5 className="contnt  m-2">Transaction History</h5>
                </div>
                <img className=" me-3" src={right_arrow} alt="" />
              </Link>
            </li>
            {/* <li className="list-unstyled border-bottom mt-2">
                    <Link to="#" className="icon_arrow">
                      <div className="d-flex ms-2">
                        <img className="" src={faq} alt="" />
                        <h5 className="contnt  m-2">FAQ</h5>
                      </div>
                      <img className=" me-3" src={right_arrow} alt="" />
                    </Link>
                  </li> */}

            <li className="list-unstyled border-bottom mt-2">
              <Link to="#" className="icon_arrow">
                <div className="d-flex ms-2">
                  <img className="" src={transfer_money} alt="" />
                  <h5 className="contnt  m-2">Transfer Money</h5>
                </div>
                <img className=" me-3" src={right_arrow} alt="" />
              </Link>
            </li>
            <li className="list-unstyled border-bottom mt-2">
              <Link to="#" className="icon_arrow">
                <div className="d-flex ms-2">
                  <img className="" src={aboutus} alt="" />
                  <h5 className="contnt  m-2">About Us</h5>
                </div>
                <img className=" me-3" src={right_arrow} alt="" />
              </Link>
            </li>
            <li className="list-unstyled border-bottom mt-2">
              <Link to="#" className="icon_arrow">
                <div className="d-flex ms-2">
                  <img className="" src={terms_and_conditons} alt="" />
                  <h5 className="contnt  m-2">Terms and Conditions</h5>
                </div>
                <img className=" me-3" src={right_arrow} alt="" />
              </Link>
            </li>
            <li className="list-unstyled border-bottom mt-2">
              <Link to="#" className="icon_arrow">
                <div className="d-flex ms-2">
                  <img className="" src={privacy_policy} alt="" />
                  <h5 className="contnt  m-2">Privacy Policy</h5>
                </div>
                <img className=" me-3" src={right_arrow} alt="" />
              </Link>
            </li>
            <li className="list-unstyled border-bottom mt-2">
              <Link to="#" className="icon_arrow">
                <div className="d-flex ms-2">
                  <img className="" src={logout} alt="" />
                  <h5 className="contnt  m-2">Log out</h5>
                </div>
                <img className=" me-3" src={right_arrow} alt="" />
              </Link>
            </li>
          </ul>
        </div>

        {/* -----------------------------
                    DropDown model
                    --------------------------=-- */}

        <div className="chat-dropdown dropdown">
          <a
            href=""
            className=""
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img src={options} alt="" height="24" />
          </a>
          <ul className="dropdown-menu">
            <li>
              <button
                type="button"
                className="dropdown-item  disconnet "
                data-toggle="modal"
                data-target="#disconnet"
                id="bids"
                onClick={() => setShow(!show)}
              >
                Disconnect <img src={up_arrow} className="arrow" alt="" />
              </button>
            </li>
            <li>
              <button
                className="dropdown-item disconnet"
                type="button"
                data-toggle="modal"
                data-target="#reports"
                id="bids"
                onClick={() => setReport(!report)}
              >
                Report
              </button>
            </li>
            <li>
              <button
                className="dropdown-item disconnet"
                type="button"
                data-toggle="modal"
                data-target="#block"
                id="bids"
                onClick={() => setBlock(!block)}
              >
                Block
              </button>
            </li>
            <li>
              <button
                className="dropdown-item disconnet"
                type="button"
                data-toggle="modal"
                data-target="#rating"
                id="bids"
                onClick={() => setRating(!rating)}
              >
                Rating
              </button>
            </li>
            <li>
              <button
                className="dropdown-item disconnet"
                type="button"
                data-toggle="police"
                data-target="#police"
                id="bids"
                onClick={() => setPolice(!police)}
              >
                Emergency
              </button>
            </li>
            <li>
              <button
                className="dropdown-item disconnet"
                type="button"
                data-toggle="document"
                data-target="#document"
                id="bids"
                onClick={() => setDocument(!document)}
              >
                Add Document
              </button>
            </li>
            <li>
              <button
                className="dropdown-item disconnet"
                type="button"
                data-toggle="sos"
                data-target="#sos"
                id="bids"
                onClick={() => setSos(!sos)}
              >
                SOS
              </button>
            </li>
            <li>
              <button
                className="dropdown-item disconnet"
                type="button"
                data-toggle="about"
                data-target="#about"
                id="bids"
                onClick={() => setState(!state)}
              >
                about Marshall
              </button>
            </li>
          </ul>
        </div>

        {/* =---------------
        Disconnect Model */}

        <Modal
          responsive-md
          className="modal-lg"
          id="disconnet"
          role="dialog"
          show={show}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <div className="container">
            <div className="text-center justify-content-center">
              <div>
                <img src={disconnect} alt="" />
                <label htmlFor="" className="d-block model_lael pt-3">
                  Disconnect
                </label>
              </div>
            </div>
            <div>
              <p className="text-center popup-heading mt-4">
                Are you sure you want to disconnect with TM62816?
              </p>
            </div>
            <div className="btn-popup-modal justify-content-evenly  d-flex">
              <a href="" className="m-1">
                <button className="popup-btn" id="popup-btn">
                  Cancel
                </button>
              </a>

              <a href="" className="m-1">
                <button className="popup-btn" id="popup-btn">
                  Disconnect
                </button>
              </a>
            </div>
          </div>
        </Modal>

        {/* =---------------
        Reports Model */}

        <Modal
          responsive-md
          className="modal-md"
          id="reports"
          role="dialog"
          show={report}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <div className="text-center justify-content-center">
            <div>
              <img src={report_icon} alt="" />
              <label htmlFor="" className="d-block model_lael pt-3">
                Report Travel Marshall
              </label>
            </div>
          </div>
          <div>
            <div className="pt-3">
              <p className="text-left popup-heading m-2">
                Why are you reporting this travel marshall?
              </p>
              <select
                className=" form-select mb-3"
                aria-label="Default select example"
              >
                <option selected>Behaviour was Inappropriate</option>
                <option value="1"> Behaviour </option>
                <option value="2"> conduct that is unwarranted</option>
                <option value="3"> perceived as disruptive</option>
              </select>
            </div>
          </div>
          <div className="form-group m-1 ">
            <label className="popup-heading p-1">
              Please describe your reason for reporting .
            </label>
            <textarea
              className="form-control text-area model_text"
              id=""
              rows="3"
              placeholder="Type here"
            ></textarea>
          </div>
          <div className="btn-popup-modal justify-content-evenly  d-flex">
            <a href="" className="m-1">
              <button className="popup-btn" id="report-btn">
                Cancel
              </button>
            </a>

            <a href="" className="m-1">
              <button className="popup-btn" id="report-btn">
                Report
              </button>
            </a>
          </div>
        </Modal>

        {/* =---------------
         Block Model */}

        <Modal
          responsive-md
          className="modal-lg"
          id="block"
          role="dialog"
          show={block}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <div className="text-center justify-content-center">
            <div>
              <img src={disconnect} alt="" />
              <label htmlFor="" className="d-block model_lael pt-3">
                Block
              </label>
            </div>
          </div>
          <div>
            <p className="text-center popup-heading mt-4">
              Are you sure you want to Block with TM62816?
            </p>
          </div>
          <div className="btn-popup-modal justify-content-evenly  d-flex">
            <a href="" className="m-1">
              <button className="popup-btn" id="popup-btn">
                Cancel
              </button>
            </a>

            <a href="" className="m-1">
              <button className="popup-btn" id="popup-btn">
                Block
              </button>
            </a>
          </div>
        </Modal>

        {/* =---------------
        Reports Model */}

        <Modal
          responsive-md
          className="modal-md"
          id="rating"
          role="dialog"
          show={rating}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <div className="text-center">
            <div className="img-rating">
              <img src={user_3} alt="" />
            </div>
            <div>
              <p className="user-name mt-2">
                TM65413
                <br />{" "}
              </p>
            </div>
            <div className="rating-star">
              <p className="text-center popup-heading mt-3">
                Do you like his Service?
              </p>
              <img src={star_highlighted} alt="" />
              <img src={star_highlighted} alt="" />

              <img src={star_highlighted} alt="" />
              <img src={star_highlighted} alt="" />
              <img src={ic_star_grey} alt="" />
            </div>
            <p className="text-center model_rate mt-1">Tap a Star to Rate</p>
            <label htmlFor="" className="model_feedback mb-4">
              Give your valuable feedback
            </label>
            <div>
              <div className="">
                <select
                  className=" form-select mb-2"
                  aria-label="Default select example"
                >
                  <option selected>TM was behaviour was professional</option>
                  <option value="1"> Behaviour </option>
                  <option value="2"> TM was behaviour was professional</option>
                  <option value="3"> professional</option>
                </select>
              </div>
            </div>
            <div className="form-group mt-3">
              <textarea
                className="form-control"
                id="exampleFormControlTextarea1"
                rows="4"
                placeholder="Type your a review (Optional)"
              ></textarea>
            </div>
          </div>
          <div className="btn-popup-modal justify-content-evenly  d-flex">
            <a href="" className="m-1">
              <button className="popup-btn" id="rating-btn">
                Dismiss
              </button>
            </a>

            <a href="" className="m-1">
              <button className="popup-btn" id="rating-btn">
                Submit
              </button>
            </a>
          </div>
        </Modal>

        {/* =---------------
        Emergency Model */}

        <Modal
          responsive-md
          className="modal-md"
          id="police"
          role="dialog"
          show={police}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <a href="">
            <Modal.Header closeButton></Modal.Header>
          </a>
          <div className="text-center">
            <div className="img-rating">
              <img src={police_icon_modal} alt="" />
            </div>
            <div>
              <p className="user-name mt-2">
                Police Station
                <br />{" "}
              </p>
            </div>
            <div
              className=" justify-content-between align-items-start mb-3 "
              id="days"
            >
              <div className="  d-flex align-items-middle justify-content-between  rsponsive-card-data">
                <h5 className="model_staion">Police Station-1</h5>
                <span className="model_staion">+91 000 000 0000</span>
              </div>
            </div>
            <div
              className=" justify-content-between align-items-start mb-3 "
              id="days"
            >
              <div className="  d-flex align-items-middle justify-content-between  mt-2 rsponsive-card-data">
                <h5 className="model_staion">Police Station-2</h5>
                <span className="model_staion">+91 000 000 0000</span>
              </div>
            </div>
            <div
              className=" justify-content-between align-items-start mb-3 "
              id="days"
            >
              <div className="  d-flex align-items-middle justify-content-between  mt-2 rsponsive-card-data">
                <h5 className="model_staion">Police Station-3</h5>
                <span className="model_staion">+91 000 000 0000</span>
              </div>
            </div>
            <div
              className=" justify-content-between align-items-start mb-3 "
              id="days"
            >
              <div className="  d-flex align-items-middle justify-content-between  mt-2 rsponsive-card-data">
                <h5 className="model_staion">Police Station-4</h5>
                <span className="model_staion">+91 000 000 0000</span>
              </div>
            </div>
            <div
              className=" justify-content-between align-items-start mb-3 "
              id="days"
            >
              <div className="  d-flex align-items-middle justify-content-between  mt-2 rsponsive-card-data">
                <h5 className="model_staion">Police Station-5</h5>
                <span className="model_staion">+91 000 000 0000</span>
              </div>
            </div>
          </div>
        </Modal>

        {/* =---------------
        Add Document */}

        <Modal
          responsive-md
          className="modal-md"
          id="document"
          role="dialog"
          show={document}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <a href=""></a>
          <div className="justify-content-between d-flex">
            <p className="text-left">Add Document</p>
            <a href="">
              <Modal.Header closeButton></Modal.Header>
            </a>
          </div>
          <div className="form-group mt-3">
            <label htmlFor="" className="model_document">
              Document Name
            </label>
            <textarea
              className="form-control model_Text"
              id=""
              rows="1"
              placeholder="Enter Document Name"
            ></textarea>
          </div>

          <form className="mt-4 model_document">
            <label for="AddFile">Add File</label>
            <div
              className=" justify-content-between align-items-start model_Text "
              id="days"
            >
              <div className="form-group">
                <input
                  type="file"
                  className="form-control-file "
                  id="AddFile"
                />
              </div>
            </div>
          </form>
          <div className="btn-popup-modal justify-content-evenly  d-flex">
            <a href="" className="m-1">
              <button className="popup-btn" id="docu-btn">
                Cancel
              </button>
            </a>

            <a href="" className="m-1">
              <button className="popup-btn" id="docu-btn">
                Add
              </button>
            </a>
          </div>
        </Modal>

        {/* =---------------
        SOS */}

        <Modal
          responsive-md
          className="modal"
          id="sos"
          role="dialog"
          show={sos}
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <div>
            <p className="text-center popup-heading mt-4">
              Are you sure you wish to send SOS alert?
            </p>
          </div>
          <div className="btn-popup-modal justify-content-evenly  d-flex">
            <a href="" className="m-1">
              <button className="popup-btn" id="report-btn">
                NO
              </button>
            </a>

            <a href="" className="m-1">
              <button className="popup-btn" id="report-btn">
                YES
              </button>
            </a>
          </div>
        </Modal>

        {/* =---------------
        About Marshall */}
        <Modal
          responsive-md
          className="modal-md"
          id="about"
          role="about"
          show={state}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <div className="row">
            <div className="justify-content-between d-flex">
              <p className="text-left marshall_head">About Travel Marshall</p>
              <a href="">
                <Modal.Header closeButton></Modal.Header>
              </a>
            </div>
            <div className="col-lg-6 ">
              <img src={marshall} alt="" className="w-100 h-100" />
            </div>
            <div className="col-lg-6 ">
              <div>
                <label className="marshall_usernam text-left">
                  TM65413
                  <br />{" "}
                </label>
              </div>
              <p className="text-left popup-heading mt-2">Interests</p>
              <div className="d-flex">
                <div className="col-lg-6">
                  <img src={camping} alt="" className="marshall_img" />
                  <img src={jungle} alt="" className="marshall_img" />
                </div>
                <div className="col-lg-6">
                  <img src={beach} alt="" className="marshall_img" />
                  <img src={cultural} alt="" className="marshall_img" />
                </div>
              </div>
            </div>
            <label htmlFor="" className="marshall_bio mt-3">
              Bio
            </label>
            <p className="bio_details">
              Hi, I’m a travel addict originally from sleepy Suffolk in the UK.
              I quit my job in Dec 2012 and have been travelling slowly,
              independently and on a budget around India, Southeast Asia and
              Australia ever since.
            </p>
          </div>
        </Modal>
      </section>
    </div>
  );
};

export default Models;
