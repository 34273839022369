import React, { useEffect, useState } from "react";
import Header from "../../../sharedComponents/Header";
import Footer from "../../../sharedComponents/Footer";
import upi_icon from "../../../assets/images/PayMethods/upi_icon.svg";
import googlepay from "../../../assets/images/PayMethods/googlepay.png";
import netbanking from "../../../assets/images/PayMethods/netbanking.svg";
import edit_icon from "../../../assets/images/PayMethods/edit_icon.svg";
import { Link, useLocation } from "react-router-dom";
import { Modal } from "react-bootstrap";
import swal from "sweetalert";

import {
  deleteWithAuthCall,
  getWithAuthCall,
  simpleGetCallWithErrorResponse,
} from "../../../api/ApiServices";
import ApiConfig from "../../../api/ApiConfig";

const TransferMoney = () => {
  const [bankAccountList, setBankAccountList] = useState([]);
  const [upiNo, setUpiNo] = useState([]);
  const [transaction, setTransaction] = useState([{}]);
  const [bModal, setBModal] = useState(false);
  const data = useLocation().state;

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getBankDetails();
    getTransactionHistory();
    return () => {};
  }, []);

  const getTransactionHistory = () => {
    setLoading(true);
    simpleGetCallWithErrorResponse(
      ApiConfig.TRANSACTION_HISTORY
      // JSON.stringify({ ...customerData })
    ).then((res) => {
      // console.log(res);
      setLoading(false);
      setTransaction(res.json.data);
    });
  };

  const getBankDetails = () => {
    setLoading(true);
    getWithAuthCall(ApiConfig.BANK_DETAILS_LIST).then((res) => {
      // console.log(res);
      setBankAccountList(res.BANK_ACCOUNT);
      setUpiNo(res.UPI_ID);
      setLoading(false);
    });
  };
  const DeleteBankInfo = (id) => {
    setLoading(true);
    deleteWithAuthCall(ApiConfig.DELETE_BANK_INFO + id)
      .then((res) => {
        // console.log(res);
        getBankDetails();
        setBModal("");
        swal("Bank Info Deleted Successfully");
      })

      .catch((err) => {
        setLoading(false);

        console.log(err);
      });
  };

  return (
    <>
      <Header />

      <main className="home-getStarted">
        <div className="">
          <div className="about_banner"></div>
          {/* <img src={emergency} alt="" /> */}
        </div>
        <div className="container mt-2" style={{ marginBottom: "200px" }}>
          <h5 htmlFor="" className="pay_req">
            Payment Methods
          </h5>
          {loading ? (
            <div
              className="d-flex spinner-border mx-auto mt-2"
              style={{
                color: "#3D313A",
                width: "70px",
                height: "70px",
              }}
            />
          ) : (
            <div className="row">
              <p htmlFor="" className="Today">
                Earned to date
              </p>
              {transaction && transaction.length > 0 ? (
                transaction.map((item, index) => {
                  return (
                    <span htmlFor="" className="bal mt-1">
                      {transaction.amount}
                    </span>
                  );
                })
              ) : (
                <h4 className="text-danger">No Amount</h4>
              )}

              <p htmlFor="" className="Today mt-4">
                Transfer Options
              </p>
              <div className="col-lg-12 mt-2">
                <div className="card11 ">
                  <div className="payment-card ">
                    <div className="row border-bottom">
                      <div className="col-lg-6">
                        <div className="d-flex pb-2">
                          <img src={upi_icon} alt="" />
                          <p className="PYcard-title ms-3">UPI Account</p>
                        </div>
                      </div>
                      <div className="col-lg-6 text-end">
                        <Link to="/UPIPayments">
                          <label className="add_text pe-3">Add</label>
                        </Link>
                      </div>
                    </div>
                    {upiNo.length > 0 ? (
                      upiNo.map((list, index) => {
                        return (
                          <div className="row" key={"list" + index}>
                            <div className="col-lg-6">
                              <div className="d-flex pt-4 ">
                                <img
                                  src={googlepay}
                                  className="ps-3 googlepay-img"
                                  alt=""
                                />
                                <p className="PYcard-title1 ">{list.upi_no}</p>
                              </div>
                            </div>
                            <div className="col-lg-6 text-end pt-4">
                              <Link
                                to="#"
                                onClick={() => setBModal(!bModal)}
                                state={list.id}
                              >
                                <img
                                  src={edit_icon}
                                  className="pe-3 edit-icon1"
                                  alt=""
                                />
                              </Link>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <h4 className="text-danger text-center mt-4">
                        No UPI ID
                      </h4>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-lg-12 mt-4">
                <div className="card11 ">
                  <div className="payment-card ">
                    <div className="row border-bottom">
                      <div className="col-lg-6">
                        <div className="d-flex pb-3">
                          <img src={netbanking} className="ps-3" alt="" />
                          <p className="bnk-title  "> Bank Account</p>
                        </div>
                      </div>
                      <div className="col-lg-6 text-end">
                        <Link to="/BankPayments">
                          <label className="add_text pe-3 ">Add</label>
                        </Link>
                      </div>
                    </div>
                    {bankAccountList.length > 0 ? (
                      bankAccountList.map((list, index) => {
                        return (
                          <div className="row" key={"list" + index}>
                            <div className="col-lg-6">
                              <div className="d-flex pt-4">
                                <img
                                  src={netbanking}
                                  className="ps-3 "
                                  alt=""
                                />
                                <p className="bnk-title ">
                                  {" "}
                                  {list.account_name}
                                </p>
                              </div>
                            </div>
                            <div className="col-lg-6 text-end pt-4">
                              <Link
                                to="#"
                                className="icon_arrow"
                                data-toggle="bModal"
                                data-target="#bModal"
                                id="bModal"
                                onClick={() => setBModal(!bModal)}
                                state={list.id}
                              >
                                <img
                                  src={edit_icon}
                                  className="pe-3 edit-icon1"
                                  alt=""
                                />
                              </Link>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <h4 className="text-danger text-center mt-4">
                        No Account
                      </h4>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <Modal
          responsive-md
          className="modal me-2 "
          id="bModal"
          role="dialog"
          show={bModal}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <div className="">
            <div>
              <h4 className="singoout">Delete Bank Info</h4>
              <p className="text-center sos-heading mt-2 p-3">
                Are you sure, do you want to Delete?
              </p>
            </div>
            <div className="d-flex justify-content-center">
              <div>
                <button
                  className="cancel-btn-chats11"
                  onClick={() => setBModal("")}
                >
                  No
                </button>
              </div>
              <div>
                <Link to="#">
                  {" "}
                  <button
                    className="cancel-btn-report1"
                    onClick={() => {
                      DeleteBankInfo(data);
                    }}
                  >
                    Yes
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </Modal>
        <Footer />
      </main>
    </>
  );
};

export default TransferMoney;
