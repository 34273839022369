import {SET_LOCATION, SET_ARRAYLOCATION, SET_RECENTSEARCH} from '../types';

const INITIAL_STATE = {
  locationR: 'Choose your location',
  dataArray: [],
  recentCity: '',
  recentArea: '',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_LOCATION:
      return {
        ...state,
        locationR: action.payload,
      };
    case SET_ARRAYLOCATION:
      return {
        ...state,
        dataArray: action.payload,
      };
    case SET_RECENTSEARCH:
      return {
        ...state,
        recentCity: action.payload.city,
        recentArea: action.payload.area,
      };

    default:
      return INITIAL_STATE;
  }
};
