import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../context/user/AppContext";
// import Button from "../pages/groups/Button";
// import gps from "../assets/images/gps.png";

export default function AutoComplete({ setPlace, ref }) {
  const placeInputRef = useRef(null);
  const { customerData, setCustomerData } = useContext(AppContext);
  const apiKey = "AIzaSyCNWHf6BlYzPBPnwmsSvSBvGdTdl-6Aays";
  // const apiKey = import.meta.env.VITE_APP_GMAP_API_KEY;
  const geocodeJson = "https://maps.googleapis.com/maps/api/geocode/json";

  useEffect(() => {
    initPlaceAPI();
    return () => {};
  }, []);
  const initPlaceAPI = async () => {
    // console.log("window.google", window.google);
    if (
      window.google &&
      window.google.maps &&
      window.google.maps.places &&
      window.google.maps.places.Autocomplete
    ) {
      let autocomplete = new window.google.maps.places.Autocomplete(
        placeInputRef.current
      );
      new window.google.maps.event.addListener(
        autocomplete,
        "place_changed",
        function () {
          let place = autocomplete.getPlace();
          setCustomerData({
            ...customerData,
            location: place.name,
            center: {
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
            },
          });

          setPlace({
            ...place,
            center: {
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
            },
            address: place.name,
          });
        }
      );
    }
  };

  const reverseGeocode = ({ latitude: lat, longitude: lng }) => {
    const url = `${geocodeJson}?key=${apiKey}&latlng=${lat},${lng}`;
    placeInputRef.current.value = "Getting your location...";
    fetch(url)
      .then((response) => response.json())
      .then((location) => {
        const place = location.results[0];
        setPlace({
          ...place,
          center: { lat, lng },
          address: location.results[0].formatted_address,
        });
        setCustomerData({
          ...customerData,
          location: location.results[0].formatted_address,
          center: {
            lat: lat,
            lng: lng,
          },
        });
        // const _address = extractAddress(place);
        // setAddress(_address);
        placeInputRef.current.value = location.results[0].formatted_address;
      });
  };

  const findMyLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        reverseGeocode(position.coords);
      });
    }
  };

  return (
    <div className="uploadPP_inputTextRow" style={{ marginBottom: "30px" }}>
      <div
        className="gps"
        style={{
          position: "relative",
        }}
      >
        <input
          id="inputGroupSelect01 place-fw"
          style={{
            backgroundColor: "white",
            height: "40px",
            zIndex: 1,
            width: "100%",
            border: "1px solid #ced4da",
            borderRadius: "10px",
            padding: "0 40px 0 10px",
            outline: "none",
            height: "50px",
            paddingLeft: "30px",
          }}
          ref={placeInputRef}
          value={customerData.location}
          onChange={(e) => {
            setCustomerData({
              ...customerData,
              location: e.target.value,
            });
          }}
        />
        {/* <Button onClick={findMyLocation}>current location</Button> */}
        {/* <button
          onClick={findMyLocation}
          style={{
            position: "absolute",
            top: "50%",
            right: "10px",
            transform: "translateY(-50%)",
            backgroundColor: "transparent",
            border: "none",
            outline: "none",
          }}
        >
          <img src={gps} alt="" width="20px" />
        </button> */}
      </div>
    </div>
  );
}
