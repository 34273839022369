import React from "react";

const Footer = () => {
  return (
    < >
      <footer>
        <div className="FooterA  ">
          <span className="copy text-center me-3" id="text_color">
            PRIVACY POLICY
          </span>
          <span className="copy text-center ms-3" id="text_color">
            TERMS & CONDITIONS
          </span>
        </div>
        <div className="FooterB">
          <span className="copy text-center pt-3">
            © Copyright 2022. All Rights Reserved.
          </span>
        </div>
      </footer>
    </>
  );
};

export default Footer;
