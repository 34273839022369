import React, { useContext, useEffect, useState } from "react";
import login_banner from "../assets/images/login_banner.png";
import google from "../assets/images/google.svg";
import facebook from "../assets/images/facebook.svg";
import view from "../assets/images/eye_off_icon.svg";
import { Link } from "react-router-dom";
import ApiConfig from "../api/ApiConfig";
import { simplePostCall } from "../api/ApiServices";
import { AppContext } from "../context/user/AppContext";
import eye from "../assets/images/eye.svg";
import check from "../assets/images/check.svg";
import swal from "sweetalert";
import { signInWithFaceBook, signInWithGoogle } from "./Firebase";
import { Modal } from "react-bootstrap";

const Login = () => {
  const [socialLogin, setSocialLogin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [facebookLogin, setFacebookLogin] = useState(false);
  const [account, setAccount] = useState("");
  const [show, setShow] = useState(false);
  const [values, setValues] = useState({
    password: "",
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handlePasswordChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const [userDetails, setUserDetails] = useState({
    email: "",
    password: "",
    role: "",
  });

  const [errMsg, setErrMsg] = useState({ email: "", password: "" });
  const { setCustomerData, setRole, role, setLoggedIn, logedIn } =
    useContext(AppContext);

  useEffect(() => {
    if (
      (socialLogin || facebookLogin) &&
      userDetails.email.length > 0 &&
      userDetails.displayName
    )
      login();
    return () => {};
  }, [socialLogin, userDetails, facebookLogin]);

  const loginIn = (requestBody) => {
    setLoading(true);

    let url;
    let data;
    if (socialLogin || facebookLogin) {
      url = ApiConfig.SOCIAL_LOGIN;
      data = requestBody;
    } else {
      url = ApiConfig.TREVFERR_LOGIN_API;
      data = userDetails;
    }
    simplePostCall(url, JSON.stringify(data))
      .then((res) => {
        // console.log(res.detail);
        if (res.user_details) {
          // console.log("res", res);
          localStorage.setItem("auth_token", res.user_details.auth_token);
          localStorage.setItem("id", res.user_details.id);
          localStorage.setItem("unique_id", res.user_details.unique_id);
          localStorage.setItem("role", res.user_details.role);
          localStorage.setItem("userDetails", JSON.stringify(res.user_details));
          localStorage.setItem("logedIn", true);
          setCustomerData({
            id: res.user_details.id,
            unique_id: res.user_details.unique_id,
            auth_token: res.user_details.auth_token,
            role: res.user_details.role,
          });
          setRole(res.user_details.role);
          setAccount(res.user_details.account_varification);
        } else swal(res.detail);
        setLoading(false);

        if (res.user_details.account_varification === "Verified") {
          setLoggedIn(true);
        } else if (res.user_details.account_varification === null) {
          swal("Account need to be Verified");
        }
      })
      .catch((err) => {});
  };
  const login = (e) => {
    e && e.preventDefault();
    if (socialLogin || facebookLogin) {
      let requestBody = {
        social_id: userDetails.uid,
        login_type: facebookLogin ? "facebook" : "gmail",
        email: userDetails.email.toLocaleLowerCase(),
        full_name: userDetails.displayName,
        username: userDetails.uid,
        log_in_status: true,
        device_type: "web",
        role: userDetails.role,
      };
      loginIn(requestBody);
    } else {
      if (userDetails.email === "" || userDetails.password === "") {
        if (userDetails.email === "") {
          setErrMsg({ ...errMsg, email: "Enter Email ID " });
          return;
        }
        if (userDetails.password === "") {
          setErrMsg({ ...errMsg, password: "Enter Password " });
          return;
        }
      } else {
        setLoading(true);
        loginIn();
      }
    }
  };

  // const handleVerify = () => {
  //   if (logedIn === true) {
  //     login();
  //   } else {
  //     swal("Account need to be verified");
  //   }
  // };
  return (
    <main className="login-main">
      <Modal
        role="dialog"
        show={show}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {" "}
        <Modal.Header onClick={() => setShow(!show)} closeButton></Modal.Header>
        <Modal.Body>
          <div className="w-100 h-100">
            <div className="row ">
              <div className="col-lg-6 center">
                <button
                  value=""
                  onClick={(e) =>
                    setUserDetails({
                      ...userDetails,
                      role: "Traveler",
                    })
                  }
                  className={`btn btnTeveller ${
                    userDetails.role === "Traveler" && "roleSelector"
                  } `}
                  type="button"
                >
                  Traveller
                </button>
              </div>
              <img src={check} alt="" />

              <div className="col-lg-6">
                <button
                  value=""
                  onClick={(e) =>
                    setUserDetails({
                      ...userDetails,
                      role: "TravelMarshall",
                    })
                  }
                  className={`btn btnTeveller ${
                    userDetails.role === "TravelMarshall" && " roleSelector"
                  } `}
                  type="button"
                >
                  Marshall
                </button>
              </div>
            </div>
            <button
              type="submit"
              className="btnTeveller login mt-4 "
              onClick={() => {
                login();
                setShow();
              }}
            >
              login
            </button>
          </div>
        </Modal.Body>
      </Modal>
      {/* (Object.keys(error).length === 0 && issubmit ? (<div className="ui message success">Singned is successfully</div>: " "  )) */}
      <section className="login-section">
        <div className="row">
          <div className="col-md-5 col-lg-5">
            <div className="left-section-login">
              <img src={login_banner} className="img-responsive image-fluid" />
            </div>
          </div>

          <div className="col-lg-7  col-md-7 parent-login ">
            <div className="login-form ">
              <form className="text-left" autoComplete="off">
                <h3 className="login-heading ">Log In</h3>

                <div className="form-group mt-4 email_fileld ">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="email_heading pt-3"
                  >
                    Email / Unique ID
                  </label>
                  <input
                    //  value={validate.email}
                    type="email"
                    name="email"
                    id="inputEmail"
                    className="form-control emailInput mt-2 mb-2 text-left "
                    placeholder="name@example.com"
                    value={userDetails && userDetails.email}
                    onChange={(e) => {
                      setErrMsg({ ...errMsg, email: "" });
                      setUserDetails({ ...userDetails, email: e.target.value });
                    }}
                  />
                  {userDetails && userDetails.email.length > 0 && (
                    <img src={check} alt="" className="check_icon" />
                  )}
                </div>
                {errMsg.email.length > 0 && (
                  <span className="text-danger">{errMsg.email}</span>
                )}
                {/* <p>{error.email}</p> */}
                <div className="form-group email_fileld  mt-3">
                  <label htmlFor="inputPassword" className="email_heading ms-2">
                    Password
                  </label>
                  <input
                    type={values.showPassword ? "text" : "password"}
                    maxLength="18"
                    // value={state.password}
                    name="password"
                    className="form-control mt-2 emailInput"
                    placeholder="Password"
                    value={userDetails.password}
                    onChange={(e) => {
                      setErrMsg({ ...errMsg, password: "" });
                      setUserDetails({
                        ...userDetails,
                        password: e.target.value,
                      });
                      handlePasswordChange("password");
                    }}
                  />
                  <div>
                    <img
                      src={values.showPassword ? eye : view}
                      alt=""
                      className="eye_icon"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    />
                    {/* <img src={hideeye} alt=""  className="eye_icon" /> */}
                  </div>
                </div>
                {errMsg.password.length > 0 && (
                  <span className="text-danger">{errMsg.password}</span>
                )}
                <div className="forgot-password mt-1 ">
                  <Link to="/ForgotPassword" className="text-decoration-none">
                    Forgot Password?
                  </Link>
                </div>
                <div className="form-group mt-4 email_fileld ">
                  <div className="d-flex ms-lg-3">
                    <div className="col">
                      <button
                        value=""
                        onClick={(e) =>
                          setUserDetails({
                            ...userDetails,
                            role: "Traveler",
                          })
                        }
                        className={`btn btnTeveller ${
                          userDetails.role === "Traveler" && " roleSelector"
                        } `}
                        type="button"
                      >
                        TRAVELLER
                      </button>
                    </div>
                    <div className="col">
                      <button
                        value=""
                        onClick={(e) =>
                          setUserDetails({
                            ...userDetails,
                            role: "TravelMarshall",
                          })
                        }
                        className={`btn btnTeveller ${
                          userDetails.role === "TravelMarshall" &&
                          " roleSelector"
                        } `}
                        type="button"
                      >
                        MARSHAL
                      </button>
                    </div>
                  </div>
                </div>

                {/* <Link to="#"> */}
                <button
                  type="submit"
                  className="login-btn mt-4"
                  id="login-btn"
                  onClick={login}
                >
                  {loading ? (
                    <div
                      className="spinner-border"
                      style={{ textAlign: "right", color: "#FFFBF3" }}
                    />
                  ) : (
                    "Log in"
                  )}
                </button>

                {/* </Link> */}
              </form>
              <div className="or mt-4 mb-4">
                <div id="line">
                  <span>or</span>
                </div>
              </div>
              <button
                className="google-btn btn  mt-3 "
                onClick={() => {
                  signInWithGoogle().then((res) => {
                    if (res.user) {
                      setUserDetails({ ...res.user.providerData[0] });
                      setSocialLogin(true);
                    }
                  });
                }}
              >
                <img src={google} alt="" height="22" className="img-google" />
                <span className="text-google">Continue with Google</span>
              </button>
              {/* <GoogleLogin
                clientId="882898085129-0057vh8dvnq4ntrcjdbna0etae00vjbl.apps.googleusercontent.com"
                render={(renderProps) => (
                  
                )}
                buttonText="Login"
                onSuccess={responseGoogle}
                onTokenRefresh={checkin}
                onFailure={responseGoogle}
                cookiePolicy={"single_host_origin"}
              /> */}

              <button
                className="facebook-btn mt-3 btn"
                onClick={() => {
                  signInWithFaceBook().then((res) => {
                    if (res.user) {
                      setUserDetails({ ...res.user.providerData[0] });
                      setFacebookLogin(true);
                    }
                  });
                }}
              >
                <img
                  src={facebook}
                  alt=""
                  height="22"
                  className="img-facebook"
                />
                <span className="text-facebook ">Log in with Facebook</span>
              </button>
              {/* <div className="input-group my-4 mx-4">
                    <input type={passwordType} onChange={handlePasswordChange} value={passwordInput} name="password" className="form-control" placeholder="Password" />
                    <div className="input-group-btn">
                     <button className="btn btn-outline-primary" onClick={togglePassword}>
                     { passwordType==="password"? <i className="bi bi-eye-slash"></i> :<i className="bi bi-eye"></i> }
                     </button>
                    </div>
                </div> */}
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Login;
